import * as R from 'ramda';
import axios from 'axios';
import jwt from 'modules/jwt';
import memoize from 'memoizee';
import { StreamChat } from 'stream-chat';

let jwtGet;
let tokenUrl = '';

const unnest = R.path(['data', 'data']);

const chatClient = StreamChat.getInstance(process.env.STREAM_IO_API_KEY);

const jwtGetSafe = url => {
  return jwtGet ? jwtGet(url) : getHubConfigs().then(() => jwtGetSafe(url));
};

export const getHubConfigs = () => axios.get('/api/company_config').then(config => {
  const hubConfig = unnest(config);
  tokenUrl = hubConfig.hub.tokenUrl;

  jwtGet = jwt(axios.create({
    baseURL: hubConfig.api.url,
    headers: { 'X_ORG_ID': hubConfig.hub.id },
    withCredentials: true
  }), axios, hubConfig.hub.tokenUrl);
});

export const getToken = () => axios.post(tokenUrl, null, {
  withCredentials: true,
  headers: { 'Accept': 'application/jwt', 'X-Requested-With': 'XMLHttpRequest' }
});

const connectToStream = memoize(
  (contact_id) => chatClient.connectUser(
    { id: contact_id },
    async() => {
      const response = await getStreamToken();
      return response.data.token;
    }),
  { promise: true }
);

const getStreamToken = () =>
  axios.request({
    method: 'GET',
    url: '/stream_io/token',
    data: null,
    withCredentials: true,
    headers: { Accept: 'application/jwt' }
  });

export const connectUserToStream = () =>
  getStreamToken()
    .then(R.prop('data'))
    .then(({ contact_id }) => connectToStream(contact_id))
    .catch(e => console.error('Unable to connect to Stream', e));

export const contentSections = () => jwtGetSafe('/content_sections').then(R.path(['data']));
export const features = () => jwtGetSafe('/features').then(unnest);
export const permissions = () => jwtGetSafe('/permissions').then(unnest);
export const messageCount = () => jwtGetSafe('/conversations/counts')
  .then(R.path(['data', 'attributes', 'unread-conversation-count']));
export const status = () => axios.get(process.env.STATUSPAGE_API_URL).then(R.prop('data'));
export const fetchHubSwitcherData = () => jwtGetSafe('api/corporate/hub_switcher').then(R.prop('data'));
export const profile = () => jwtGetSafe('/people/self').then(unnest);
export const windowObjApi = () => jwtGetSafe('api/c2_homepage/infl_window_object').then(R.prop('data'));
