import React from 'react';
import { compose, setDisplayName, withHandlers } from 'recompose';
import ChannelCard from '@influitive/secret-garden/lib/channel-card';
import { BackgroundImage } from '@influitive/secret-garden/lib/image';
import { track } from '../../common/tracking-utils';
import { withNamespaces } from 'react-i18next';
import truncateNumber from '../../common/truncate-number';

export default compose(
  withHandlers({
    track: ({ channel }) => () => track('channel', { id: channel.id })
  }),
  withNamespaces(),
  setDisplayName('ChannelItem')
)(({ channel, track, t }) => (
  <ChannelCard
    uuid={channel.uuid}
    media={channel.image && <BackgroundImage src={channel.image} alt={channel.title} />}
    url={`/channels/${channel.id}`}
    title={channel.title}
    description={channel.description}
    challengeCountText={t('{{count}} Challenge', { count: channel.total_challenge_count })}
    unseenCountText={t('{{count}} New', { count: truncateNumber(channel.unseen_challenge_count) })}
    onClick={track}
    darkModeOn={channel.dark_mode_on}
    primaryColorOverlay={channel.primary_color_overlay}
    data-qa={`channel-item-${channel.id}`} />
));
