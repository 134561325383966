import React from 'react';
import styled from '@emotion/styled';
import { withNamespaces } from 'react-i18next';
import cn from 'classnames';

import styles from './style.css';
import Popover from '../popover';
import Notifications from 'modules/advocate-notifications/export';
import { qaSel } from '../../common/utils';
import Tooltip from '../disabled-tooltip';
import { isLensActive } from 'modules/lenses/api';

const NotificationsPopoverContainer = styled('div')`
  width: 350px;
  border-radius: 5px;
  overflow: hidden;
`;

const disabledBtn = inActiveLens => inActiveLens ? styles.disabledBtn : '';

class NotificationOverlay extends React.Component {
  state = {
    popoverOpen: false,
    inActiveLens: false
  };

  componentDidMount() {
    isLensActive().then(result => this.setState({ inActiveLens: result }));
  }

  handleClick = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  };

  handleClickOutside = () => {
    this.setState({ popoverOpen: false });
  };

  /* eslint complexity: 0 */
  render() {
    const { overlayAdjustments, toggleSeen, children, t, moment } = this.props;
    const { inActiveLens } = this.state;
    return (
      <div className={cn(styles.container, 'c-action-center-link c-notifications')}>
        <button
          data-tip={inActiveLens}
          data-for="disabled-notification"
          data-qa={qaSel('link-notifications')}
          className={cn(styles.iconLink, 'c-button c-button-icon', disabledBtn(inActiveLens))}
          ref={node => this.notifications = node}
          aria-label={t('Access your last notifications')}
          onClick={inActiveLens
            ? () => {}
            : e => {
              this.handleClick(e);
              toggleSeen();
            }
          }
        >
          {children}
        </button>
        {inActiveLens && <Tooltip id="disabled-notification" />}
        <Popover
          target={this.notifications}
          show={this.state.popoverOpen}
          onHide={this.handleClickOutside}
          {...overlayAdjustments}>
          <NotificationsPopoverContainer className="c-notification-container">
            <Notifications t={t} moment={moment} />
          </NotificationsPopoverContainer>
        </Popover>
      </div>
    );
  }
}

export default withNamespaces()(NotificationOverlay);
