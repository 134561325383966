import axios from 'axios';
import jwt2, { windowObj } from 'modules/jwt-2';
import * as R from 'ramda';

const api = jwt2();

const unnest = R.path(['data', 'data']);

export const publicHomepageTabs = () => axios.get('/api/public_homepage_tabs').then(unnest);
const disableNavListForClones = ['referrals', 'messages', 'notifications'];
export const advocateLinks = inActiveLens => api.get('/api/advocate_links').then(R.pipe(
  unnest,
  R.map(
    links => R.map(
      link => R.ifElse(
        R.both(
          () => inActiveLens,
          R.propSatisfies(x => R.contains(x, disableNavListForClones), 'type')
        ),
        R.assoc('disabled', true),
        R.assoc('disabled', false),
      )(link)
    )(links)
  )
));
export const profile = () => api.get('/people/self').then(unnest);
export const messageCount = () => api.get('/conversations/counts');
export const hubSwitcher = () => api.get('/hub_switcher')
  .then(unnest)
  .then(R.merge({ family: { companies: [] } }));
export const notificationsCount = () => api.get('/api/notifications/unread_count').then(({ data }) => data);
export const enabledFeatures = () => axios.get('/api/features/public').then(unnest);
export const permissions = () => api.get('/permissions').then(unnest);
export const unseenDiscussionsCounts = companyIds => {
  // This is commented out until we find a better solution for the hub switcher notification behaviour
  // return api.get(`/api/unseen/discussions?company_ids=${JSON.stringify(companyIds)}`).then(({ data }) => data);

  return Promise.resolve(R.zipObj(companyIds, []));
};
export const unseenChallengesCounts = companyIds => {
  // This is commented out until we find a better solution for the hub switcher notification behaviour
  // return api.get(`/api/unseen/challenges?company_ids=${JSON.stringify(companyIds)}`).then(({ data }) => data);

  return Promise.resolve(R.zipObj(companyIds, []));
};

export const customBranding = async() => {
  try {
    const { branding } = await windowObj();
    if (!branding.serializedBrandingUrl) throw new Error();
    return axios.get(branding.serializedBrandingUrl).then(R.prop('data'));
  } catch (e) {
    return axios.get('/api/branding').then(unnest);
  }
};

export const editProfile = () => api.get('/api/current_contact/edit').then(R.prop('data'));
export const deleteAccount = () => api.delete('/api/current_contact').then(R.prop('data'));
export const updateProfile = (data) => api.post('/api/current_contact/', data, {
  headers: { 'Content-Type': 'multipart/mixed' }
}).then(R.prop('data'));

export const updateTimezone = () => {
  if (window.Intl && window.Intl.DateTimeFormat) {
    api.post(
      '/api/current_contact/update_timezone',
      { timezone: window.Intl.DateTimeFormat().resolvedOptions().timeZone },
      { headers: { 'Content-Type': 'application/json' } }
    );
  }
};
