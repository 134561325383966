exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".infl-fe__style__contextSwitcher___2ZHlk {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  background-color: #FFFBDF;\n  border-bottom: 1px #DADDE0 solid;\n  text-align: center;\n  font-size: 12px;\n  color: #232B34;\n  padding: 3px;\n}\n  .infl-fe__style__contextSwitcher___2ZHlk ::after {\n    content: '';\n  }\n  @media (min-width: 400px) {.infl-fe__style__contextSwitcher___2ZHlk {\n    flex-direction: row\n}\n    .infl-fe__style__contextSwitcher___2ZHlk .infl-fe__style__contextLocation___3-0AU::after {\n      content: '•';\n      padding: 0 10px 0 3px;\n    }\n  }\n  @media (max-width: 768px) {\n    .infl-fe__style__contextSwitcher___2ZHlk .infl-fe__style__contextLocation___3-0AU {\n      display: none;\n    }\n  }\n\n.infl-fe__style__icon___9_PaM {\n  display: inline-block;\n  margin: 0 5px;\n}\n\n.infl-fe__style__toolTipText___3WjdH {\n  padding: 10px 0;\n}\n\n.infl-fe__style__link___3Scz6 {\n  color: #232B34;\n  text-decoration: underline;\n}\n\n.infl-fe__style__link___3Scz6:visited {\n    color: #232B34;\n  }\n\n.infl-fe__style__link___3Scz6:hover,\n  .infl-fe__style__link___3Scz6:active,\n  .infl-fe__style__link___3Scz6:focus {\n    text-decoration: none;\n  }\n", ""]);

// Exports
exports.locals = {
	"contextSwitcher": "infl-fe__style__contextSwitcher___2ZHlk",
	"contextLocation": "infl-fe__style__contextLocation___3-0AU",
	"icon": "infl-fe__style__icon___9_PaM",
	"toolTipText": "infl-fe__style__toolTipText___3WjdH",
	"link": "infl-fe__style__link___3Scz6"
};