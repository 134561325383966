exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".infl-fe__styles__companyBanner___1UMaA {\n  background-color: #0A5ECC;\n  position: relative;\n}\n\n.infl-fe__styles__customBanner___3Xqv5 {\n  display: block !important;\n}\n\n@media (max-width: 1024px) {\n\n.infl-fe__styles__defaultPadding___1Eun5 {\n    padding-left: 25px\n}\n  }\n\n.infl-fe__styles__customBanner___3Xqv5 > * {\n  box-sizing: content-box !important;\n}\n\n.infl-fe__styles__logo___PWwyS {\n  width: 1024px;\n  padding: 10px 0;\n  margin: 0 auto;\n}\n", ""]);

// Exports
exports.locals = {
	"companyBanner": "infl-fe__styles__companyBanner___1UMaA",
	"customBanner": "infl-fe__styles__customBanner___3Xqv5",
	"defaultPadding": "infl-fe__styles__defaultPadding___1Eun5",
	"logo": "infl-fe__styles__logo___PWwyS"
};