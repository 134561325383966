import React, { useEffect, useReducer } from 'react';
import { pipe } from 'ramda';
import Froala from '@influitive/secret-garden/lib/froala';
import ThemeProvider from '@influitive/secret-garden/lib/theme-provider';

import { customBranding, getProfileFields } from './api';
import emoticonsSet from './emotions-set';
import { froalaAIGeneratorEnabled } from 'src/common/utils';

import { setInternalValue, setBrandingColors, setCustomProfileFields } from './actions';
import reducer from './reducers';

const uploadOptions = {
  videoUploadURL: false,
  videoUploadToS3: window?._infl?.rte_s3_params?.video,
  videoMaxSize: 1024 * 1024 * 100,

  imageUploadURL: false,
  imageUploadToS3: window?._infl?.rte_s3_params?.image,
  imageMaxSize: 1024 * 1024 * 5,

  fileUploadURL: false,
  fileUploadToS3: window?._infl?.rte_s3_params?.file,
  fileMaxSize: 1024 * 1024 * 25
};

const globalConfig = {
  key: process.env.FROALA_KEY,
  attribution: false,
  editorClass: 'fr-app-container',
  emoticonsSet,
  filestack: () => window.app.filepicker,
  ...uploadOptions
};

const initialState = value => ({
  internalValue: value,
  brandingColors: [],
  customProfileFields: []
});

const Wrapper = pipe(
  ({ value, onChange, onTextChange, ...props }) => {
    const [state, dispatch] = useReducer(reducer, initialState(value));

    useEffect(() => {
      if (value !== state.internalValue) {
        dispatch(setInternalValue(value));
      }
    }, [value]);

    useEffect(() => {
      R.pipeP(
        customBranding,
        R.prop('color_scheme'),
        R.values,
        R.compose(dispatch, setBrandingColors)
      )();

      R.pipeP(
        getProfileFields,
        R.prop('data'),
        R.compose(dispatch, setCustomProfileFields)
      )();
    }, []);

    return {
      ...props,
      ...state,
      value: state.internalValue,
      onChange: html => {
        if (onChange && html !== state.internalValue) {
          // AI-GEN START - Cursor GPT4
          const hubUrl = window.location.origin;
          const s3Url = `https://s3.amazonaws.com/${window?._infl?.rte_s3_params?.video?.bucket}/`;
          // AI-GEN START - Cursor GPT4
          if (s3Url.includes('-priv-files')) {
            html = html.replace(s3Url, `${hubUrl}/s3_files?key=`);
          }
          // AI-GEN END
          // AI-GEN END
          dispatch(setInternalValue(html));
          onChange(html);
        }
      },
      onTextChange: text => {
        if (onTextChange && text !== state.internalValue) {
          onTextChange(text);
          dispatch(setInternalValue(text));
        }
      }
    };
  },
  ({ customOptions, value, onChange, onTextChange, ...props }) =>
    <ThemeProvider>
      <div id='froala-editor' className='froala-editor' data-qa={props['data-qa']}>
        <Froala
          {...props}
          value={value}
          onChange={onChange}
          onTextChange={onTextChange}
          onCodeViewToggle={isActive => isActive ? null : window.$?.gritter?.add('Code successfully applied')}
          customOptions={R.mergeDeepRight(customOptions, globalConfig)}
          enableAIGenerator={froalaAIGeneratorEnabled} />
      </div>
    </ThemeProvider>
);

Wrapper.defaultProps = {
  customOptions: {}
};

Wrapper.displayName = 'FroalaWrapper';

export default Wrapper;
