import t from 'tcomb';
import Leaf from './leaf';

const All = t.declare('All');
const Any = t.declare('Any');
const Not = t.declare('Not');
const Aql = t.union([Leaf, All, Any, Not]);

All.define(t.interface({
  $all: t.list(Aql)
}));

Any.define(t.interface({
  $any: t.list(Aql)
}));

Not.define(t.interface({
  $not: Aql
}));

export * from './leaf';
export { default as Operator } from './operator';
export { default as ValueInterfaces } from './value-interfaces';
export { All, Any, Not, Aql };
