import React from 'react';
import { compose, setDisplayName, withHandlers, defaultProps } from 'recompose';
import ChallengeCard from '@influitive/secret-garden/lib/challenge-card';
import Image from '@influitive/secret-garden/lib/image';
import defaultImg from './images/generic-challenge.png';
import { track } from '../../common/tracking-utils';

export default compose(
  defaultProps({
    markAsSeen: () => {}
  }),
  withHandlers({
    handleChallengeClick: ({ challenge, onChallengeOpen, markAsSeen }) => e => {
      e.preventDefault();
      e.stopPropagation();
      onChallengeOpen(challenge);
      challenge.unseen && markAsSeen(challenge);
      track('challenge', { id: challenge.id });
    }
  }),
  setDisplayName('ChallengeItem')
)(({ challenge, handleChallengeClick }) => (
  <ChallengeCard
    uuid={challenge.uuid}
    media={<Image width="230" src={challenge.image || defaultImg} alt={challenge.title} />}
    url={`/challenges/${challenge.id}`}
    title={challenge.title}
    category={challenge.category}
    points={challenge.points}
    statuses={challenge.statuses}
    onClick={handleChallengeClick}
    data-qa={`challenge-item-${challenge.id}`}
    new={challenge.unseen} />
));
