import { createReducer } from 'redux-act';

import { fetchedBadges, handleError } from '../actions/action-types';

export default createReducer({
  [fetchedBadges]: (state, payload) => ({
    ...state,
    [payload.id]: {
      ...state[payload.id],
      errorMsg: null,
      badges: payload.badges
    }
  }),
  [handleError]: (state, payload) => ({
    ...state,
    [payload.id]: {
      ...state[payload.id],
      badges: [],
      errroMsg: payload.errorMsg
    }
  })
}, {});
