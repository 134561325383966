import React from 'react';
import { compose, withState, withHandlers, withProps } from 'recompose';
import * as R from 'ramda';
import { withTheme } from 'emotion-theming';
import cn from 'classnames';

import { trackClick } from '../../common/tracking-utils';
import { qaSel } from '../../common/utils';
import { onDiscoursePageChange, isCurrPageWithOverrides, isContentSection } from '../../common/discourse-utils';
import styles from './style.css';
import ContentSectionsDropDown from './content-sections-dropdown';
import { Link, PlaceholderLink } from './style.js';
import Tooltip from '../disabled-tooltip';

// to be removed on July 23rd
const shouldNotHaveDynamicMoreMenu = () =>
  (window.location.hostname.split('.')[0] === 'pointclickcare')
  || (window.location.hostname.split('.')[0] === 'tagetik')
  || (window.location.hostname === 'insiders.tagetik.com');

const navSpace = () => R.min(window.innerWidth, 1024)
  - document.querySelector('.c-nav-details-container')?.offsetWidth
  - document.querySelector('#nav-container')?.offsetWidth;

const isSelected = (currentPageOverride, feature) => isCurrPageWithOverrides(
  currentPageOverride[feature.path],
  Object.keys(currentPageOverride).length !== 0,
  feature.type
);

const setNavItemDataId = (currentPageOverride, feature) => {
  const isActive = isSelected(currentPageOverride, feature) ? ' c-active' : '';
  return `c-nav-item ${isActive}`;
};

const Links = compose(
  withTheme,
  withState('currentPageOverride', 'setCurrentPageOverride', {}),
  withState('discourseEvent', 'setDiscourseEvent', null),
  withState('moreLinkNum', 'setMoreLinkNum', 0),
  withHandlers({
    onDiscourseEvent: ({ setCurrentPageOverride, features }) => e => {
      setCurrentPageOverride(onDiscoursePageChange(e, features));
    }
  }),
  withProps(({ features = [], moreLinkNum }) => R.pipe(
    R.splitAt(R.negate(moreLinkNum)),
    R.when(R.always(moreLinkNum === 0), R.reverse),
    ([standAloneLinks, dropDownLinks]) => ({
      standAloneLinks,
      dropDownLinks
    })
  )(features)),
)(class extends React.Component {
  componentDidMount() {
    document.addEventListener('discourse-page-change', this.props.setDiscourseEvent);

    const event = new Event('nav-loaded', { bubbles: true });
    document.getElementById('nav-container').dispatchEvent(event);

    window.addEventListener('resize', this.resizeHandler);
  }

  componentDidUpdate(prevProps) {
    this.makeNavFit();
    if (prevProps.features !== this.props.features) {
      this.props.onDiscourseEvent(this.props.discourseEvent);
    }
  }

  resizeHandler = () => {
    this.makeNavFit();
    this.props.onDiscourseEvent(this.props.discourseEvent);
  }

  /* eslint-disable complexity */
  makeNavFit = () => {
    if (!this.props.features) return;

    if (shouldNotHaveDynamicMoreMenu()) {
      const contentSectionLength = R.filter(isContentSection, this.props.features).length;

      if (contentSectionLength === this.props.moreLinkNum) return;

      this.props.setMoreLinkNum(contentSectionLength);
    } else if (navSpace() < -1) {
      this.props.setMoreLinkNum(this.props.moreLinkNum + 1);
    }
  };
  /* eslint-enable complexity */

  componentWillUnmount() {
    document.removeEventListener('discourse-page-change', this.props.setDiscourseEvent);
    window.removeEventListener('resize', this.resizeHandler);
  }
  render() {
    const { standAloneLinks, dropDownLinks, currentPageOverride } = this.props;
    return (
      <div id="nav-container" className={cn(styles.container, 'c-nav-list-container')}>
        <ul
          data-qa={qaSel('features')}
          className={cn(styles.navList, styles.navListTopLevel, 'c-nav-list')}>
          {R.isEmpty(standAloneLinks) ? <React.Fragment>
            <li className={cn(styles.navListItem, 'c-nav-list-item')} key={0}>
              <PlaceholderLink />
            </li>
            <li className={cn(styles.navListItem, 'c-nav-list-item')} key={1}>
              <PlaceholderLink />
            </li>
            <li className={cn(styles.navListItem, 'c-nav-list-item')} key={2}>
              <PlaceholderLink />
            </li>
            <li className={cn(styles.navListItem, 'c-nav-list-item')} key={3}>
              <PlaceholderLink />
            </li>
          </React.Fragment> : standAloneLinks.map(feature => (
            <li className={cn(styles.navListItem, 'c-nav-list-item')} key={feature.label}>
              <Link
                data-tip={feature.disabled}
                data-for={`disabled-${feature.type}`}
                disabled={feature.disabled}
                type={feature.type}
                data-qa={qaSel(`top-nav-link-${feature.type}`)}
                href={feature.disabled ? '#' : feature.path}
                onClick={trackClick(feature.type)}
                selected={isSelected(currentPageOverride, feature)}
                className={cn(setNavItemDataId(currentPageOverride, feature), `c-nav-item-${feature.type}`)}
                disabled={feature.disabled}>
                {feature.label}
              </Link>
              {feature.disabled && <Tooltip id={`disabled-${feature.type}`} />}
            </li>
          ))}
          {dropDownLinks.length > 0 && <ContentSectionsDropDown
            sectionsForDrownDown={dropDownLinks}
            currentPageOverride={currentPageOverride}>
          </ContentSectionsDropDown>}
        </ul>
      </div>
    );
  }
});

export default Links;
