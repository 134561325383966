import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import Sticky from 'react-stickynode';
import * as R from 'ramda';
import cn from 'classnames';
import { branch, compose, lifecycle, renderNothing, setDisplayName, withHandlers, withProps, withState } from 'recompose';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import Flex from '@influitive/secret-garden/lib/flex';
import Spinner from '@influitive/secret-garden/lib/spinner';
import Link from '@influitive/secret-garden/lib/link';
import Text from '@influitive/secret-garden/lib/text';

import { goToMemberView, contactSupport } from '../utils';
import InfoText from './info-text';
import LensBorder from './lens-border';
import { lensStates } from '../constants';

const StyledSpinner = styled(Spinner)`
  margin-right: ${props => props.theme.spacing[2]}px;
`;

const StyledLink = styled(Link)`
  color: white;
  font-size: ${props => props.theme.fonts.size[2]}px;
  margin-left: ${props => props.theme.spacing[3]}px;
  line-height: 1;

  &:hover {
    color: white;
  }

  &:disabled {
    opacity: 0.6;
  }
`;

const getLinkText = (lensState, retryCounter, inMemberView) => {
  const linkText = R.prop(lensState, {
    loading: 'Cancel',
    loaded: 'View Lens',
    active: inMemberView ? 'Refresh' : 'View on Member Side'
  });
  return linkText || (retryCounter < 3 ? 'Retry' : 'Contact Support and Reload');
};

const errorNotification = action => `An error occured while ${action} your Lens.`;

const Name = ({ name }) => (
  <Text data-qa='lens-name' color='white' type='bold' variant='small'>{name}</Text>
);

const ActiveText = ({ isMobile, inMemberView, name, date }) => (
  inMemberView
    ? isMobile
      ? <Fragment>Viewing as <Name name={name} />.</Fragment>
      : <Fragment>You are looking at a copy of <Name name={name} />'s view as of {date}.</Fragment>
    : <Fragment>You have an active Lens for <Name name={name} />.</Fragment>
);

const getNotificationAction = ({
  lensState,
  cancelLens,
  clearLens,
  activateLens,
  refreshLens,
  createLens,
  handleRetry,
  ...props
}) => R.prop(lensState, {
  loading: { message: <Fragment>Loading Lens for <Name name={props.name} />.</Fragment>, action: cancelLens },
  loaded: { message: <Fragment>Lens is ready for <Name name={props.name} />.</Fragment>, action: activateLens },
  active: { message: <ActiveText {...props} />, action: props.inMemberView ? refreshLens : goToMemberView },
  createError: { message:  errorNotification('creating'), action: () => createLens(props.uuid) },
  clearError: { message: errorNotification('clearing'), action: handleRetry(clearLens) },
  refreshError: { message: errorNotification('refreshing'), action: handleRetry(refreshLens) },
  activeError: { message: errorNotification('trying to view'), action: handleRetry(activateLens) },
  cancelError: { message: errorNotification('cancelling'), action: handleRetry(cancelLens) }
});

const InfoWrapper = compose(
  withProps(({ lensState, retryCounter, inMemberView, ...props }) => ({
    notification: getNotificationAction({ lensState, inMemberView, ...props }),
    linkText: getLinkText(lensState, retryCounter, inMemberView)
  })),
  setDisplayName('InfoWrapper')
)(({ isMobile, lensState, notification, linkText, lensActionPending, ...props }) => (
  <Flex data-qa='lens-info-bar' width={1} justifyContent={isMobile ? 'space-between' : 'center'} alignItems="center">
    {lensState === lensStates.LOADING && <StyledSpinner size="small" />}
    <InfoText isMobile={isMobile} lensState={lensState} {...props} notificationText={notification.message} />
    <StyledLink variant="secondary" onClick={notification.action} disabled={lensActionPending}>{linkText}</StyledLink>
  </Flex>
));

export default compose(
  branch(({ lensState, lensActionPending }) =>
    lensActionPending || !lensState || lensState === lensStates.CLEARED, renderNothing),
  withState('retryCounter', 'updateRetryCounter', 0),
  withProps(({ lens, clone, lensState }) => ({
    notificationRef: React.useRef(null),
    name: (lensState !== lensStates.CLEARED && lens?.['impersonated-user']) ?
      lens['impersonated-user']?.name : clone?.name,
    date: lens?.['loaded-at-with-timezone']
  })),
  withHandlers({
    handleMediaQueryChange: ({ setNotificationBarHeight, notificationRef, lensState, inMemberView }) => () => (
      inMemberView && lensState === lensStates.ACTIVE &&
        setNotificationBarHeight(notificationRef?.current?.offsetHeight)
    )
  }),
  withTheme,
  withProps(({ retryCounter, updateRetryCounter, handleMediaQueryChange, inMemberView, lensState, theme }) => ({
    handleRetry: action => retryCounter < 3 ?
      () => { updateRetryCounter(retryCounter + 1); action(); }
      : contactSupport,
    isMobile: useMediaQuery({ query: `(max-width: ${theme.sizes.phone}px)` }, undefined, handleMediaQueryChange),
    isTablet: useMediaQuery({ query: `(max-width: ${theme.sizes.tablet}px)` }, undefined, handleMediaQueryChange),
    enableStickyBar: inMemberView && lensState === lensStates.ACTIVE
  })),
  lifecycle({
    componentDidMount() {
      this.props.lensState === lensStates.ACTIVE && this.props.inMemberView &&
        this.props.setNotificationBarHeight(this.props.notificationRef?.current?.offsetHeight);
    }
  }),
  setDisplayName('NotificationBar')
)(styled(({ className, lensState, name, inMemberView, notificationRef, enableStickyBar, ...props }) => {
  return (
    <React.Fragment>
      <Sticky innerZ={1000} enabled={enableStickyBar}>
        <div ref={notificationRef}>
          <Flex alignItems='center' justifyContent='center' px={3} py={2} minHeight="30" className={cn(className, 'c-lens-info-bar')}>
            {name && <InfoWrapper name={name} lensState={lensState} inMemberView={inMemberView} {...props} />}
          </Flex>
        </div>
      </Sticky>
      <LensBorder lensState={lensState} inMemberView={inMemberView} />
    </React.Fragment>
  );
})`
  background: ${({ theme, lensState }) => {
    const colour = R.prop(lensState, {
      loading: theme.colors.primary[0],
      loaded: theme.colors.success[0],
      active: theme.colors.accent[0]
    });
    return colour || theme.colors.error[2];
  }}}
`);
