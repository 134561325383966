import React from 'react';
import { compose, setDisplayName, setPropTypes  } from 'recompose';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { CarouselProvider } from '@influitive/secret-garden/lib/carousel';

const StyledContainer = styled('div')`
  @media (max-width: 768px) {
    display: flex;
    width: calc(100% + 20px);
    margin-left: -15px;
    > ul {
      &::-webkit-scrollbar {
        display: none;
      }
      padding-left: 15px;
      margin-left: 0;
      margin-right: 0;
      > li:last-child {
        padding-right: 10px;
      }
    }
  }
`;

const ListCarousel = styled('ul')`
  display: flex;
  min-width: 100%;
  overflow-x: auto;
  padding: 0 25px 25px 25px;
  margin: 0 -25px -25px -25px;
  -webkit-overflow-scrolling: touch;
  @media (-moz-touch-enabled: 1), (pointer:coarse) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &::after {
    content: '';
    padding-right: 10px;
  }
`;

const ListItem = styled('li')`
  ${props => `
    display: flex;
    position: relative;
    min-width: ${props.minItemWidth}px;
    max-width: ${props.minItemWidth}px;
    scroll-snap-align: center;
    margin: 10px;
    flex-basis: auto;
    flex-grow: 1;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &::before {
      display: block;
      content: '';
      width: 100%;
      padding-top: ${props.aspectRatio[1] / props.aspectRatio[0] * 100}%;
    }
  `}
`;

const RatioContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Carousel = (({ aspectRatio, minItemWidth, children }) => (
  <StyledContainer>
    <CarouselProvider carouselContext={true}>
      <ListCarousel>
        {React.Children.map(children, child => (
          <ListItem aspectRatio={aspectRatio.split('/')} minItemWidth={minItemWidth}>
            <RatioContainer>{child}</RatioContainer>
          </ListItem>
        ))}
      </ListCarousel>
    </CarouselProvider>
  </StyledContainer>
));

export default compose(
  setPropTypes({
    children: PropTypes.node,
    minItemWidth: PropTypes.number,
    aspectRatio: PropTypes.string
  }),
  setDisplayName('MobileCarousel'),
)(Carousel);
