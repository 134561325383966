import React from 'react';
import MediaQuery from 'react-responsive';
import { compose, setDisplayName, lifecycle, withState, withPropsOnChange, withHandlers } from 'recompose';
import styled from '@emotion/styled';
import debounce from 'lodash.debounce';
import { withTheme } from 'emotion-theming';
import Carousel from '@influitive/secret-garden/lib/carousel';
import MobileCarousel from '../mobile-carousel';

const fadeWidth = breakpoint => ({
  'desktop': 64,
  'tablet-l': 30,
  'tablet-p': 23
}[breakpoint]);

const itemSpacing = (props, breakpoint) => ({
  'desktop': parseInt(props.theme.spacing[4]),
  'tablet-l': parseInt(props.theme.spacing[3]),
  'tablet-p': parseInt(props.theme.spacing[3])
}[breakpoint]);

export const fadeOffset = (multiplier, breakpoint) => props => (
  (fadeWidth(breakpoint) + itemSpacing(props, breakpoint)) * multiplier
);

const breakpoints = {
  'm': {
    itemsPerPage: 3,
    spacing: 3,
    showDots: true,
    alwaysShowArrows: true,
    size: 'medium'
  },
  'l': {
    itemsPerPage: 4,
    spacing: 4,
    showDots: true,
    alwaysShowArrows: true,
    size: 'large'
  },
  'xl': {
    itemsPerPage: 4,
    spacing: 4,
    showDots: true,
    alwaysShowArrows: false,
    size: 'x-large'
  }
};

const CarouselWrapper = styled('div')`
  ${props => `
    opacity: ${props.isVisible ? 1 : 0};
    width: calc(100% + ${fadeOffset(2, 'desktop')(props)}px);
    padding: ${props.theme.spacing[6]}px 0 100px;
    margin: -${props.theme.spacing[6]}px 0 -100px -${fadeOffset(1, 'desktop')(props)}px;
    overflow: hidden;
    transition: opacity 0.25s ease-out;
    @media (max-width: 1200px) {
      width: 100%;
      margin-left: calc((1024px - 100vw) * 0.5);
    }
    @media (max-width: 1024px) {
      margin-left: -${props.theme.spacing[2]}px;
    }
    @media (max-width: 480px) {
      margin-left: -${props.theme.spacing[2]}px;
      padding-left: ${props.theme.spacing[2] * 2}px;
    }
  `}
`;

const carouselFade = () => `
  position: absolute;
  width: ${fadeWidth('desktop') + 10}px;
  height: calc(100% + 30px);
  top: -10px;
  content: '';
  z-index: 2;
  @media (max-width: 1024px) {
    width: ${fadeWidth('tablet-l') + 10}px;
  }
  @media (max-width: 768px) {
    width: ${fadeWidth('tablet-p') + 10}px;
  }
`;

const CarouselContainer = styled('div')`
  position: relative;
  width: calc(100% - ${fadeOffset(2, 'desktop')}px);
  margin: 0 auto;
  z-index: 1;
  @media (max-width: 1024px) {
    width: calc(100% - ${fadeOffset(2, 'tablet-l')}px);
  }
  @media (max-width: 768px) {
    width: calc(100% - ${fadeOffset(2, 'tablet-p')}px);
  }
  @media (max-width: 480px) {
    width: 100%;
    margin: 0;
  }
  &:before {
    ${carouselFade}
    left: -${fadeOffset(1, 'desktop')}px;
    background: linear-gradient(
      270deg,
      rgba(255,255,255,0) 0%,
      rgba(255,255,255,0.25) 10%,
      rgba(255,255,255,0.5) 35%,
      rgba(255,255,255,1) 100%
    );
    @media (max-width: 1024px) {
      left: -${fadeOffset(1, 'tablet-l')}px;
    }
    @media (max-width: 768px) {
      left: -${fadeOffset(1, 'tablet-p')}px;
    }
    @media (max-width: 480px) {
      display: none;
    }
  }
  &:after {
    ${carouselFade}
    right: -${fadeOffset(1, 'desktop')}px;
    background: linear-gradient(
      -270deg,
      rgba(255,255,255,0) 0%,
      rgba(255,255,255,0.25) 10%,
      rgba(255,255,255,0.5) 35%,
      rgba(255,255,255,1) 100%
    );;
    @media (max-width: 1024px) {
      right: -${fadeOffset(1, 'tablet-l')}px;
    }
    @media (max-width: 768px) {
      right: -${fadeOffset(1, 'tablet-p')}px;
    }
    @media (max-width: 480px) {
      width: calc(30vw - ${props => props.theme.spacing[3]}px);
      right: calc(-30vw + ${props => props.theme.spacing[2]}px);
      background: transparent;
    }
  }
`;

const withBreakpointHandler = compose(
  withState('currBreakpoint', 'setBreakpoint', 'xl'),
  withState('isVisible', 'setVisibility', false),
  withHandlers({
    /* eslint-disable complexity */
    handleWindowResize: ({ currBreakpoint, setBreakpoint }) => () => {
      let newBreakpoint = currBreakpoint;
      if (window.innerWidth < 840) {
        newBreakpoint = 'm';
      } else if (window.innerWidth < 1024) {
        newBreakpoint = 'l';
      } else if (window.innerWidth > 1024) {
        newBreakpoint = 'xl';
      }
      currBreakpoint !== newBreakpoint && setBreakpoint(newBreakpoint);
    }
    /* eslint-enable complexity */
  }),
  withPropsOnChange(
    ['handleWindowResize'],
    ({ handleWindowResize }) => ({
      handleWindowResize: debounce(handleWindowResize, 250)
    })
  ),
  lifecycle({
    componentDidMount() {
      window.addEventListener('resize', this.props.handleWindowResize);
      this.props.handleWindowResize();
      setTimeout(() => this.props.setVisibility(true), 250);
    },
    componentWillUnmount() {
      window.removeEventListener('resize', this.props.handleWindowResize);
      this.props.handleWindowResize.cancel();
    }
  })
);

export default compose(
  withTheme,
  withBreakpointHandler,
  setDisplayName('Carousel')
)(({ children, theme, currBreakpoint, isVisible }) => (
  <MediaQuery maxWidth={767}>
    {matches =>
      matches ? (
        <MobileCarousel aspectRatio="18/19" minItemWidth={240}>{children}</MobileCarousel>
      ) : (
        <CarouselWrapper isVisible={isVisible}>
          <CarouselContainer>
            <Carousel
              itemsPerPage={breakpoints[currBreakpoint].itemsPerPage}
              spacing={parseInt(theme.spacing[breakpoints[currBreakpoint].spacing])}
              isOverflowHidden={false}
              showDots={breakpoints[currBreakpoint].showDots}
              alwaysShowArrows={breakpoints[currBreakpoint].alwaysShowArrows}
              size={breakpoints[currBreakpoint].size}
              aspectRatio="4.5/5">
              {children}
            </Carousel>
          </CarouselContainer>
        </CarouselWrapper>
      )
    }
  </MediaQuery>
));
