import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@rebass/grid/emotion';
import { css } from '@emotion/core';
import cn from 'classnames';

const UnreadContainer = styled(Box)`
  position: absolute;
  right: -5px;
`;

export const UnreadAlert = styled(Box)`
${props => css`
    display: ${props.show ? 'block' : 'none'};
    width: 10px;
    height: 10px;
    padding: 1px;
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.primary[0]};
    border-radius: 10px;
    box-shadow: ${props.noShadow ? 'none' : `0px 0px 0px 1px ${props.theme.colors.white}`};
  `}
`;

export const UnreadMarker = ({ show, className, noShadow = false }) => (
  <UnreadContainer className={cn(className, 'c-unread-marker-wrapper')}>
    <UnreadAlert show={show} noShadow={noShadow} className="c-unread-marker"/>
  </UnreadContainer>
);
