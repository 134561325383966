import React from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import { withNamespaces } from 'react-i18next';
import { branch, compose, lifecycle, renderNothing, setDisplayName, withState } from 'recompose';
import cn from 'classnames';

import { DisabledLink, LinkContainer, Link } from '../more-menu/links';
import { UnreadMarker } from '../unread-marker';
import { checkSelectedHub, isGatewayApp } from '../../common/utils';
import { isLensActive } from 'modules/lenses/api';

const HubList = styled(({ className, ...props }) => (
  <LinkContainer id="hub-switcher" className={cn(className, 'c-hub-switcher')} {...props}>
    {props.children}
  </LinkContainer>
))`
  padding-bottom: 0;
  border-top: 1px solid ${props => props.theme.colors.neutral[4]};
`;

const FamilyName = styled('span')`
  display: block;
  padding: 7px 15px 7px 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: ${props => props.theme.fonts.size[3]}px;
  color: ${props => props.theme.colors.neutral[1]};
  @media (min-width: 769px) {
    font-size: ${props => props.theme.fonts.size[2]}px;
    padding: 3px 15px 3px 15px;
  }
`;

const FamilyHubList = styled('ul')`
  margin: 0;
  list-style: none;
`;

const SwitchHeading = styled('li')`
  margin-bottom: -5px;
  padding: 10px 0 0 15px;
  font-size: ${props => props.theme.fonts.size[1]}px;
  color: ${props => props.theme.colors.neutral[2]};
  @media (min-width: 769px) {
    padding: 5px 0 0 15px;
  }
`;

const LinkContent = styled('span')`
  position: relative;
`;

const StyledUnreadMarker = styled(UnreadMarker)`
  right: -14px;
  top: -1px;
`;

const HubLink = (({ hub, indentLevel, unseenCount, inActiveLens }) => (
  inActiveLens
    ? <DisabledLink
      name={hub.name}
      indentLevel={indentLevel}
      tooltipMessage="This can't be previewed in Lens Mode." />
    : <Link
      href={R.prop('switch_url', hub)}
      selected={checkSelectedHub(hub)}
      indentLevel={indentLevel}
      className="c-hub-link">
      <LinkContent className={cn('c-hub-name', { 'c-active': checkSelectedHub(hub) })}>
        {hub.name}
        <StyledUnreadMarker show={unseenCount > 0} noShadow={true} />
      </LinkContent>
    </Link>
));

const Switcher = ({ hubs, indentLevel, unseenCounts, inActiveLens }) => (
  <React.Fragment>
    {hubs.map(hub => <li key={hub.id} className="c-nav-link">
      <HubLink
        hub={hub}
        indentLevel={indentLevel}
        unseenCount={R.path([hub.org_id, 'count'], unseenCounts)}
        inActiveLens={inActiveLens} />
    </li>)}
  </React.Fragment>
);

/* eslint complexity: 0 */
const HubSwitcher = compose(
  withState('inActiveLens', 'setInActiveLens', false),
  lifecycle({
    componentDidMount() {
      isLensActive().then(this.props.setInActiveLens);
    }
  }),
  branch(({ data, inActiveLens }) => inActiveLens && R.isEmpty(R.path(['family', 'companies'], data)), renderNothing),
  setDisplayName('HubSwitcher')
)(({ data, t, unseenCounts, inActiveLens }) => (
  <HubList>
    <SwitchHeading className="c-switch-to">{t('Switch to...')}</SwitchHeading>
    <li>
      <LinkContainer className="c-switcher-list">
        {R.has('family', data) &&
          <li>
            {data.family.enable_gateway && !inActiveLens
              ? <Link className="c-family-name" href={data.family.gateway_url}>{data.family.name}</Link>
              : <FamilyName className="c-family-name">{data.family.name}</FamilyName>}
            <FamilyHubList className="c-family-list">
              <Switcher hubs={R.path(['family', 'companies'], data)} indentLevel={1} unseenCounts={unseenCounts} inActiveLens={inActiveLens} />
            </FamilyHubList>
          </li>}
        {!inActiveLens && !isGatewayApp && !data.family.enable_gateway && R.has('others', data) &&
          <Switcher hubs={R.prop('others', data)} unseenCounts={unseenCounts} />}
      </LinkContainer>
    </li>
  </HubList>
));

export default withNamespaces()(HubSwitcher);
