import t from 'tcomb';
import { StringInterface, StringArrayInterface } from '../value-interfaces';

const ResponseBase = t.interface({
  question_id: t.String
});

const tuples = [
  StringInterface,
  StringArrayInterface
];

const Response = t.union(tuples.map(tuple => ResponseBase.extend(tuple, { strict: true })), 'Response');
export default Response;
