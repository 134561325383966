import React, { Fragment } from 'react';
import { branch, compose, renderNothing, setDisplayName } from 'recompose';
import styled from '@emotion/styled';
import { lensStates } from '../constants';

const LensBorderLeft = styled.div`
  left: 0;
  bottom: 0;
  position: fixed;
  height: 100%;
  width: 4px;
  z-index: 1000;
  background: ${props => props.theme.colors.accent[0]};
`;

const LensBorderRight = styled.div`
  right: 0;
  bottom: 0;
  position: fixed;
  height: 100%;
  width: 4px;
  z-index: 1000;
  background: ${props => props.theme.colors.accent[0]};
`;

const LensBorderBottom = styled.div`
  bottom: 0;
  position: fixed;
  height: 4px;
  width: 100%;
  z-index: 1000;
  background: ${props => props.theme.colors.accent[0]};
`;

const LensBorder = compose(
  branch(({ lensState, inMemberView }) => lensState !== lensStates.ACTIVE || !inMemberView, renderNothing),
  setDisplayName('LensBorder')
)(() => (
  <Fragment>
    <LensBorderLeft />
    <LensBorderRight />
    <LensBorderBottom />
  </Fragment>
));

export default LensBorder;
