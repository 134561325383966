import React from 'react';
import { setDisplayName } from 'recompose';
import styled from '@emotion/styled';
import { PreloaderElement, pulsatingAnimation } from '@influitive/secret-garden/lib//skeleton-preloader';
import { themeGet } from 'styled-system';

const SkeletonPreloader = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${pulsatingAnimation}
`;

const Avatar = styled(PreloaderElement)`
  border-radius: 100%;
  width: 128px;
  height: 128px;
  margin: ${themeGet('spacing.6')}px 0px ${themeGet('spacing.3')}px ${themeGet('spacing.6')}px ;
`;

const OpenFile = styled(PreloaderElement)`
  width: 150px;
  height: ${themeGet('spacing.4')}px;
  margin-left: ${themeGet('spacing.6')}px;
  margin-bottom: ${themeGet('spacing.6')}px;
`;

const FieldsSection = styled(PreloaderElement)`
  width: 520px;
  height: 320px;
  margin-left: ${themeGet('spacing.6')}px;
  margin-bottom: ${themeGet('spacing.6')}px;
`;

const Submit = styled(PreloaderElement)`
  align-self: flex-end;
  width: 100px;
  height: ${themeGet('spacing.5')}px;
  margin-right: ${themeGet('spacing.6')}px;
  margin-bottom: ${themeGet('spacing.6')}px;
`;

export default setDisplayName('Preloader')(({ className }) => (
  <SkeletonPreloader className={className}>
    <Avatar /><OpenFile /><FieldsSection /><Submit />
  </SkeletonPreloader>
));
