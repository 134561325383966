exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".infl-fe__style__container___3KJmN {\n  display: flex;\n  flex: 1 1 auto;\n  padding-left: 30px;\n  justify-content: flex-end;\n}\n\n.infl-fe__style__avatarImage___181b8 {\n  height: 50px;\n  width: 50px;\n  border-radius: 100%;\n  vertical-align: middle;\n}\n\n.infl-fe__style__nameLink___3jm5A {\n  display: flex;\n  flex-direction: column;\n  top: 1px;\n  margin-left: 15px;\n}\n\n.infl-fe__style__chevron___2Xzs7 {\n  display: inline;\n  font-size: 9px;\n  color: #B5BBC2;\n  margin-left: 5px;\n}\n\n.infl-fe__style__profileOptions___15mo0 {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "infl-fe__style__container___3KJmN",
	"avatarImage": "infl-fe__style__avatarImage___181b8",
	"nameLink": "infl-fe__style__nameLink___3jm5A",
	"chevron": "infl-fe__style__chevron___2Xzs7",
	"profileOptions": "infl-fe__style__profileOptions___15mo0"
};