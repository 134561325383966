import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const Error = ({ errorMsg, t }) => (
  R.isEmpty(errorMsg)
    ? null
    : <h3>{t(errorMsg)}</h3>
);

Error.propTypes = {
  errorMsg: PropTypes.string
};

export default withNamespaces()(Error);
