import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import App from './app';

const load = props => document.getElementById('infl-fe-delete-members') && render(
  <AppContainer>
    <App {...props} />
  </AppContainer>,
  document.getElementById('infl-fe-delete-members')
);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept('./app', load);
  }
}

window.inflLoader = window.inflLoader || {};
window.inflLoader = { ...window.inflLoader, loadDeleteMembers: load };
