import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import App from './app';
import { corporatePage } from '../../src/common/utils';

const load = () => document.getElementById('infl-fe-advocate-nav') && render(
  <AppContainer>
    <App />
  </AppContainer>,
  document.getElementById('infl-fe-advocate-nav')
);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept('./app', load);
  }
}

window.inflLoader = window.inflLoader || {};
window.inflLoader = { ...window.inflLoader, loadAdvocateNav: load };

if (!corporatePage()) {
  load();
} else {
  document.getElementById('infl-fe-advocate-nav')
    && (document.getElementById('infl-fe-advocate-nav').style.display = 'none');
}
