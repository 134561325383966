import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import styled from '@emotion/styled';

import Icon from '@influitive/secret-garden/lib/icon';
import vars from 'modules/vars';

import Badge from 'modules/badge-popover';
import UnlockedLabel from './unlocked-label';
import RemainingCountLabel from './remaining-count-label';
import { LevelHeaderWrapper, LevelName } from './no-level-header';
import NoLevelSection from './no-level-section';

// NOTE: some of the methods below are very similar to
// ones in modules/badges/components/badges.js
// we might want to merge them in the future

// accessors
const getLevelRank = R.path(['levelInfo', 'rank']);

// comparators, can be reversed with R.flip
const byLevelRank = R.ascend(getLevelRank); // level rank comparator
const byDate = R.ascend(R.prop('createdAt')); // date comparator

// grouping functions
const groupByLevelRank = R.groupWith(R.useWith(R.equals, [getLevelRank, getLevelRank]));

const sortAndGroupByRank = (
  R.pipe(
    R.sort(byLevelRank),
    groupByLevelRank,
    R.map(R.sortWith([
      byLevelRank,
      byDate
    ]))
  )
);

const format = R.map(badges => ({ level: R.prop('levelInfo', R.head(badges)), badges }));

const isEarned = R.pipe(
  R.prop('remainingProgressPercentage'),
  R.equals(0)
);

const LockIcon = styled(Icon)`
  margin-left: 5px;
`;

const LevelHeader = ({ level, badges }) => {
  const earnedBadges = R.filter(isEarned, badges);
  const isLevelBlocked = R.gt(level.requiredBadgesCount, earnedBadges.length);
  const remainingBadgesCount = level.requiredBadgesCount - earnedBadges.length;

  return (
    <LevelHeaderWrapper>
      <LevelName blocked={isLevelBlocked}>
        {level.name}
        {isLevelBlocked && <LockIcon icon='lock' fill={vars['neutral-3']} width='14px' />}
      </LevelName>
      {isLevelBlocked
        ? <RemainingCountLabel remaining={remainingBadgesCount} />
        : <UnlockedLabel />}
    </LevelHeaderWrapper>
  );
};

const LevelSection = ({ id, level, badges }) =>
  <React.Fragment>
    <LevelHeader level={level} badges={badges} />
    {badges.map(badge =>
      <Badge contactUUID={id} key={badge.id} badge={badge} />
    )}
  </React.Fragment>;

const sortAndGroup = R.pipe(sortAndGroupByRank, format);

const Badges = ({ id, badges }) => {
  return (
    <div className="c-badges">
      {sortAndGroup(badges).map(({ level, badges }) =>
        <div key={R.or(level.rank, 'unranked')}>
          {level.uuid
            ? <LevelSection id={id} level={level} badges={badges} />
            : <NoLevelSection id={id} level={level} badges={badges} />}
        </div>
      )}
    </div>
  );
};

Badges.propTypes = {
  id: PropTypes.string.isRequired,
  badges: PropTypes.array.isRequired
};

export default withNamespaces()(Badges);
