import React from 'react';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { qaSel } from '../../common/utils';

const List = styled('ul')`
  margin: 0;
`;

const ListItem = styled('li')`
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  border-bottom: 1px solid ${themeGet('colors.neutral.4')};
  ${props => props.unread && `background-color: ${themeGet('colors.neutral.4')(props)};`}
  word-break: break-word;
`;

const Content = styled('div')`
  margin-left: 10px;
  text-align: left;
`;

const Title = styled('div')`
  font-size: 13px;
`;

const Description = styled('div')`
  font-size: ${themeGet('fonts.size.1')}px;
  .truncate_3_lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const TimeAgo = styled('div')`
  font-size: ${themeGet('fonts.size.1')}px;
  color: ${themeGet('colors.neutral.1')};
`;

const Icon = styled('img')`
  min-width: 32px;
  width: 32px;
  height: 32px;
`;

const Notifications = ({ data, moment }) => (
  <List data-qa={qaSel('notification-list')} className="c-notification-list">
    {data.map(({ attributes }) => (
      <ListItem
        unread={!attributes.read}
        key={attributes['updated-at']}
        data-qa={qaSel('notification-item')}
        className="c-notification-item">
        <Icon src={attributes['image-url']} className="c-notification-icon"/>
        <Content className="c-notification-details">
          <Title className="c-notification-title">{attributes['notification-title']}</Title>
          <Description
            className="c-notification-description"
            data-qa={qaSel('notification-item-description')}
            dangerouslySetInnerHTML={{ __html: attributes['notification-text'] }}/>
          <TimeAgo className="c-notification-timestamp">{moment(attributes['updated-at']).fromNow()}</TimeAgo>
        </Content>
      </ListItem>
    ))}
  </List>
);

export default Notifications;
