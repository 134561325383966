import t from 'tcomb';

export const NumberOperator = t.enums({
  '$gt': 'greater Than',
  '$gteq': 'greater Than or equal to',
  '$lteq': 'less than or equal to',
  '$lt': 'less than'
}, 'NumberOperator');

export const StringOperator = t.enums({
  '$exact': 'exact string match',
  '$substring': 'substring of'
}, 'StringOperator');

export const ArrayOperator = t.enums({
  '$exact_in': 'exact match in array',
  '$substring_in': 'string of array item'
}, 'ArrayOperator');

export const TimeOperator = t.enums({
  '$before': 'before a time',
  '$after': 'after a time'
}, 'TimeOperator');

export const ImageOperator = t.enums({
  '$unset': 'empty image'
}, 'ImageOperator');

export const Operator = t.union([
  NumberOperator,
  StringOperator,
  ArrayOperator,
  TimeOperator,
  ImageOperator
]);

export default {
  NumberOperator,
  StringOperator,
  ArrayOperator,
  TimeOperator,
  ImageOperator,
  Operator
};
