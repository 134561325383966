import React from 'react';
import { withNamespaces } from 'react-i18next';
import styled from '@emotion/styled';

import Icon from '@influitive/secret-garden/lib/icon';

import vars from 'modules/vars';

const Wrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: theme.fonts.weight[3],
  fontSize: `${theme.fonts.size[3]}px`,
  color: theme.colors.success[0]
}));

const UnlockedLabel = ({ t }) => (
  <Wrapper>
    <Icon icon='check' fill={vars.success} width='24px' />
    <span>{t('Unlocked!')}</span>
  </Wrapper>
);

export default withNamespaces()(UnlockedLabel);
