import React from 'react';
import { Icon } from 'infl-icons';
import { withNamespaces } from 'react-i18next';
import cn from 'classnames';
import { compose, withState, lifecycle, renameProp } from 'recompose';
import CountUp from 'react-countup';
import * as R from 'ramda';

import { qaSel } from '../../common/utils';
import actionCenterStyles from '../action-center/style.css';
import styles from './style.css';

const Points = compose(
  withState('start', 'setStart', undefined),
  renameProp('value', 'end'),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (R.isNil(prevProps.end)) return;

      if (prevProps.end !== this.props.end || R.isNil(this.props.start)) {
        this.props.setStart(prevProps.end);
      }
    }
  })
)(({ end, start, onClick, t }) => (
  <a
    data-qa={qaSel('rewards')}
    className={cn(actionCenterStyles.iconLink, 'c-link c-points')}
    href="/rewards"
    onClick={onClick}>
    <div className={cn(styles.points, 'c-icon-wrapper')}>
      <Icon icon="coins" title={t('Your points balance')} />
      <div className={cn(styles.pointsAmt, 'c-points-balance')}>
        <CountUp start={start} end={end || 0} duration={1.5} formattingFn={e => e?.toLocaleString()} />
      </div>
    </div>
  </a>
));

export default withNamespaces()(Points);
