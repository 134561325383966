import styled from '@emotion/styled';
import Avatar from '@influitive/secret-garden/lib/avatar';
import Button from '@influitive/secret-garden/lib/button';
import Flex from '@influitive/secret-garden/lib/flex';
import Input from '@influitive/secret-garden/lib/form-inputs/input';
import TypeAhead from '@influitive/secret-garden/lib/form-inputs/type-ahead';
import Modal from '@influitive/secret-garden/lib/modal';
import ModalArea from '@influitive/secret-garden/lib/modal/modal-area';
import ModalHeader from '@influitive/secret-garden/lib/modal/modal-header';
import ModalTitle from '@influitive/secret-garden/lib/modal/modal-title';
import Tooltip from '@influitive/secret-garden/lib/tooltip';
import cn from 'classnames';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { toast } from 'react-toastify';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { themeGet } from 'styled-system';

import { isLensActive } from 'modules/lenses/api';
import { deleteAccount, editProfile, updateProfile } from '../../common/api';
import i18n from './localization';
import Preloader from './preloader';
import styles from './style.css';

const BottomLinksContainer = styled(Flex)`
border-bottom: 1px solid ${themeGet('colors.neutral.4')};
`;

const ProfileFileInput = styled('input')`
  padding-top: ${themeGet('spacing.3')}px;
`;

const InputWrapper = styled('div')`
  margin: ${themeGet('spacing.2')}px;
  flex: 0 0 calc(50% - 2*${themeGet('spacing.2')}px);
`;

const TypeaheadInputWrapper = styled(InputWrapper)`
  margin-top: calc(${themeGet('spacing.2')}px + ${themeGet('spacing.9')}px);
`;

const defaultOption = (option_value, options_list) => (
  options_list.map((option) => {
    if (option.value === option_value) { return option; }
  })
);

const disabledLink = inActiveLens => inActiveLens ? styles.disabledLink : '';

export default compose(
  withState('profileData', 'setProfileData', {}),
  withState('profileIsLoaded', 'setProfileIsLoaded', false),
  withState('inActiveLens', 'setInActiveLens', false),
  withHandlers({
    handleProfileImgChange: ({ profileData, setProfileData }) => event => {
      profileData.image = event.target.files[0];
      profileData.image_url = URL.createObjectURL(event.target.files[0]);

      setProfileData(profileData);
    },
    handleSubmit: ({ profileData }) => () => {
      const formData = new FormData();

      for (const field in profileData) {
        if (field !== 'image' && typeof profileData[field] === 'object') {
          formData.append(field, JSON.stringify(profileData[field]));
        } else {
          formData.append(field, profileData[field]);
        }
      }

      updateProfile(formData).then(() => window.location.reload());
    },
    handleDeleteAccount: ({ inActiveLens }) => e => {
      e.stopPropagation();
      if (inActiveLens) return;
      const deleteConfirm = window.prompt(
        i18n.t('We are sad to see you go! Are you sure you want to delete your account? All of your user data will be deleted forever and you will no longer be able to access your account.\n\nType DELETE to confirm')
      );

      if (deleteConfirm === 'DELETE') {
        deleteAccount()
          .then(() => {
            toast(i18n.t('We are processing your deletion request now. You will be logged out shortly.'));
            window.location.href = '/users/sign_out';
          })
          .catch(() => {
            toast.error(
              i18n.t('Sorry, we were unable to send your account deletion request. Please try again in a few minutes.')
            );
          });
      } else if (deleteConfirm !== null) {
        // User hit ok but didn't type DELETE
        toast(i18n.t('Sorry, you must type DELETE to confirm your account deletion.'));
      }
    },
    onInputChange: ({ profileData, setProfileData }) => field => event => {
      profileData[field] = event.target.value;

      setProfileData(profileData);
    },
    onDiscourseAttributesChange: ({ profileData, setProfileData }) => field => event => {
      profileData.discourse_contact_attributes[field] = event.target.value;

      setProfileData(profileData);
    },
    onLanguageChange: ({ profileData, setProfileData }) => event => {
      profileData.lang = event.value;

      setProfileData(profileData);
    },
    onTimeZoneChange: ({ profileData, setProfileData }) => event => {
      profileData.preference_attributes.time_zone = event.value;

      setProfileData(profileData);
    }
  }),
  lifecycle({
    componentDidMount() {
      isLensActive().then(this.props.setInActiveLens);
      editProfile().then(data => {
        this.props.setProfileData(data);
        this.props.setProfileIsLoaded(true);
      });
    }
  }),
  withNamespaces()
)(({
  onModalClose,
  onInputChange,
  handleProfileImgChange,
  profileData,
  handleSubmit,
  onDiscourseAttributesChange,
  profileIsLoaded,
  onLanguageChange,
  onTimeZoneChange,
  inActiveLens,
  handleDeleteAccount,
  t
}) => (
  <Modal isOpen={true} onClose={onModalClose} size="medium" verticalAlign="center" contentLabel={'title'}>
    <ModalArea variant="header">
      <ModalHeader closeModal={onModalClose}><ModalTitle>{t('Edit Profile')}</ModalTitle></ModalHeader>
    </ModalArea>
    {!profileIsLoaded
      ? <Preloader />
      : <React.Fragment>
        <Flex py={5} px={6} flexDirection={'column'} justifyContent={'space-around'}>
          <Avatar url={profileData.image_url} size={'x-large'} alt={t('Avatar Placeholder')} />
          <ProfileFileInput type="file" id="file" name="file" onChange={handleProfileImgChange}/>
        </Flex>
        <Flex className="c-edit-profile-fields" px={5} pb={4} flexDirection="row" justifyContent="start" flexWrap="wrap">
          <InputWrapper className="c-name">
            <Input onChange={onInputChange('name')} id={'name'} label={t('Name')} value={profileData.name} required={true} />
          </InputWrapper>
          <InputWrapper className="c-title">
            <Input onChange={onInputChange('title')} id={'title'} label={t('Job Title')} value={profileData.title} />
          </InputWrapper>
          <TypeaheadInputWrapper className="c-language">
            <TypeAhead
              id={'language'}
              label={t('Language')}
              placeholder={t('Select language')}
              options={profileData.supported_languages}
              defaultValue={defaultOption(profileData.lang, profileData.supported_languages)}
              onChange={onLanguageChange}
              isClearable={false} />
          </TypeaheadInputWrapper>
          <InputWrapper className="c-company">
            <Input onChange={onInputChange('company')} id={'company'} label={t('Company')} value={profileData.company} />
          </InputWrapper>
          <TypeaheadInputWrapper className="c-time-zone">
            <TypeAhead
              id={'time-zone'}
              label={t('Time Zone')}
              options={profileData.time_zone_list}
              defaultValue={defaultOption(profileData.preference_attributes.time_zone, profileData.time_zone_list)}
              onChange={onTimeZoneChange}
              isClearable={false} />
          </TypeaheadInputWrapper>
          <InputWrapper className="c-username">
            <Input onChange={onDiscourseAttributesChange('username')} id={'username'}  label={t('Username')} value={profileData.discourse_contact_attributes.username} />
          </InputWrapper>
        </Flex>
        <BottomLinksContainer px={6} pb={4} flexDirection={'column'} justifyContent={'space-around'}>
          <p>
            {t('If you would like to request an export or change of your data, contact')}{' '}
            <a
              href="https://influitive-supportdesk.zendesk.com/hc"
              target="_blank"
              rel="noopener noreferrer"
              title="Influitive Support"
            >
              {t('Support')}
            </a>
            {inActiveLens && (
              <Tooltip id="delete-account-tip" clickable={true}>
                  You cannot delete your account in Lens Mode.
              </Tooltip>
            )}{' '}
            {t('If you would like to delete your account, click')}{' '}
            <span data-tip data-for="delete-account-tip">
              <a
                className={cn(styles.iconLink, disabledLink(inActiveLens))}
                onClick={handleDeleteAccount}
                href="#"
              >
                {t('Delete Account')}
              </a>
            </span>
          </p>
        </BottomLinksContainer>
        <Flex px={4} py={3} justifyContent={'flex-end'}>
          {inActiveLens && <Tooltip id="save-profile-tip" clickable={true}>You cannot update your profile in Lens Mode.</Tooltip>}
          <div data-tip data-for="save-profile-tip">
            <Button disabled={inActiveLens} onClick={handleSubmit}>{t('Save Profile')}</Button>
          </div>
        </Flex>
      </React.Fragment>
    }
  </Modal>
));
