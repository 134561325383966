import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import styled from '@emotion/styled';
import cn from 'classnames';
import SgLink from '@influitive/secret-garden/lib/link';
import { withNamespaces } from 'react-i18next';

import Popover from '../popover';
import { qaSel } from '../../common/utils';
import { Icon } from 'infl-icons';
import { Link } from './style.js';
import styles from './style.css';
import { trackClick } from '../../common/tracking-utils';
import { isCurrPageWithOverrides } from '../../common/discourse-utils';

const ContentSectionLink = styled('li')`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 ${props => props.theme.spacing[4]}px 0 0;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  &:first-child {
    margin-left: 0;
  }
  font-size: ${props => props.theme.fonts.size[3]}px;
  font-weight: ${props => props.selected ? props.theme.fonts.weight[3] : props.theme.fonts.weight[1]} !important;
  color: ${props => props.selected ? props.theme.colors.primary[0] : props.theme.colors.neutral[1]} !important;
  &:focus, &:active, &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.primary[0]} !important;
  }
`;

const StyledSgLink = styled(SgLink)`
  color: inherit;
  &:focus, &:active, &:hover {
    text-decoration: inherit;
  }
`;

let popOverRef = null;

const isAnyChildCurrentPageOverride = (currentPageOverride, features) => R.any(feature => isCurrPageWithOverrides(
  currentPageOverride[feature.path],
  Object.keys(currentPageOverride).length !== 0,
  feature.type
), features);

const ContentSectionsDropDown = compose(
  withState('dropDownIsOpen', 'setDropDownIsOpen', false),
  withHandlers({
    onContentDropdownClick: ({ dropDownIsOpen, setDropDownIsOpen }) => () => {
      setDropDownIsOpen(!dropDownIsOpen);
    },
    onHide: ({ setDropDownIsOpen }) => () => {
      setDropDownIsOpen(false);
    },
    onBlurHandler: ({ setDropDownIsOpen }) => () => {
      document.querySelector('.c-notifications .c-button').focus();
      setDropDownIsOpen(false);
    }
  }),
  withNamespaces()
)(({
  sectionsForDrownDown,
  currentPageOverride,
  onContentDropdownClick,
  dropDownIsOpen,
  setDropDownIsOpen,
  onBlurHandler,
  onHide,
  t
}) => (
  <ContentSectionLink
    className="c-nav-list-item c-more"
    selected={isAnyChildCurrentPageOverride(currentPageOverride, sectionsForDrownDown)}
    ref={node => popOverRef = node} onClick={onContentDropdownClick}>
    <StyledSgLink className="c-more-text" >
      <span>{t('More')}</span>
      <span
        className={cn(styles.contentSectionCaret, 'c-content-section-caret')}
        style={{ color: 'inherit' }}>
        <Icon icon="chevron-down" title="Chevron down" />
      </span>
    </StyledSgLink>
    <Popover
      target={popOverRef}
      show={dropDownIsOpen}
      minWidth="initial"
      setDropDownIsOpen={setDropDownIsOpen}
      onHide={onHide}>
      <ul className={cn(styles.navList, 'c-nav-list')}>
        {sectionsForDrownDown.map((section, i) =>
          <Link
            className={`c-${section.type}-${section.label}`}
            key={section.path}
            type={section.type}
            tabIndex="9"
            data-qa={qaSel(`top-nav-link-${section.type}`)}
            href={section.path}
            onClick={trackClick(section.type)}
            selected={isCurrPageWithOverrides(
              currentPageOverride[section.path],
              Object.keys(currentPageOverride).length !== 0,
              section.type
            )}
            onBlur={(i === sectionsForDrownDown.length - 1) ? onBlurHandler : undefined}>
            <li className={cn(styles.contentSectionItem, 'c-content-section-item')}>
              {section.label || section.name}
            </li>
          </Link>
        )}
      </ul>
    </Popover>
  </ContentSectionLink>
));

export default ContentSectionsDropDown;
