exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".infl-fe__style__mobileHeader___z3lVL {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 6px 18px;\n  border-bottom: 1px solid #DADDE0;\n  background-color: #ffffff;\n}\n.infl-fe__style__avatar___vXdjW {\n  display: flex;\n  justify-content: flex-start;\n  flex-grow: 1;\n  flex-basis: 0;\n}\n.infl-fe__style__avatarNoCoinIcon___2NXAf {\n  display: flex;\n  justify-content: flex-start;\n  flex-basis: 0;\n}\n.infl-fe__style__profileLink___1Y4Gi {\n  color: #232B34;\n}\n.infl-fe__style__profileLink___1Y4Gi:hover, .infl-fe__style__profileLink___1Y4Gi:focus, .infl-fe__style__profileLink___1Y4Gi:active{\n    background-color: #DADDE0;\n    text-decoration: none;\n  }\n.infl-fe__style__logoContainer___1aWcW {\n  display: flex;\n  justify-content: center;\n  flex-grow: 1;\n  flex-basis: 0;\n}\n.infl-fe__style__logo___2oznn {\n  max-width: 310px;\n  max-height: 30px;\n  margin-top: 5px;\n  margin-left: 5px;\n}\n.infl-fe__style__pointsContainer___8LCXD {\n  display: flex;\n  justify-content: flex-end;\n  flex-grow: 1;\n  flex-basis: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"mobileHeader": "infl-fe__style__mobileHeader___z3lVL",
	"avatar": "infl-fe__style__avatar___vXdjW",
	"avatarNoCoinIcon": "infl-fe__style__avatarNoCoinIcon___2NXAf",
	"profileLink": "infl-fe__style__profileLink___1Y4Gi",
	"logoContainer": "infl-fe__style__logoContainer___1aWcW",
	"logo": "infl-fe__style__logo___2oznn",
	"pointsContainer": "infl-fe__style__pointsContainer___8LCXD"
};