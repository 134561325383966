import React from 'react';
import { withNamespaces } from 'react-i18next';
import styled from '@emotion/styled';

export const LevelHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing[1]}px;
  margin-top: ${props => props.theme.spacing[4]}px;
  ${props => props.noLevel && `
    border-top: 2px solid ${props.theme.colors.neutral[5]};
    padding-top: ${props.theme.spacing[5]}px;
  `}
`;

export const LevelName = styled.p(({ theme, blocked }) => ({
  display: 'flex',
  alignItems: 'center',
  color: blocked ? theme.colors.neutral[3] : theme.colors.neutral[1],
  fontSize: `${theme.fonts.size[4]}px`,
  lineHeight: `${theme.fonts.lineHeight[4]}px`,
  fontWeight: theme.fonts.weight[3],
  marginRight: '20px',
  marginBottom: 0
}));

const Description = styled.div`
  color: ${props => props.theme.colors.neutral[3]};
`;

const NoLevelHeader = ({ level, t }) => (
  <LevelHeaderWrapper noLevel={true}>
    <LevelName>
      {level.name}
    </LevelName>
    <Description>
      {t('These badges are not part of any particular level - click to see details!')}
    </Description>
  </LevelHeaderWrapper>
);

export default withNamespaces()(NoLevelHeader);
