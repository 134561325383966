import React, { Component } from 'react';
import ThemeProvider from '@influitive/secret-garden/lib/theme-provider';

import DeleteMembers from './delete-members';

export default class App extends Component {
  render() {
    return (
      <ThemeProvider>
        <DeleteMembers {...this.props} />
      </ThemeProvider>
    );
  }
}
