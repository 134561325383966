import { froalaAIGeneratorEnabled } from '../../src/common/utils';

export const addAIGeneratorButton = (buttonsSettings, anchorButton) => {
  if (!froalaAIGeneratorEnabled) {
    return buttonsSettings;
  }

  const anchorButtonIndex = buttonsSettings.buttons.indexOf(anchorButton) + 1;

  return {
    buttons: [
      ...buttonsSettings.buttons.slice(0, anchorButtonIndex),
      'aiGenerator',
      ...buttonsSettings.buttons.slice(anchorButtonIndex, buttonsSettings.buttons.length)
    ],
    buttonsVisible: buttonsSettings.buttonsVisible + 1
  };
};
