import React from 'react';
import cn from 'classnames';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import { branch, compose, lifecycle, renderNothing, setDisplayName, withProps, withState } from 'recompose';
import Flex, { Box } from '@influitive/secret-garden/lib/flex';
import Icon from '@influitive/secret-garden/lib/icon';
import Link from '@influitive/secret-garden/lib/link';

import { enabledFeatures, permissions } from './api';

const MobileValue = compose(
  withTheme,
  setDisplayName('MobileValue')
)(({ theme }) => (
  <Flex m={1} height={theme.spacing[4]}><Icon icon={'context-switcher'} fill={'white'} width={`${theme.spacing[4]}px`} /></Flex>
));

const DesktopValue = ({ inMemberView }) => (
  <Box display="inline-flex" justifyContent="center" alignItems="center">
    <MobileValue />Switch to {inMemberView ? 'Admin' : 'Member '} View
  </Box>
);

export default compose(
  withState('permissions', 'setPermissions', null),
  withState('enabledFeatures', 'setEnabledFeatures', null),
  lifecycle({
    componentDidMount() {
      permissions().then(this.props.setPermissions);
      enabledFeatures().then(this.props.setEnabledFeatures);
    }
  }),
  withProps(({ enabledFeatures, permissions }) => ({
    hasCorporateAccess: !enabledFeatures?.includes('role_management_restructure') || permissions?.includes('corporate_access')
  })),
  branch(({ inMemberView, permissions, enabledFeatures, hasCorporateAccess }) => (
    inMemberView && (!permissions || !enabledFeatures || !hasCorporateAccess)
  ), renderNothing),
  setDisplayName('ContextSwitcher')
)(styled(({ className, useIcon, inMemberView, dataSelector }) => (
  <Link className={cn(className, 'c-link')}
    data-qa={inMemberView ? `${dataSelector}_switch-to-admin` : `${dataSelector}_switch-to-advocate`}
    href={inMemberView ? '/corporate' : '/'}>
    {useIcon ? <MobileValue /> : <DesktopValue inMemberView={inMemberView} />}
  </Link>
))`
  ${props => css`
    color: ${props.theme.colors.white} !important;
    font-weight: ${props.theme.fonts.weight[3]};
    font-size: ${props.theme.fonts.size[3]}px;
    margin-left: ${props.theme.spacing[2]}px;

    &:visited {
      font-weight: ${props.theme.fonts.weight[3]};
    }

    &:hover,
    &:focus {
      color: ${props.theme.colors.white};
    }

    @media (min-width: ${props.theme.sizes.tablet + 1}px) {
      min-width: 140px;
    }
  `}
`);
