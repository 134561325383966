import memoize from 'memoizee';
import jwt2 from 'modules/jwt-2';
import * as R from 'ramda';

import { convertTermToAql } from './utils';
import { lensStates } from './constants';

const api = jwt2();

export const createLens = id => api.post('/lenses', { impersonated_uuid: id });

export const clearLens = () => api.delete('/lenses');

export const refreshLens = () => api.post('/lenses/refresh');

export const activateLens = () => api.post('/lenses/activate');

export const cancelLens = () => api.post('/lenses/cancel');

export const isLensActive = () => Promise.resolve(window._infl.currentContact.isClone);

const isLensStateChangeNotificationLoaded = R.both(
  R.propEq('action-type', 'lens_state_change'),
  R.propEq('state', lensStates.LOADED)
);

const subscribeToPusher = ({ lens, lensNotificationHandler }) => {
  const impersonatorUuid = (lens.state === lensStates.CLEARED)
    ? R.path(['_infl', 'currentContact', 'id'], window)
    : lens['impersonator-uuid'];
  const hedwigApiClient = require('modules/hedwig-api-client');

  hedwigApiClient.subscribe(({ data: { attributes } }) => (
    isLensStateChangeNotificationLoaded(attributes) && lensNotificationHandler(attributes?.state)
  ), null, impersonatorUuid);
};

export const subscribeToLens = memoize(({ onLensReceived, lensNotificationHandler }) => (
  api.get('/lenses/current').then(data => {
    const lens = data.data?.data?.attributes || { state: 'cleared' };
    onLensReceived && onLensReceived(lens);

    if (lens.state !== lensStates.ACTIVE) {
      subscribeToPusher({ lens, lensNotificationHandler });
    }
  }).catch(e => console.error('Could not retrieve lens:', e))
));

export const advocateSearch = memoize((val, groupId, canHaveProfileLinks) => api.get('advocate_search', {
  params: {
    aql: convertTermToAql(val, groupId)
  }
}).then(R.pipe(
  R.prop('data'),
  R.map(option => ({
    value: R.trim(option.name),
    label: R.trim(option.name),
    titleAttr: 'View profile in a new tab',
    hasProfileLink: canHaveProfileLinks,
    ...option
  }))
)).catch(e => console.error('there was a problem fetching search results.', e)),
{
  primitive: true,
  promise: true
});

export const searchGroups = memoize(query => api.get('/targeting/groups/search', {
  params: { query }
}).then(data => R.pipe(
  R.path(['data', 'results']),
  R.map(option => ({
    value: R.trim(option.uuid),
    label: R.trim(option.name),
    ...option
  }))
)(data)));
