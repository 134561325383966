import React from 'react';
import { withNamespaces } from 'react-i18next';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  color: ${props => props.theme.colors.neutral[3]};
`;

const RemainingCountLabel = ({ remaining, t }) => (
  <Wrapper>
    <strong>{t(`{{number}} more badge${remaining === 1 ? '' : 's'}`, { number: remaining })}</strong>
    &nbsp;
    {t('required to unlock this level!')}
  </Wrapper>
);

export default withNamespaces()(RemainingCountLabel);
