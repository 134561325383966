import * as R from 'ramda';

const isUserTrackingCookiesDisabled = (typeof window === 'undefined') ? undefined : R.path(['_infl', 'hub', 'isUserTrackingCookiesDisabled'], window);

const nameSpaceKeys = o => Object.keys(o).reduce((acc, curr) => {
  acc[`current_contact_${curr}`] = o[curr];
  return acc;
}, {});

/* eslint-disable no-unused-vars */
export default {
  init: (_token) => {
    let tracking_options = {};
    if (isUserTrackingCookiesDisabled) {
      tracking_options = {
        opt_out_tracking_by_default: true,
        opt_out_persistence_by_default: true
      };
    }

    // initialize tracking here
    // tracker.init(...opts);
  },
  track: (_eventName, _props) => {

    let defaultProps = {};
    if (window && window._infl) {
      defaultProps = {
        subdomain: window._infl.hub.subdomain,
        ...nameSpaceKeys(window._infl.currentContact)
      };
    }

    // call tracking here
    // return tracker.track(eventName, { ...defaultProps, ...props });
    return true;
  }
};
