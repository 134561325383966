import React from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import { compose, lifecycle, setDisplayName, withHandlers, withStateHandlers, withProps } from 'recompose';
import Button from '@influitive/secret-garden/lib/button';
import { themeGet } from 'styled-system';
import cn from 'classnames';

import { qaSel } from '../../common/utils';
import { notifications } from '../../common/api';
import Loader from '../loader';
import Notifications from '../notifications';

const StyledButton = styled(Button)`
  box-sizing: border-box;
`;

const NotificationContainer = styled('div')`
  background-color: ${themeGet('colors.white')};
  color: ${themeGet('colors.neutral.0')};
  text-align: center;
  font-weight: ${themeGet('fonts.weight.1')};
  font-size: ${themeGet('fonts.size.5')}px;
`;

const NotificationNavigation = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${themeGet('colors.neutral.4')};
`;

export default compose(
  withStateHandlers(
    () => ({
      page: 1,
      loading: false,
      notifications: null,
      nextDisabled: false
    }),
    {
      setLoading: state => () => ({ ...state, loading: true, nextDisabled: true }),
      setLoadedData: state => (notifications, page) => ({ ...state, notifications, page, loading: false }),
      setNextDisabled: state => (nextDisabled) => ({ ...state, nextDisabled })
    }
  ),
  withHandlers({
    checkNextPage: ({ setNextDisabled }) => newPage => {
      notifications(newPage).then(nextPageNotifications => {
        setNextDisabled(R.isEmpty(nextPageNotifications));
      });
    }
  }),
  withHandlers({
    changePage: ({ setLoading, setLoadedData, checkNextPage }) => newPage => {
      setLoading();

      notifications(newPage).then(data => {
        setLoadedData(data, newPage);
        checkNextPage(newPage + 1);
      });
    }
  }),
  withHandlers({
    showNextPage: ({ changePage, page }) => () => changePage(page + 1),
    showPreviousPage: ({ changePage, page }) => () => changePage(page - 1)
  }),
  lifecycle({
    componentDidMount() {
      this.props.changePage(1);
    }
  }),
  withProps(({ page, loading, nextDisabled }) => ({
    disableBack: page === 1 || loading,
    disableNext: nextDisabled || loading
  })),
  setDisplayName('Notifications')
)(({ t, loading, showNextPage, showPreviousPage, disableBack, disableNext, notifications, moment }) => (
  <NotificationContainer data-qa={qaSel('popover')} id="ADVOCATE-NOTIFICATIONS">
    <NotificationNavigation className="c-notification-navigation">
      <StyledButton
        data-qa={qaSel('notifications-back')}
        variant="secondary"
        disabled={disableBack}
        onClick={showPreviousPage}
        className={cn('c-prev', { 'c-disabled': disableBack })}>
        {t('Back')}
      </StyledButton>
      <div className="c-title">{t('Notifications')}</div>
      <StyledButton
        data-qa={qaSel('notifications-more')}
        variant="secondary"
        disabled={disableNext}
        onClick={showNextPage}
        className={cn('c-next', { 'c-disabled': disableNext })}>
        {t('More')}
      </StyledButton>
    </NotificationNavigation>
    {notifications === null || loading ?
      <Loader /> :
      notifications && <Notifications moment={moment} data={notifications} />
    }
  </NotificationContainer>
));
