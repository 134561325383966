import React from 'react';
import * as R from 'ramda';
import { Box } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { compose, lifecycle, withHandlers, withState, defaultProps } from 'recompose';
import { Icon } from 'infl-icons';
import Link from '@influitive/secret-garden/lib/link';
import { withNamespaces } from 'react-i18next';
import cn from 'classnames';

import { messageCount } from '../../common/api';
import { isMobileApp,
  currPage,
  isCurrPage,
  isLinkEnabled,
  qaSel,
  sumUnseenCounts
} from '../../common/utils';
import { isLensActive } from 'modules/lenses/api';
import { track } from '../../common/tracking-utils';
import Burger from '../burger';
import MoreMenu from '../more-menu';
import Tooltip from '../disabled-tooltip';
import styles from './style.css';
import('./inbox-override.css');
import { UnreadMarker } from '../unread-marker';

isMobileApp() && import('./mobile-app.css');

const menuItems = (t, unseenNotificationsCount, inActiveLens) => [
  { icon: 'home', type: 'home', path: '/', title: t('Access home page') },
  { icon: 'trophy', type: 'challenges', path: '/challenges', title: t('Access Challenges page'), track: 'mNav_challenges' },
  { icon: 'conversation', type: 'discussions', path: '/forum', title: t('Access Discussions page'), track: 'mNav_discussions' },
  { icon: 'pulse', type: 'activity_feed', path: '/activity_feed', title: t('Access Activity Feed page') },
  {
    icon: 'bell',
    type: 'notifications',
    path: '/mobile_notifications',
    title: t('Access Notifications page'),
    unread: unseenNotificationsCount > 0,
    disabled: inActiveLens
  }
];

const IconLink = styled(props => <Link {...R.dissoc('isSelected', props)} />)`
  padding: 0 10px;
  position: relative;

  &:link, &:visited, &:hover, &:active {
    color: ${props => props.isSelected ? props.theme.colors.primary[0] : props.theme.colors.neutral[2]};
  }
  ${props => props.disabled && css`
      color: ${props.theme.colors.neutral[3]}}

      &:hover, &:active, &:focus, &:visited {
        color: ${props.theme.colors.neutral[3]}}
      }
  `}
`;

const MobileUnreadMarker = styled(UnreadMarker)`
  right: 7px;
  top: 4px
`;

const inboxMessageEvent = 'influitive:inbox-messages-count';

const MobileIconMenuItem = compose(
  withHandlers({
    handleLinkClick: ({ selectedItem, setSelected, item }) => e => {
      if (selectedItem === 'more' && isCurrPage(item.type)) {
        e.preventDefault();
        setSelected(item.type);
      }
      item.track && track(item.track);
    }
  })
  /* eslint-disable complexity */
)(({ item, selectedItem, handleLinkClick }) => (
  <div className={cn(styles.menuItem, 'c-menu-item')}>
    <IconLink
      data-tip={item.disabled}
      data-for={`disabled-${item.type}`}
      data-qa={qaSel(`mobile-icon-menu-${item.type}`)}
      href={item.disabled ? '#' : item.path}
      isSelected={selectedItem === item.type}
      onClick={handleLinkClick}
      aria-label={item.title}
      disabled={item.disabled}>
      {item.unread && selectedItem !== item.type && <MobileUnreadMarker show={item.unread}/>}
      <Icon icon={item.icon} title={item.title} />
      {item.disabled && <Tooltip id={`disabled-${item.type}`} />}
    </IconLink>
  </div>
));

const MobileIconMenuContent = compose(
  withState('selectedItem', 'setSelected', currPage),
  withState('msgCount', 'msgCountReceived', 0),
  withState('inActiveLens', 'setInActiveLens', false),
  withHandlers({
    handleMoreMenu: ({ selectedItem, setSelected, setStickyEnabled }) => () => {
      setSelected(selectedItem === 'more' ? currPage : 'more');
      setStickyEnabled && setStickyEnabled(selectedItem === 'more');
    },
    getMessageCount: props => () => {
      messageCount().then(count => {
        props.msgCountReceived(count.data.attributes['unread-conversation-count']);
      });
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.getMessageCount();
      isLensActive().then(this.props.setInActiveLens);
      window.document.addEventListener(inboxMessageEvent, this.props.getMessageCount);
    },
    componentWillUnmount() {
      window.document.removeEventListener(inboxMessageEvent, this.props.getMessageCount);
    }
  }),
  withNamespaces(),
  defaultProps({
    useSuspense: false
  })
)(({
  features,
  selectedItem,
  setSelected,
  handleMoreMenu,
  hubSwitcher,
  msgCount,
  t,
  unseenCounts,
  unseenNotificationsCount,
  inActiveLens
}) => {
  const menu = menuItems(t, unseenNotificationsCount, inActiveLens)
    .map(item => isLinkEnabled(features || [], item) && <MobileIconMenuItem
      key={item.type}
      item={item}
      selectedItem={selectedItem}
      setSelected={setSelected} />
    )
    .filter(R.identity);

  return <React.Fragment>
    <div className={cn(styles.mobileIconMenu, 'c-mobile-icon-menu')}>
      {menu.length === 0 ? <React.Fragment>
        <div className={styles.menuItem} key="0" />
        <div className={styles.menuItem} key="1" />
        <div className={styles.menuItem} key="2" />
        <div className={styles.menuItem} key="3" />
      </React.Fragment> : <React.Fragment>
        {menu}
      </React.Fragment>}
      <div className={cn(styles.menuItem, 'c-menu-item')}>
        <Burger
          showNotification={(msgCount + sumUnseenCounts(unseenCounts)) > 0}
          onClick={handleMoreMenu}
          open={selectedItem === 'more'} />
      </div>
    </div>
    <MoreMenu
      open={selectedItem === 'more'}
      features={features}
      hubSwitcher={hubSwitcher}
      showMessagesNotification={msgCount > 0}
      unseenCounts={unseenCounts} />
  </React.Fragment>;
});

const MobileIconMenu = ({ features, hubSwitcher, setStickyEnabled, unseenCounts, unseenNotificationsCount }) => <Box>
  {isMobileApp()
    ? <Box className={cn(styles.bottomFixed, 'c-bottom-fixed')}>
      <MobileIconMenuContent features={features} hubSwitcher={hubSwitcher} unseenCounts={unseenCounts}
        unseenNotificationsCount={unseenNotificationsCount} />
    </Box>
    : <MobileIconMenuContent features={features} hubSwitcher={hubSwitcher}
      unseenCounts={unseenCounts} setStickyEnabled={setStickyEnabled}
      unseenNotificationsCount={unseenNotificationsCount}/>}
</Box>;

export default MobileIconMenu;
