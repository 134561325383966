exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".infl-fe__style__container___KemFm {\n  display: flex;\n  flex: 3;\n  justify-content: left;\n  align-items: center;\n  padding: 16px 0;\n}\n\n.infl-fe__style__navList___A0H6H {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\n\n.infl-fe__style__navListTopLevel___3AiRU {\n  display: flex;\n  width: 100%;\n}\n\n@media (max-width: 1024px) {\n\n.infl-fe__style__navListTopLevel___3AiRU {\n    margin: 0 0 0 35px\n}\n  }\n\n.infl-fe__style__navListItem___2fSC4 {\n  display: inline-block;\n  margin: 0 25px 0 0;\n  -webkit-font-smoothing: antialiased;\n}\n\n.infl-fe__style__navListItem___2fSC4:first-child {\n    margin-left: 0;\n  }\n\n.infl-fe__style__currentPage___BsQJu {\n  font-size: 1.2em;\n  font-weight: 700;\n  color: #232B34;\n}\n\n.infl-fe__style__currentPage___BsQJu:visited {\n    color: #232B34;\n  }\n\n.infl-fe__style__navLink___25oGO {\n  color: #232B34;\n  font-weight: 300;\n  font-size: 1.2em;\n  letter-spacing: 0.5px;\n}\n\n.infl-fe__style__navLink___25oGO:visited {\n    color: #232B34;\n  }\n\n.infl-fe__style__navLink___25oGO:hover, .infl-fe__style__navLink___25oGO:focus, .infl-fe__style__navLink___25oGO:active {\n    color: #232B34;\n    text-decoration: none;\n  }\n\n.infl-fe__style__contentSectionItem___1sSNz {\n  padding: 7px 15px;\n}\n\n.infl-fe__style__contentSectionCaret___2uTGR {\n  margin-left: 5px;\n  color: #232B34;\n  font-size: 10px;\n  font-weight: 600;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "infl-fe__style__container___KemFm",
	"navList": "infl-fe__style__navList___A0H6H",
	"navListTopLevel": "infl-fe__style__navListTopLevel___3AiRU",
	"navListItem": "infl-fe__style__navListItem___2fSC4",
	"currentPage": "infl-fe__style__currentPage___BsQJu",
	"navLink": "infl-fe__style__navLink___25oGO",
	"contentSectionItem": "infl-fe__style__contentSectionItem___1sSNz",
	"contentSectionCaret": "infl-fe__style__contentSectionCaret___2uTGR"
};