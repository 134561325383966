import t from 'tcomb';
import Field from './field';
import CustomField from './custom-field';
import Group from './group';
import ChallengeCompleted from './challenge-completed';
import RedeemedReward from './redeemed-reward';
import BadgeEarned from './badge-earned';
import JoinCode from './join-code';
import Response from './response';

export default t.union([
  Field,
  CustomField,
  Group,
  ChallengeCompleted,
  RedeemedReward,
  BadgeEarned,
  JoinCode,
  Response
]);

export {
  Field,
  CustomField,
  Group,
  ChallengeCompleted,
  RedeemedReward,
  BadgeEarned,
  JoinCode,
  Response
};
