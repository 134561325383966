import React from 'react';
import { compose, setDisplayName } from 'recompose';
import styled from '@emotion/styled';

const ErrorWrapper = styled('div')`
  width: 100%;
  margin: auto 0;
  text-align: center;
  padding: ${props => props.theme.spacing[5]}px 0;
`;

const ErrorText = styled('div')`
  font-size: ${props => props.theme.fonts.size[1]};
  color: ${props => props.theme.colors.error[2]};
`;

export default compose(
  setDisplayName('ErrorMessage')
)(({ errorText }) => (
  <ErrorWrapper>
    {errorText &&
      <ErrorText>{errorText}</ErrorText>}
  </ErrorWrapper>
));
