import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { branch, compose, renderNothing, setDisplayName } from 'recompose';
import Flex, { Box } from '@influitive/secret-garden/lib/flex';
import Text from '@influitive/secret-garden/lib/text';
import styled from '@emotion/styled';

import ContextSwitcher from './context-switcher';
import RewriteToggleButton from './rewrite-toggle-button';

const StyledText = styled(Text)`
  line-height: 1.5;
  white-space: nowrap;
`;

const MemberViewHeader = compose(
  branch(({ inMemberView }) => !inMemberView, renderNothing),
  setDisplayName('MemberViewHeader')
)(styled(({ className, children, dataSelector, showRewriteToggleButton }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Flex className={className} justifyContent="space-between">
      <Flex alignItems="center">
        <Box mr={3}><StyledText type="bold" color="white">Member View</StyledText></Box>
        {children}
      </Flex>
      {isTablet
        ? (
          <Flex>
            {showRewriteToggleButton && <Box mb={1}><RewriteToggleButton /></Box>}
            <ContextSwitcher
              inMemberView={true}
              useIcon={true}
              dataSelector={dataSelector} />
          </Flex>
        )
        : null}
    </Flex>
  );
})`
  @media(max-width: ${props => props.theme.sizes.tablet}px) {
    width: 100%;
  }
`);

export default MemberViewHeader;
