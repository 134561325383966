import React, { useState } from 'react';
import styled from '@emotion/styled';
import * as R from 'ramda';

import Popover from '@influitive/secret-garden/lib/popover';
import defaults from '@influitive/secret-garden/lib/theme-provider/defaults';
import PopoverBody from './popover';
import { fetchBadgeProgress } from './actions';

const badgeHeight = 64;
const badgeWidth = badgeHeight;
const overlayHeight = (size, { remainingProgressPercentage }) =>
  (remainingProgressPercentage / 100) * R.or(size, badgeHeight);

const StyledPopover = styled(Popover)`
  z-index: 1501;
`;

const marginRight = defaults.spacing[3];
const StyledBadge = styled.div`
  display: inline-block;
  margin-right: ${marginRight}px;
  cursor: pointer;
  position: relative;
`;

const BadgeOverlay = styled.div(({ theme, height, size }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: `${R.or(size, theme.spacing[7])}px`,
  maxHeight: `${R.or(size, theme.spacing[7])}px`,
  background: 'white',
  opacity: .7,
  height
}));

const BadgeIcon = styled.img(({ size, theme }) => ({
  width: `${R.or(size, theme.spacing[7])}px`,
  height: `${R.or(size, theme.spacing[7])}px`
}));

export const badgeFullWidth = badgeWidth + parseInt(marginRight);

export default ({ contactUUID, badge, size, hideProgress }) => {
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [popoverBadge, setPopoverBadge] = useState(null);

  const handleClick = () => {
    setIsOpenPopover(true);
    if (popoverBadge) return;

    fetchBadgeProgress(badge.id, contactUUID).then(popoverBadge => {
      setPopoverBadge(popoverBadge);
    }).catch(() => {
      setIsOpenPopover(false);
    });
  };

  const handleClosePopover = () => {
    setIsOpenPopover(false);
  };

  return (
    <StyledPopover
      isOpen={isOpenPopover}
      preferPlace='below'
      onOuterAction={() => setIsOpenPopover(false)}
      body={
        <PopoverBody
          badge={popoverBadge}
          closePopover={handleClosePopover}
          hideProgress={hideProgress} />
      }>
      <StyledBadge className='c-badge' onClick={handleClick}>
        <BadgeIcon className='c-badge-image' src={badge.icon} size={size} />
        <BadgeOverlay className='c-badge-overlay' height={overlayHeight(size, badge)} size={size} />
      </StyledBadge>
    </StyledPopover>
  );
};
