import React from 'react';
import { branch, compose, renderNothing, setDisplayName, withHandlers, withProps, withState } from 'recompose';
import { useMediaQuery } from 'react-responsive';
import * as R from 'ramda';
import styled from '@emotion/styled';
import AsyncButton from '@influitive/secret-garden/lib/async-button';
import ButtonGroup from '@influitive/secret-garden/lib/button-group';
import Button from '@influitive/secret-garden/lib/button';
import Modal from '@influitive/secret-garden/lib/modal';
import ModalArea from '@influitive/secret-garden/lib/modal/modal-area';
import ModalHeader from '@influitive/secret-garden/lib/modal/modal-header';
import ModalTitle from '@influitive/secret-garden/lib/modal/modal-title';

import { lensStates } from '../constants';

const StyledButton = styled(({ className, isTablet, icon, text, onClick, lensActionPending }) => (
  <AsyncButton
    className={className}
    data-qa='action-button'
    onClick={onClick}
    size={getBtnSize(isTablet)}
    text={isTablet ? undefined : text}
    status={lensActionPending ? 'pending' : 'default'}
    buttonIcon={isTablet ? icon : undefined} />
))`
  margin-top: ${props => props.theme.spacing[2]}px;
  margin-left: 0;

  @media(max-width: ${props => props.theme.sizes.phone}px) {
     margin-left: ${props => props.theme.spacing[2]}px;
  }
`;

const getBtnSize = isTablet => isTablet ? 'mobile' : 'normal';

const getButtonProps = ({ loadLens, cancelLens, clearLens, lensState, toggleConfirmationModal, lensActionPending }) => {
  const clearText = lensActionPending ? 'Clearing...' : 'Clear Lens';
  const buttonProps = R.prop(lensState, {
    loading: { icon: 'cancel', text: lensActionPending ? 'Cancelling...' : 'Cancel', onClick: cancelLens },
    loaded: { icon: 'close-circle', text: clearText, onClick: clearLens },
    active: { icon: 'close-circle', text: clearText, onClick: toggleConfirmationModal },
    cleared: { icon: 'arrow-right', text: 'Load Lens', onClick: loadLens }
  });
  return buttonProps || null;
};

export default compose(
  withState('showModal', 'setShowModal', false),
  withHandlers({
    loadLens: ({ value, setClone, createLens }) => () => {
      setClone(value);
      createLens(value.uuid);
    },
    clearLens: ({ setShowModal, clearLens }) => () => {
      setShowModal(false);
      clearLens();
    },
    toggleConfirmationModal: ({ showModal, setShowModal }) => () => setShowModal(!showModal)
  }),
  withProps((props) => ({
    buttonProps: getButtonProps({ ...props }),
    isTablet: useMediaQuery({ query: '(max-width: 768px)' })
  })),
  branch(({ lensState, value }) => lensState !== lensStates.ACTIVE && R.isNil(value), renderNothing),
  setDisplayName('ActionButton')
)(({ buttonProps, isTablet, showModal, toggleConfirmationModal, clearLens, lensActionPending }) => (
  <React.Fragment>
    <Modal
      isOpen={showModal}
      onClose={toggleConfirmationModal}
      contentLabel="Confirm end lens modal"
      verticalAlign="top">
      <ModalArea variant="header" data-qa="confirm-end-lens">
        <ModalHeader closeModal={toggleConfirmationModal}>
          <ModalTitle>Clear Lens?</ModalTitle>
        </ModalHeader>
      </ModalArea>
      <ModalArea><p>You will clear this Lens and revert to standard Member View.</p></ModalArea>
      <ModalArea variant="footer">
        <ButtonGroup align="flex-end">
          <Button variant="secondary" onClick={toggleConfirmationModal} data-qa="confirm-end-lens-cancel">No, cancel</Button>
          <Button variant="primary" onClick={clearLens} data-qa="confirm-end-lens-submit">Yes, clear Lens</Button>
        </ButtonGroup>
      </ModalArea>
    </Modal>
    {buttonProps && <StyledButton
      size={getBtnSize(isTablet)}
      isTablet={isTablet}
      {...buttonProps}
      lensActionPending={lensActionPending}
    />}
  </React.Fragment>
));
