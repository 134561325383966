import React from 'react';
import ReactDOM from 'react-dom';

import FileUploader from './file-uploader';

(function() {
  let uploader = type => {
    return {
      init: (elem, props = {}) => {
        elem && ReactDOM.render((
          <FileUploader type={type} {...props} />
        ), elem);

        const newModalDialog = window.inflComp && window.inflComp.newModalDialog;

        const originalOnClose = newModalDialog.onCloseCallback();

        newModalDialog && newModalDialog.onClose(() => {
          originalOnClose && originalOnClose();

          ReactDOM.unmountComponentAtNode(elem);
        });
      }
    };
  };

  window.VideoUploader = uploader('video');
  window.DocumentUploader = uploader('file');
  window.ImageUploader = uploader('image');
  window.dispatchEvent(new Event('influitive:file-uploader-loaded'));
})();
