import React from 'react';
import { compose, setDisplayName, lifecycle } from 'recompose';
import cn from 'classnames';
import styled from '@emotion/styled';

import styles from './style.css';
import Profile from '../profile';
import Links from '../links';
import ActionCenter from '../action-center';

const HeaderContainer = styled('header')`
  margin: 0;
  width: 100%;
  position: relative;
  background-color: ${props => props.theme.colors.white};
`;

const UserNav = compose(
  lifecycle({
    componentDidMount() {
      !window._infl && (window._infl = {
        currentContact: this.props.profile
      });
    }
  }),
  setDisplayName('UserNav')
)(({ profile, features, hubSwitcher, unseenCounts, moment, unseenNotificationsCount, enabledFeatures }) => (
  <HeaderContainer className="c-header-nav-container">
    <div className={cn(styles.nav, 'c-header-nav-wrapper')}>
      <Links features={features} />
      <div className={cn(styles.actionsContainer, 'c-nav-details-container')}>
        <ActionCenter
          points={profile?.attributes.points.current}
          moment={moment}
          unseenNotificationsCount={unseenNotificationsCount}
          inboxV2Enabled={enabledFeatures.includes('inbox_v2')} />
        <Profile
          features={features}
          profile={profile}
          hubSwitcher={hubSwitcher}
          unseenCounts={unseenCounts}
          enabledFeatures={enabledFeatures} />
      </div>
    </div>
  </HeaderContainer>
));

export default UserNav;
