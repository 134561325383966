import * as R from 'ramda';
import { createAction, types } from 'redux-act';
types.clear();
import * as api from '../../api';

export const challengesRequested = createAction('challenges_requested');
export const challengeIdsReceived = createAction('challenge_ids_received');
export const challengesReceived = createAction('challenges_received');
export const challengesFetchFailed = createAction('challenges_fetch_failed');
export const featuresReceived = createAction('features_received');
export const featuresFetchFailed = createAction('features_fetch_failed');
export const fetchStatusesReset = createAction('fetch_statuses_reset');
export const orderedByReceived = createAction('ordered_by_received');

const mapArray = prop => R.reduce((acc, val) => R.assoc(val[prop], val, acc), {});

export const fetchChallenges = () => (dispatch, getState) => R.pipe(
  R.pipe(challengesRequested, dispatch),
  R.pipeP(
    api.getRecommendedChallenges,
    R.tap(R.pipe(
      R.prop('orderedBy'),
      orderedByReceived,
      dispatch
    )),
    R.tap(R.pipe(
      R.prop('challenges'),
      R.pluck('uuid'),
      challengeIdsReceived,
      dispatch
    )),
    R.tap(R.pipe(
      R.prop('challenges'),
      mapArray('uuid'),
      challengesReceived,
      dispatch
    ))
  )
)(getState()).catch(e => {
  console.error(e);
  dispatch(challengesFetchFailed());
});

export const fetchFeatures = () => dispatch => api.getFeatures()
  .then(data => dispatch(featuresReceived(data)))
  .catch(() => dispatch(featuresFetchFailed()));

export const resetFetchStatuses = () => dispatch => dispatch(fetchStatusesReset());
