exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".infl-fe__delete-members__alert___iqayv {\n  display: flex;\n  background-color: #FFE7ED;\n  padding: 10px;\n  margin-bottom: 16px;\n\n}\n\n  .infl-fe__delete-members__alert___iqayv .infl-fe__delete-members__alertIcon___1zBOV {\n    fill: #CE063B;\n  }\n\n  .infl-fe__delete-members__alert___iqayv .infl-fe__delete-members__alertText___2rzEA {\n    margin-left: 5px;\n    color: black;\n  }\n\n.infl-fe__delete-members__dataList___3KUGQ {\n  list-style: disc inside none;\n  margin-bottom: 10px;\n  padding: 0 24px;\n}\n\n.infl-fe__delete-members__examples___3VVJO {\n  margin-left: 10px;\n  margin-bottom: 20px;\n}\n\n.infl-fe__delete-members__examples___3VVJO .infl-fe__delete-members__example___mF64l {\n    max-width: 100%;\n  }\n\n.infl-fe__delete-members__examples___3VVJO .infl-fe__delete-members__exampleSmall___1rGm4{\n    height: 44px;\n    width: 401px;\n  }\n\n.infl-fe__delete-members__button___2GsPR{\n  font-weight: 600;\n}\n\n.infl-fe__delete-members__button___2GsPR{\n  font-weight: 600;\n}\n\n.infl-fe__delete-members__footer___2BqEJ {\n  display: flex;\n  justify-content: flex-end;\n  padding: 10px 0;\n  border-top: 1px solid #DADDE0;\n}\n\n.infl-fe__delete-members__footer___2BqEJ .infl-fe__delete-members__buttonRightPadding___VpYTl {\n    margin-right: 10px;\n  }\n.infl-fe__delete-members__pgraph___3eSOl{\n  padding: 0 8px;\n}\n", ""]);

// Exports
exports.locals = {
	"alert": "infl-fe__delete-members__alert___iqayv",
	"alertIcon": "infl-fe__delete-members__alertIcon___1zBOV",
	"alertText": "infl-fe__delete-members__alertText___2rzEA",
	"dataList": "infl-fe__delete-members__dataList___3KUGQ",
	"examples": "infl-fe__delete-members__examples___3VVJO",
	"example": "infl-fe__delete-members__example___mF64l",
	"exampleSmall": "infl-fe__delete-members__exampleSmall___1rGm4",
	"button": "infl-fe__delete-members__button___2GsPR",
	"footer": "infl-fe__delete-members__footer___2BqEJ",
	"buttonRightPadding": "infl-fe__delete-members__buttonRightPadding___VpYTl",
	"pgraph": "infl-fe__delete-members__pgraph___3eSOl"
};