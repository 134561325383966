import styled from '@emotion/styled';
import _Icon from '@influitive/secret-garden/lib/icon';

export const Wrapper = styled.div`
  width: 100%;
  height: 100% !important;
  position: relative;
`;

export const Placeholder = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.neutral[5]};
  display: flex;
  justify-content: center;
`;


export const Round = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Icon = styled(_Icon)`
   fill: ${props => props.theme.colors.neutral[3]};
`;

export const Dropdown = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  height: ${props => props.theme.spacing[6]}px;
  width: ${props => props.theme.spacing[6]}px;
  background-color: white;
  box-shadow: ${props => props.theme.container.shadow[2]};
`;

export const Button = styled.div`
  padding: ${props => props.theme.spacing[2]}px ${props => props.theme.spacing[3]}px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.neutral[5]};
  }

  ${props => props.danger && `
    color: ${props.theme.colors.error[0]};
  `}
`;
