import React from 'react';
import * as R from 'ramda';
import { compose, branch, renderComponent, setDisplayName, renderNothing } from 'recompose';
import { withNamespaces } from 'react-i18next';
import styled from '@emotion/styled';
import { moment } from 'modules/localization';

import Icon from '@influitive/secret-garden/lib/icon';
import ProgressBar from '@influitive/secret-garden/lib/progress-bar';
import vars from 'src/common/vars';

const progressColor = window._inflTheme?.themeColorPrimary;
const percentFor = ({ remainingProgressPercentage }) => 100 - remainingProgressPercentage;

const StyledDots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  height: 200px;
`;
const Dots = () => <StyledDots>...</StyledDots>;

const Popover = styled.div`
  min-width: 340px;
  width: min-content;
  color: ${props => props.theme.colors.neutral[1]};

  @media (max-width: ${vars.mobile}) {
    min-width: 250px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

const BadgeIcon = styled.img`
  width: ${props => props.theme.spacing[7]}px;
  height: ${props => props.theme.spacing[7]}px;
  margin-right: ${props => props.theme.spacing[3]}px;
`;

const BadgeTitle = styled.span(({ theme }) => ({
  fontSize: `${theme.fonts.size[4]}px`,
  fontWeight: theme.fonts.weight[3]
}));

const CloseIcon = styled(Icon)`
  cursor: pointer;
`;

const Description = styled.div`
  margin-top: 13px;
  margin-bottom: ${props => props.theme.spacing[3]}px;
  font-size: 15px;
`;

const Goal = styled.div`
  font-size: 13px;
`;

const isAwarded = ({ remainingProgressPercentage }) => R.equals(remainingProgressPercentage, 0);

const Footer = compose(
  branch(R.prop('hideProgress'), renderNothing),
  branch(R.pipe(R.prop('badge'), R.allPass([isAwarded, R.prop('isAutomatic')])), renderNothing),
  withNamespaces(),
  setDisplayName('BadgePopoverFooter')
)(({ badge, t }) =>
  R.and(isAwarded(badge), R.not(badge.isAutomatic))
    ? <div>
      {t('This badge was awarded to you by an admin {{date}}', { date: moment(badge.earnedAt).fromNow() })}
    </div>
    : <React.Fragment>
      <div>
        <ProgressBar percent={percentFor(badge)} color={progressColor} />
      </div>

      <Goal dangerouslySetInnerHTML={{ __html: badge.goal }} />
    </React.Fragment>
);

export default compose(
  branch(R.pipe(R.prop('badge'), R.isNil), renderComponent(Dots)),
  setDisplayName('BadgePopover')
)(({ badge, closePopover, hideProgress }) =>
  <Popover>
    <HeaderWrapper>
      <HeaderContent>
        <BadgeIcon src={badge.icon} />
        <BadgeTitle>{badge.name}</BadgeTitle>
      </HeaderContent>
      <span onClick={() => closePopover()}>
        <CloseIcon icon='close' width='18px' />
      </span>
    </HeaderWrapper>

    <Description>
      {badge.description}
    </Description>
    <Footer hideProgress={hideProgress} badge={badge} />
  </Popover>
);
