import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducer';
import middleware from './middleware';

const configureStore = () => {
  let composeEnhancers = compose;

  if (process.env.NODE_ENV !== 'production') {
    composeEnhancers = composeWithDevTools({ name: 'CHALLENGES-CAROUSEL' });
  }

  return createStore(
    reducer,
    composeEnhancers(
      applyMiddleware(...middleware)
    )
  );
};

const store = configureStore();

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept('./reducer', () => {
      store.replaceReducer(require('./reducer').default);
    });
  }
}

export default store;
