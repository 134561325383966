import React from 'react';
import styled from '@emotion/styled';
import debounce from 'lodash.debounce';
import Avatar from '@influitive/secret-garden/lib/avatar';
import { Icon } from 'infl-icons';
import { withNamespaces } from 'react-i18next';
import cn from 'classnames';

import avatarPng from '../../assets/default_image.png';
import Popover from '../popover';
import Options from './options';
import { qaSel, sumUnseenCounts } from '../../common/utils';
import styles from './style.css';
import { UnreadMarker } from '../unread-marker';
import EditProfileModal from '../edit-profile';

const CardContainer = styled('div')`
  display: flex;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.colors.neutral[4]};
  text-decoration: none;
`;

const NameLink = styled('a')`
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  line-height: 28px;
  color: ${props => props.theme.colors.neutral[0]};
  white-space: normal;
  &:visited {
    color: ${props => props.theme.colors.neutral[0]};
  }
  &:hover, &:focus, &:active {
    color: ${props => props.theme.colors.primary[0]};
    text-decoration: none;
  }
  &:after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

const IconContainer = styled('span')`
  vertical-align: text-top;
  margin-right: 3px;
  margin-top: -4px;
`;

const StyledUnreadMarker = styled(UnreadMarker)`
  top: 0;
  right: -4px;
`;

const AvatarContainer = styled('div')`
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

const ProfileLinkContainer = styled('div')`
  margin: 8px 0;
  padding: 8px 30px 8px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;

  &:hover {
    transition: all .2s ease;
    background-color: ${props => props.theme.colors.neutral[5]};
  }
`;

const ProfileCard = withNamespaces()(({ profile, t }) => (
  <CardContainer data-qa={qaSel('profile-popover')} className="c-profile-dropdown-header">
    <ProfileLinkContainer>
      <Avatar
        size="medium"
        url={profile?.links.avatar || avatarPng}
        alt={profile ? t('{{name}} profile image', { name: profile.attributes.name }) : ''} />
      <div className={cn(styles.nameLink, 'c-name-and-points-container')}>
        <NameLink
          data-qa={qaSel('profile-popover-name-card')}
          href={`/profiles/${profile?.id}`}
          aria-label={t('Access your profile')}
          data-fs="fs-exclude"
          className="c-name">
          {profile?.attributes.name}
        </NameLink>
        <div className="c-points-wrapper">
          <IconContainer className="c-icon-wrapper"><Icon icon="coins" /></IconContainer>
          <span className="c-points">{profile?.attributes.points.current.toLocaleString()}</span>
        </div>
      </div>
    </ProfileLinkContainer>
  </CardContainer>
));

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      overlayPositioning: { leftOffset: -75, arrowOffset: 80 },
      openProfileModal: false
    };
    this.debouncedWindowResize = debounce(this.handleWindowResize, 250);
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.debouncedWindowResize);
    this.handleWindowResize();
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.debouncedWindowResize);
  };

  handleWindowResize = () => {
    window.innerWidth >= 1125
      ? this.state.overlayPositioning.leftOffset !== -75 &&
      this.setState({
        overlayPositioning: { leftOffset: -75, arrowOffset: 80 }
      })
      : this.state.overlayPositioning.leftOffset !== -15 &&
      this.setState({
        overlayPositioning: { leftOffset: -15, arrowOffset: 20 }
      });
  };

  handleClick = (e) => {
    e.preventDefault();
    this.setState({ popoverOpen: !this.state.popoverOpen });
  };

  handleClickOutside = () => {
    this.setState({ popoverOpen: false });
  };

  handleEditProfileModalOpen = openModal => () => {
    this.setState({ openProfileModal: openModal });
  };

  render() {
    const { features, profile, hubSwitcher, unseenCounts, enabledFeatures } = this.props;
    return (
      <div className={cn(styles.container, 'c-profile-details-container')} aria-label="Profile popover">
        {this.state.openProfileModal && <EditProfileModal onModalClose={this.handleEditProfileModalOpen(false)} />}
        <AvatarContainer
          data-qa={qaSel('profile')}
          onClick={this.handleClick}
          aria-haspopup="true"
          aria-expanded={this.state.popoverOpen}
          className="c-profile-details"
          ref={node => this.profileAvatar = node}>
          <Avatar
            size={'small'}
            url={profile?.links.avatar || avatarPng}
            alt={`${profile?.attributes.name} Avatar`} />
          <StyledUnreadMarker show={sumUnseenCounts(unseenCounts) > 0} />
        </AvatarContainer>
        <Popover
          target={this.profileAvatar}
          show={this.state.popoverOpen}
          onHide={this.handleClickOutside}
          {...this.state.overlayPositioning}>
          <ProfileCard profile={profile} />
          <Options
            features={features}
            enabledFeatures={enabledFeatures}
            userId={profile?.id}
            hubSwitcher={hubSwitcher}
            unseenCounts={unseenCounts}
            onProfileModalOpen={this.handleEditProfileModalOpen(true)} />
        </Popover>
      </div>
    );
  }
}

export default Profile;
