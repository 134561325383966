import React from 'react';
import * as R from 'ramda';
import { compose, setDisplayName, withHandlers, withState, lifecycle } from 'recompose';
import styled from '@emotion/styled';
import ThemeProvider from '@influitive/secret-garden/lib/theme-provider';
import defaults from '@influitive/secret-garden/lib/theme-provider/defaults';
import { customBranding } from '../../api';
import ChallengesHeadingLink from '../challenges-heading-link';
import Challenges from '../challenges';
import { corporatePage } from '../../../../src/common/utils';

/* eslint-disable complexity */
const customBrandingTheme = customBranding => (customBranding && customBranding.color_scheme) ? {
  colors: {
    primary: [ R.path(['color_scheme', 'primary_color'], customBranding) ],
    secondary: [ R.path(['color_scheme', 'secondary_color'], customBranding) ]
  },
  fonts: {
    family: customBranding.custom_font_enabled
      ? R.insert(0, customBranding.custom_font_name, defaults.fonts.family)
      : defaults.fonts.family,
    custom: customBranding.custom_font_enabled ? {
      fontName: customBranding.custom_font_name,
      fontUrl: customBranding.custom_font_url
    } : null
  }
} : {};
/* eslint-enable complexity */

const App = styled('div')`
  display: ${props => props.isVisible ? 'block' : 'none'};
  ${props => props.theme.fonts.custom && `
    @font-face {
      font-family: ${props.theme.fonts.family.join(', ')};
      font-style: normal;
      src: url(${props.theme.fonts.custom.fontUrl});
    }
  `}
  font-family: ${props => props.theme.fonts.family.join(', ')} !important;
`;

const checkChallengePlayer = () => (
  window &&
  window.inflComp &&
  window.inflComp.ChallengeModalView &&
  typeof window.inflComp.ChallengeModalView === 'function'
);

export default compose(
  withState('customBranding', 'brandingReceived', null),
  withState('challengePlayer', 'setChallengePlayer', null),
  withState('isVisible', 'setVisibility', true),
  withHandlers({
    handleChallengeOpen: ({ challengePlayer }) => challenge => {
      challengePlayer
        ? challengePlayer.open(challenge)
        : (window.location = `/challenges/${challenge.id}`);
    },
    handleNoChallenges: ({ setVisibility }) => () => {
      setVisibility(false);
    },
    handlePageChange: ({ setVisibility }) => () => {
      corporatePage() && setVisibility(false);
    },
    handleTopicPageChange: ({ setVisibility }) => () => {
      window.location.pathname.includes('/t/') ? setVisibility(false) : setVisibility(true);
    },
    handleCategoryPageChange: ({ setVisibility }) => e => {
      e.detail.isContentSection === true
        ? setVisibility(false)
        : setVisibility(true);
    }
  }),
  lifecycle({
    componentDidMount() {
      corporatePage() && this.props.setVisibility(false);
      customBranding().then(this.props.brandingReceived);
      checkChallengePlayer() && this.props.setChallengePlayer(window.inflComp.ChallengeModalView());
      document.addEventListener('discourse-page-change', this.props.handlePageChange);
      document.addEventListener('discourse-topic-page-change', this.props.handleTopicPageChange);
      document.addEventListener('discourse-parent-category-page-change', this.props.handleCategoryPageChange);
    }
  }),
  setDisplayName('ChallengesCarousel')
)(({ customBranding, handleChallengeOpen, handleNoChallenges, isVisible }) => (
  <ThemeProvider theme={customBrandingTheme(customBranding)}>
    <App isVisible={isVisible} data-qa="CHALLENGES-CAROUSEL_app">
      <ChallengesHeadingLink />
      <Challenges
        onNoChallenges={handleNoChallenges}
        onChallengeOpen={handleChallengeOpen} />
    </App>
  </ThemeProvider>
));
