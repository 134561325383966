import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import App from './components/app';
import { I18nextProvider } from 'react-i18next';
import i18n from 'modules/localization';

const load = () => document.getElementById('infl-fe-challenges-carousel') && render(
  <AppContainer>
    <Provider store={store}>
      <I18nextProvider i18n={i18n({ defaultNs: 'ChallengesCarousel', ns: 'ChallengesCarousel' })}>
        <App />
      </I18nextProvider>
    </Provider>
  </AppContainer>,
  document.getElementById('infl-fe-challenges-carousel')
);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept('./components/app', load);
  }
}

window.inflLoader = window.inflLoader || {};
window.inflLoader = { ...window.inflLoader, loadChallengesCarousel: load };

load();
