import * as R from 'ramda';
import { nanoid } from 'nanoid';
import { pushData, pushBatch } from './api';

const VIEW_EVENT = 'view';
const CLICK_EVENT = 'click';

const commonParameters = {
  referrer: window.document.referrer,
  os: window.navigator.platform
};

const getTrackerName = (category, name) => `${category}-${name}`;
const activeTrackers = {};

window.pageEventsTracker = {
  startActivity: ({ category, name, ...data }) => {
    const trackerName = getTrackerName(category, name);

    if (activeTrackers[trackerName]) return;

    const trackerId = nanoid();

    const trackingData = {
      trackerId,
      eventType: VIEW_EVENT,
      parameters: {
        category,
        name,
        ...commonParameters,
        ...data
      }
    };

    pushData(trackingData);

    activeTrackers[trackerName] = trackingData;
  },

  stopActivity: ({ category, name }) => {
    const trackerName = getTrackerName(category, name);

    const trackingData = activeTrackers[trackerName] || {};

    if (R.not(R.isEmpty(trackingData))) {
      pushData(trackingData);
      delete activeTrackers[trackerName];
    }
  },

  trackClick: ({ elementName, category, pageName }) => {
    pushData({
      eventType: CLICK_EVENT,
      trackerId: nanoid(),
      parameters: {
        name: elementName,
        category,
        pageName,
        ...commonParameters
      }
    });
  }
};

window.document.addEventListener('visibilitychange', function logData() {
  if (document.visibilityState === 'hidden') {
    if (R.not(R.isEmpty(activeTrackers))) {
      pushBatch(R.values(activeTrackers));
    }
  }
});

window.$('body').on('click', '[data-custom-tracking]', function() {
  const element = window.$(this);

  window.pageEventsTracker.trackClick({
    elementName: element.data('custom-tracking'),
    category: element.data('custom-tracking-category'),
    pageName: element.data('custom-tracking-page-name')
  });
});
