import * as R from 'ramda';

export const windowLang = () => R.path(['hub', 'lang'], windowObject);

export let windowObject = window._infl;

export const setWindowObject = newWindowObject => {
  windowObject = newWindowObject;
};

export const qaSel = sel => `ADVOCATE-NAV-FE_${sel}`;
