import * as R from 'ramda';
import tracking from 'modules/tracking';

tracking.init('replace_with_tracking_token');

const nameSpace = R.concat('C2Homepage::');

export const track = (eventName, props) => tracking.track(eventName, props);

export const trackC2Event = eventName => track(nameSpace(eventName));

const trackMap = {
  challenges: 'Advocate_Navbar_Challenges',
  discussions: 'Advocate_Navbar_Discussion',
  referrals: 'Advocate_Navbar_Referrals',
  rewards: 'Advocate_Navbar_Rewards',
  home: 'Advocate_Navbar_Home'
};

export const trackClick = type => () => type && trackMap[type] && trackC2Event(trackMap[type]);
