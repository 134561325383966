import { isCurrPage } from './utils';

export const isContentSection = link => link.type === 'content_section';

const contentSectionsSlugs = features => features?.filter(isContentSection).map(contentSection => contentSection.path);

/*
  Contrary to the other tabs, content section tabs path are dynamic and their content does not live in the hub.
  So theres no native way for the navbar to know what tab section is currently selected.
  Our approach to solve this is to listen to an event fired from discourse (e.g "discourse-page-change")
  that contains the slug of the category the contact is in and then cross reference it with the tabs.
*/
/* eslint-disable no-undef */
export const onDiscoursePageChange = (e, features, baseSlug = '/forum/session/sso?return_path=/forum/c/') => {
  /*
    There are 2 cases:
    Logged in Pages: This is the default case and the base slug has /forum/session/sso since
    we need to make sure that the advocates is logged in
    Public Pages: The base slug is simply /forum/c/ since we just need to redirect to the correct
    page.
  */
  if (!e) return {};
  let slug = baseSlug + e.detail.slug;
  const contentSectionSlugs = contentSectionsSlugs(features);

  if (contentSectionSlugs?.includes(slug)) return { [slug]: true };

  return {};
};

/*
  isCurrPageWithOverrides()
  is used in the case when a user is navigating to any discourse page

  currentPageOverride - a boolean to override the functionality of the 'isCurrPage'
  We currently use it to determine what content type tab is selected.

  anyPageOverriden - to 'unselect' any tabs. So if another tab is selected
  via currPageOverride, we want to also ignore the effect of 'isCurrpage'.
  The latter is important for the case of the "Home" tab which
  should not be selected when navigating to a content section.
*/
export const isCurrPageWithOverrides = (currentPageOverride, anyPageOverriden, type) =>
  currentPageOverride || (!anyPageOverriden && isCurrPage(type));
