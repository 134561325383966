import t from 'tcomb';
import { StringInterface, NumberInterface, StringArrayInterface, MsAgoInterface, ImageInterface } from '../value-interfaces';

const FieldBase = t.interface({
  field: t.String
});

const extensions = [
  StringInterface,
  NumberInterface,
  StringArrayInterface,
  MsAgoInterface,
  ImageInterface
];

const interfaces = extensions.map(ext => FieldBase.extend(ext, { strict: true }));

const Field = t.union(interfaces, 'Field');
export default Field;
