import React from 'react';
import { Overlay } from 'react-overlays';
import { compose, defaultProps, lifecycle, setDisplayName, withHandlers } from 'recompose';
import styled from '@emotion/styled';
import { Box } from '@rebass/grid/emotion';
import cn from 'classnames';

import styles from './style.css';
import constants from '../../common/constants';

const PopoverContent = styled(Box)`
  position: relative;
  min-width: ${props => props.minWidth ? props.minWidth : '226px'};
  background-color: ${props => props.theme.colors.white};
  border-radius: 5px;
  box-shadow: ${props => props.theme.container.shadow[2]};
  font-family: ${props => props.theme.fonts.custom ? props.theme.fonts.custom.fontName : constants.defaultFont} !important;
`;

const Svg = styled(({ className }) => (
  <svg className={className}>
    <line x1="0" y1="12" x2="13" y2="0" style={{ stroke: 'rgba(200, 200, 200, 0.1)', strokeWidth: 1 }} />
    <line x1="13" y1="0" x2="26" y2="12" style={{ stroke: 'rgba(200, 200, 200, 0.1)', strokeWidth: 1 }} />
    <Polygon className="c-polygon"/>
  </svg>
))`
  height: 13px;
  width: 26px;
`;

const Polygon = styled(({ className }) => (
  <polygon points="13,1 25,12 1,12" className={className} />
))`
  fill: white;
`;

const Popover = compose(
  lifecycle({
    componentDidMount() {
      document.querySelector('.c-popover-content').childNodes[0].childNodes[0].focus();
    }
  }),
  withHandlers({
    keyDownHandler: ({ setDropDownIsOpen }) => e => {
      if (e.which === 27) {
        setDropDownIsOpen(false);
        document.querySelector('.c-more-text').focus();
      }
    }
  })
)(({
  children,
  style,
  arrowOffsetLeft,
  arrowOffsetTop,
  leftOffset,
  arrowOffset,
  noArrow,
  minWidth,
  keyDownHandler
}) => (
  <div
    className={cn(styles.popoverWrapper, 'c-popover-wrapper')}
    style={{ left: style.left + leftOffset, top: style.top }}
    onKeyDown={keyDownHandler}>
    <PopoverContent minWidth={minWidth} className="c-popover-content">
      {children}
      {!noArrow && <div
        className={cn(styles.arrow, 'c-popover-arrow')}
        style={{ top: arrowOffsetTop, left: arrowOffsetLeft, marginLeft: arrowOffset }}>
        <Svg className="c-arrow"/>
      </div>}
    </PopoverContent>
  </div>
));

export default compose(
  defaultProps({
    leftOffset: 0,
    placement: 'bottom',
    noArrow: false,
    onHide: () => {}
  }),
  setDisplayName('Popover')
)(({
  children,
  leftOffset,
  arrowOffset,
  target,
  show,
  placement,
  noArrow,
  onHide,
  minWidth,
  setDropDownIsOpen
}) => (
  <Overlay
    target={target}
    show={show}
    placement={placement}
    shouldUpdatePosition={true}
    rootClose={true}
    onHide={onHide}>
    <Popover
      children={children}
      leftOffset={leftOffset}
      arrowOffset={arrowOffset}
      noArrow={noArrow}
      minWidth={minWidth}
      setDropDownIsOpen={setDropDownIsOpen} />
  </Overlay>
));
