import React from 'react';
import * as R from 'ramda';
import tinyColor from 'tinycolor2';
import ThemeProvider from '@influitive/secret-garden/lib/theme-provider';

const getColorShades = color => {
  return [
    color,
    tinyColor(color).darken(20).toString(),
    tinyColor(color).lighten(10).toString(),
    tinyColor(color).lighten(25).toString()
  ];
};

const customBrandingTheme = customBranding => (customBranding && customBranding.color_scheme) ? {
  colors: {
    primary: getColorShades(R.path(['color_scheme', 'primary_color'], customBranding)),
    secondary: [ R.path(['color_scheme', 'secondary_color'], customBranding) ],
    bannerBackground: [ R.path(['color_scheme', 'banner_background'], customBranding) ]
  },
  fonts: {
    custom: customBranding.custom_font_enabled ? {
      fontName: customBranding.custom_font_name,
      fontUrl: customBranding.custom_font_url
    } : null
  }
} : {};

export default ({ customBranding, children }) => <ThemeProvider theme={customBrandingTheme(customBranding)}>
  {children}
</ThemeProvider>;
