import React from 'react';
import * as R from 'ramda';
import Avatar from '@influitive/secret-garden/lib/avatar';
import { branch, compose, renderNothing, setDisplayName } from 'recompose';
import cn from 'classnames';

import avatarPng from '../../assets/default_image.png';
import { qaSel, logoAlt } from '../../common/utils';
import { track } from '../../common/tracking-utils';
import Points from '../points';
import styles from './style.css';
import { withNamespaces } from 'react-i18next';

const trackClick = type => () => track(type);

const CoinIcon = compose(
  branch(({ enabledFeatures }) =>
    R.contains('mobile_hide_rewards_join_code', enabledFeatures || []), renderNothing
  ),
  setDisplayName('CoinIcon')
)(({ profile }) => (
  <div className={cn(styles.pointsContainer, 'c-points-container')}>
    <Points value={profile?.attributes.points.current} onClick={trackClick('mNav_rewards')} />
  </div>
));

/* eslint complexity: 0 */
const MobileHeader = ({ profile, customBranding, enabledFeatures, t }) => (
  <div data-qa={qaSel('mobile-nav')} className={cn(styles.mobileHeader, 'c-mobile-header')}>
    <div className={cn(
      R.contains('mobile_hide_rewards_join_code', enabledFeatures || []) ? styles.avatarNoCoinIcon : styles.avatar,
      'c-profile-details-container '
    )}>
      <a
        data-qa={qaSel('mobile-nav-profile-avatar')}
        className={cn(styles.profileLink, 'c-profile-details')}
        href={`/profiles/${profile?.id}`}
        aria-label={t('Access your profile')}>
        <Avatar
          size={'small'}
          url={profile?.links.avatar || avatarPng}
          alt={profile ? t('{{name}} Avatar', { name: profile.attributes.name }) : ''} />
      </a>
    </div>
    {customBranding['mobile_logo'] && (
      <div className={cn(styles.logoContainer, 'c-logo-container')}>
        <a href="/" data-qa={qaSel('mobile-nav-branding-logo')}>
          <img
            className={cn(styles.logo, 'c-logo-img')}
            src={customBranding['mobile_logo']}
            alt={logoAlt(profile)} />
        </a>
      </div>
    )}
    <CoinIcon profile={profile} enabledFeatures={enabledFeatures} />
  </div>
);

export default withNamespaces()(MobileHeader);
