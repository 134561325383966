import React from 'react';
import * as R from 'ramda';
import { compose, setDisplayName, withProps } from 'recompose';
import styled from '@emotion/styled';
import { Box } from '@rebass/grid/emotion';
import cn from 'classnames';
import { logoAlt, qaSel, sanitizeHTML } from '../../common/utils';
import styles from './styles.css';
import('./custom-banner-override.css');

const CompanyBanner = styled(Box)`
  position: relative;
  ${props => props.theme.colors.bannerBackground && `background-color: ${props.theme.colors.bannerBackground[0]} !important`};
`;

const defaultHubStyles = '#logo-bar { position: relative; width: 100%; max-width: 1024px; padding: 10px 0; margin: 0 auto; height: 60px; }\n';

const needsDefaultPadding = (divId, bannerCss) => divId === 'top-header' || (divId === 'custom-banner' && bannerCss === '');

const customBranding = R.pipe(
  ({ enabledFeatures, customBranding, profile }) => ({
    divId: R.ifElse(
      R.contains('additional_advanced_branding'),
      R.always('custom-banner'),
      R.always('top-header')
    )(enabledFeatures || []),
    customHtml: R.pipe(
      R.defaultTo(''),
      R.replace('{{logo}}', `<a id="logo" href="/" data-qa="${qaSel('company-banner-logo')}"><img src=${customBranding.logo} alt=${logoAlt(profile)} /></a>`),
      sanitizeHTML
    )(customBranding.banner_html),
    customCss: R.pipe(
      R.defaultTo(''),
      R.replace(/\u003Cstyle\u003E|\u003C\/style\u003E/g, ''),
      R.concat(defaultHubStyles)
    )(customBranding.banner_css)
  })
);

const Banner = compose(
  withProps(customBranding),
  setDisplayName('Banner')
)((({ divId, customHtml, customCss }) => (
  <CompanyBanner className='c-company-banner-container'>
    <style dangerouslySetInnerHTML={{ __html: customCss }}/>
    <div id={divId}
      data-qa={qaSel('custom-banner')}
      className={cn(
        styles.customBanner,
        needsDefaultPadding(divId, customCss) && styles.defaultPadding,
        'c-company-banner'
      )}
      dangerouslySetInnerHTML={{ __html: customHtml }} />
  </CompanyBanner>
)));

export default Banner;
