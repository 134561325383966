import React from 'react';
import { Icon } from 'infl-icons';
import { compose, withState, lifecycle, withHandlers, withProps } from 'recompose';
import cn from 'classnames';
import { StreamChat } from 'stream-chat';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Flex from '@influitive/secret-garden/lib/flex';

import vars from 'modules/vars';
import { permissions, connectUserToStream } from 'modules/admin-nav/common/api';
import { useHandleConnection } from '../../../admin-nav/common/util';
import { isLensActive } from 'modules/lenses/api';
import { messageCount } from '../../common/api';
import { isAdmin } from 'modules/advocate-nav/common/from-hub';
import { qaSel } from '../../common/utils';
import { trackC2Event } from '../../common/tracking-utils';
import NotificationOverlay from '../notification-overlay';
import Tooltip from '../disabled-tooltip';
import Points from '../points';
import { UnreadMarker } from '../unread-marker';
import styles from './style.css';

const chatClient = StreamChat.getInstance(process.env.STREAM_IO_API_KEY);

const overlayAdjustments = { leftOffset: -142, arrowOffset: 148 };

const trackClick = type => () => trackC2Event(type);

const inboxMessageEvent = 'influitive:inbox-messages-count';

const disabledLink = inActiveLens => inActiveLens ? styles.disabledLink : '';
const hiddenLink = permissions => isAdmin && !permissions.includes('member_interactions_access') ? styles.hidden : '';
const chatLink = (inboxV2Enabled, inActiveLens) => inActiveLens ? '#' : inboxV2Enabled ? '/chat' : '/inbox';

const UnreadBadge = styled(({ className, show, ...props }) => (
  show && <Flex className={className} justifyContent='center' alignItems='center' px={1} {...props} />
))(({ theme }) => css`
  border-radius: 10px;
  background: ${theme.colors.primary[0]};
  color: ${vars['white']};
  min-width: 16px;
  height: 16px;
  font-size: ${theme.fonts.size[1]}px;
  position: absolute;
  top: -1px;
  left: 12px;
  font-weight: 500;
`);

const ActionCenter = compose(
  withState('unreadCount', 'setUnreadCount', 0),
  withState('notificationsSeen', 'setNotificationsSeen', false),
  withState('inActiveLens', 'setInActiveLens', false),
  withState('permissions', 'permissionsReceived', []),
  withState('initiallyConnected', 'setInitiallyConnected', false),
  withHandlers({
    getUnreadCount: ({ setUnreadCount, inboxV2Enabled, setInitiallyConnected }) => () => {
      if (inboxV2Enabled) {
        connectUserToStream().then(response => {
          response?.me && setUnreadCount(response.me.unread_channels);
          setInitiallyConnected(true);
        });
      }
      else {
        messageCount().then(count => {
          setUnreadCount(count.data.attributes['unread-conversation-count']);
        });
      }
    }
  }),
  withHandlers(({ inboxV2Enabled, getUnreadCount, setUnreadCount }) => {
    const events = ['notification.message_new', 'notification.mark_read', 'message.new'];
    const handleMessageEvent = event =>
      event.unread_channels !== undefined && setUnreadCount(event.unread_channels);

    const subscribeToStream = () => events.map(event => chatClient.on(event, handleMessageEvent));
    const unsubscribeToStream = () => events.map(event => chatClient.off(event, handleMessageEvent));

    return {
      subscribeToNewMessages: () => () =>
        inboxV2Enabled ? subscribeToStream() : window.document.addEventListener(inboxMessageEvent, getUnreadCount),
      unsubscribeToNewMessages: () => () =>
        inboxV2Enabled ? unsubscribeToStream() : window.document.removeEventListener(inboxMessageEvent, getUnreadCount)
    };
  }),
  withProps(({ initiallyConnected }) => ({
    shouldSkipHandleConnection: !chatClient.wsConnection || !initiallyConnected
  })),
  lifecycle({
    componentDidMount() {
      this.props.getUnreadCount();
      isLensActive().then(this.props.setInActiveLens);
      permissions().then(this.props.permissionsReceived);

      this.props.subscribeToNewMessages();
    },
    componentWillUnmount() {
      this.props.unsubscribeToNewMessages();
      chatClient.closeConnection();
    }
  }),
)(({
  points,
  unreadCount,
  notificationsSeen,
  setNotificationsSeen,
  moment,
  unseenNotificationsCount,
  inActiveLens,
  permissions,
  inboxV2Enabled,
  setUnreadCount,
  shouldSkipHandleConnection
}) => {
  useHandleConnection({
    shouldSkip: shouldSkipHandleConnection,
    setUnreadCount
  });

  return (
    <div data-qa={qaSel('action-center')} className={cn(styles.container, 'c-action-center')}>
      <NotificationOverlay
        moment={moment}
        overlayAdjustments={overlayAdjustments}
        toggleSeen={() => setNotificationsSeen(true)}>
        {!notificationsSeen && <UnreadMarker show={unseenNotificationsCount > 0} />}
        <div className={cn(styles.iconStyled, 'c-icon-wrapper')}><Icon icon="bell" /></div>
      </NotificationOverlay>
      <a
        data-tip
        data-for='disabled-inbox'
        data-qa={qaSel('link-messages')}
        className={cn(styles.iconLink, disabledLink(inActiveLens), 'c-link c-message', hiddenLink(permissions))}
        href={chatLink(inboxV2Enabled, inActiveLens)}
        aria-label="Access your messages">
        {inboxV2Enabled
          ? <UnreadBadge show={unreadCount > 0}>{unreadCount}</UnreadBadge>
          : <UnreadMarker show={unreadCount > 0} />
        }
        <div className={cn(styles.iconStyled, 'c-icon-wrapper')}><Icon icon="mail" /></div>
      </a>
      {inActiveLens && <Tooltip id='disabled-inbox' />}
      <Points value={points} onClick={trackClick('Advocate_Navbar_Rewards')} />
    </div>
  ); });

export default ActionCenter;
