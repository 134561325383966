import React from 'react';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
import { compose, lifecycle, setDisplayName, withHandlers, withProps, withState } from 'recompose';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import Flex from '@influitive/secret-garden/lib/flex';

import LensLoader from 'modules/lenses/lens-loader';
import LensTitle from 'modules/lenses/lens-loader/lens-title';
import NotificationBar from 'modules/lenses/notification-bar';
import MemberViewHeader from './member-view-header';
import ContextSwitcher from './context-switcher';
import { activateLens, cancelLens, clearLens, createLens, subscribeToLens, refreshLens } from '../lenses/api';
import { lensStates } from '../lenses/constants';
import RewriteToggleButton from './rewrite-toggle-button';
import { enabledFeatures, permissions } from './api';

const goToRootWindowLocation = () => window.location.href = '/';

const Divider = styled('div')`
  height: 51px;
  border-left: 1px;
  border-color: ${p => p.theme.colors.white};
  border-left-style: solid;
  margin-top: -${p => p.theme.spacing[2]}px;
  margin-bottom: -${p => p.theme.spacing[2]}px;
  margin-left: calc(${p => p.theme.spacing[2]}px + ${p => p.theme.spacing[1]}px);
`;

/* eslint-disable complexity */
const SwitcherWithToggle = ({ showRewriteToggleButton, showContextSwitcher, ...props }) =>
  <Flex alignItems="center">
    {showRewriteToggleButton && !props.isTablet && <>
      <RewriteToggleButton />
      {showContextSwitcher && <Divider />}
    </>}
    {showContextSwitcher && <ContextSwitcher
      {...props}
      useIcon={props.isTablet}/>}
  </Flex>;
/* eslint-enable complexity */

export default compose(
  withState('lensState', 'setLensState', props => props.lens?.state),
  withState('lens', 'lensReceived', null),
  withState('clone', 'setClone', {}),
  withState('lensActionPending', 'setLensActionPending', false),
  withState('hasLensPermission', 'setLensPermission', false),
  withState('showRewriteToggle', 'setShowRewriteToggle', false),
  withHandlers({
    createLens: ({ setLensState }) => (uuid) => {
      setLensState(lensStates.LOADING);
      createLens(uuid).catch(() => setLensState(lensStates.CREATE_ERROR));
    },
    refreshLens: ({ setLensState }) => () => {
      refreshLens().then(() => {
        setLensState(lensStates.LOADING);
        goToRootWindowLocation();
      }).catch(() => setLensState(lensStates.REFRESH_ERROR));
    },
    clearLens: ({ setLensState, setLensActionPending }) => () => {
      setLensActionPending(true);
      clearLens().then(goToRootWindowLocation).catch(() => {
        setLensState(lensStates.CLEAR_ERROR);
        setLensActionPending(false);
      });
    },
    activateLens: ({ setLensState }) => () => {
      activateLens().then(goToRootWindowLocation).catch(() => (
        setLensState(lensStates.ACTIVE_ERROR)));
    },
    cancelLens: ({ setLensState, setLensActionPending }) => () => {
      setLensActionPending(true);
      cancelLens().then(() => {
        setLensState(lensStates.CLEARED);
        setLensActionPending(false);
      }).catch(() => {
        setLensState(lensStates.CANCEL_ERROR);
        setLensActionPending(false);
      });
    },
    setLensToLoaded: ({ setLensState, lensState }) => () => (
      lensState === lensStates.LOADING && setLensState(lensStates.LOADED)
    )
  }),
  withHandlers({
    handleLensSubscribe: ({ setLensToLoaded, lensReceived }) => () =>
      subscribeToLens({
        onLensReceived: lens => lensReceived(lens),
        lensNotificationHandler: setLensToLoaded
      })
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.lens?.state !== this.props.lens?.state && this.props.lens?.state) {
        this.props.setLensState(this.props.lens.state);
      }
    },
    componentDidMount() {
      enabledFeatures().then(features => {
        if (features.includes('role_management_restructure')) {
          permissions().then(permissions => {
            if (permissions.includes('view_lens')) {
              this.props.setLensPermission(true);
              this.props.handleLensSubscribe();
            }
          });
        }
        else {
          this.props.setLensPermission(true);
          this.props.handleLensSubscribe();
        }

        this.props.setShowRewriteToggle(features.includes('rewrite_toggle_button'));
      });
    }
  }),
  withTheme,
  withProps(({ theme }) => ({
    isTablet: useMediaQuery({ query: `(max-width: ${theme.sizes.tablet}px)` }),
    isDesktop: useMediaQuery({ query: `(max-width: ${theme.sizes.desktop}px)` })
  })),
  withProps(({ isDesktop, inMemberView, isTablet }) => ({
    wrapContent: isDesktop ? 'wrap' : '',
    justifySwitcher: inMemberView ? 'space-between' : 'flex-end',
    showContextSwitcher: !inMemberView || !isTablet
  })),
  withProps(({ inMemberView, showRewriteToggle }) => ({
    showRewriteToggleButton: inMemberView && showRewriteToggle
  })),
  setDisplayName('AdminBar'),
)(styled(({ className, hasLensPermission, hasCorporateAccessPermission,
  wrapContent, justifySwitcher, showContextSwitcher, showRewriteToggleButton, ...props
}) => (
  <React.Fragment>
    <Flex
      className={cn(className, 'c-admin-bar')}
      data-qa={`${props.dataSelector}_admin-bar`}
      justifyContent={justifySwitcher}
      alignItems="center">
      {hasLensPermission ?
        <Flex alignItems="center" flex="1 1 auto" flexWrap={wrapContent}>
          <MemberViewHeader {...props} showRewriteToggleButton={showRewriteToggleButton}>
            <LensTitle lensState={props.lensState} />
          </MemberViewHeader>
          <LensLoader canHaveProfileLinks={hasCorporateAccessPermission} {...props} />
        </Flex> :
        <MemberViewHeader {...props} showRewriteToggleButton={showRewriteToggleButton} />
      }
      <SwitcherWithToggle
        showRewriteToggleButton={showRewriteToggleButton}
        showContextSwitcher={showContextSwitcher}
        {...props} />
    </Flex>
    {hasLensPermission && <NotificationBar {...props} />}
  </React.Fragment>
))`
  background: ${props => props.theme.colors.neutral[1]};
  padding: ${props => `${props.inMemberView ? props.theme.spacing[2] : props.theme.spacing[1]}px ${props.theme.spacing[3]}px`};
  z-index: 1001; /* has to be overtop mobile sticky nav and lens notification bar */
  position: relative;

  @media (max-width: 768px) {
    padding: ${props => `${props.theme.spacing[2]}px ${props.theme.spacing[3]}px`};
  }
`);
