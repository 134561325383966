import * as AQL from 'modules/aql-gen';

export const goToMemberView = () => window.location.href = '/';

export const contactSupport = () => {
  window.open('https://influitive-supportdesk.zendesk.com/hc', '_blank');
  window.location.href = window.location.href; // eslint-disable-line no-self-assign
};

const makeGroup = groupId => groupId ? [{ group_id: groupId }] : [];

export const convertTermToAql = (term, groupId) => AQL.all([
  AQL.any([
    AQL.Field({
      field: 'name',
      operator: '$substring',
      string_value: term
    }),
    AQL.Field({
      field: 'email',
      operator: '$substring',
      string_value: term
    }),
    AQL.Field({
      field: 'title',
      operator: '$substring',
      string_value: term
    }),
    AQL.Field({
      field: 'company',
      operator: '$substring',
      string_value: term
    })
  ]),
  AQL.any(makeGroup(groupId))
]);
