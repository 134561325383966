import tracking from 'modules/tracking';
import { StreamChat } from 'stream-chat';
tracking.init('replace_with_tracking_token');
export const nameSpaceEventName = x => `JWTJS::${x}`;

import { tokenUrl } from './from-hub.js';

const chatClient = StreamChat.getInstance(process.env.STREAM_IO_API_KEY);

export default (instance, axios, jwtTokenUrl = tokenUrl, shouldLogOut = true) => {
  let savedToken = null;

  const logout = error => {
    chatClient.disconnectUser();
    savedToken = null;
    tracking.track(nameSpaceEventName('user logged out'), error);
    window.location.href = window.location.protocol + '//' + window.location.hostname + '/users/sign_out';
  };

  const getToken = () => axios.post(jwtTokenUrl, null, {
    withCredentials: true,
    headers: { 'Accept': 'application/jwt', 'X-Requested-With': 'XMLHttpRequest' }
  }).then(data => data.data);

  const retryWithNewToken = error => {
    return getToken().then(token => {
      savedToken = token;
      const config = error.response.config;
      config.headers.X_JWT_TOKEN = savedToken;
      return axios(config);
    });
  };

  instance.interceptors.request.use(config => {
    const prom = savedToken ? Promise.resolve(savedToken) : getToken();
    return prom.then(token => {
      savedToken = token;
      config.headers.X_JWT_TOKEN = savedToken;
      return config;
    });
  }, err => Promise.reject(err));

  instance.interceptors.response.use(res => res, error => {
    /* eslint-disable complexity */
    const inner = (error, count = 2) => {
      if (!error.response) return Promise.reject(error);
      if (401 === error.response.status) {
        return shouldLogOut ? logout(error) : null;
      }
      if (498 === error.response.status) {
        if (count > 0) {
          return retryWithNewToken(error).then(res => res, error => inner(error, count - 1));
        }
        return logout();
      }

      return Promise.reject(error);
    };

    return inner(error);
  });

  instance.destroyJwtToken = () => savedToken = null;

  return instance;
};
