import axios from 'axios';
import * as R from 'ramda';
import jwt from 'modules/jwt';

const hubId = R.pathOr('invalidHubId', ['_infl', 'hub', 'id'], window);
const csrfToken = (document.querySelector('meta[name="csrf-token"]') || {}).content;

const instance = jwt(axios.create({
  baseURL: '/bulk/contact',
  headers: { 'X_ORG_ID': hubId, 'X-CSRF-Token': csrfToken },
  withCredentials: true
}), axios);

export const deleteMembers = ids => instance.post('/destroy', {
  targets: R.map(id => ({ id, hub_id: hubId }), ids),
  attributes: {}
});
