import React from 'react';
import * as R from 'ramda';
import FileUploader from '@influitive/secret-garden/lib/form-inputs/file-uploader';
import ThemeProvider from '@influitive/secret-garden/lib/theme-provider';
import { I18nextProvider } from 'react-i18next';

import i18n from './localization';
import { deleteFile } from './api';

const Uploader = props =>
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={{ colors: { primary: [window._inflTheme.themeColorPrimary] } }}>
      <div data-qa={props['data-qa']}>
        <FileUploader
          apiKey={process.env.FILESTACK_API_KEY}
          height="350px"
          lang={window._infl?.hub?.lang || 'en'}
          removeFile={(_bucket, path) => {
            deleteFile(path);
            props?.clearInput();
          }}
          {...R.omit(['clearInput'], props)}
        />
      </div>
    </ThemeProvider>
  </I18nextProvider>;

Uploader.displayName = 'FileUploader';

export default Uploader;
