import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';

import Error from '../components/error';
import BadgeList from '../components/badge-list';
import { fetchBadges } from '../actions/badges';

const Badges = ({ id, instances, fetchBadges }) => {
  useEffect(() => {
    fetchBadges(id);
  }, [id]);

  return (
    R.isNil(instances[id])
      ? null
      : <div className="c-badges-container">
        <Error errorMsg={instances[id].errorMsg}/>
        <BadgeList id={id} badges={instances[id].badges} />
      </div>
  );
};

Badges.propsTypes = {
  id: PropTypes.string.isRequired,
  instances: PropTypes.object
};

export default connect(
  state => ({
    instances: state
  }),
  { fetchBadges }
)(Badges);
