import tracking from 'modules/tracking';
tracking.init('replace_with_tracking_token');
export const nameSpaceEventName = x => `JWTJS::${x}`;
import axios from 'axios';
import memo from 'memoizee';
import { StreamChat } from 'stream-chat';

const tokenUrl = '/token';
const chatClient = StreamChat.getInstance(process.env.STREAM_IO_API_KEY);

export const getToken = memo(() => axios.post(tokenUrl, null, {
  withCredentials: true,
  headers: { 'Accept': 'application/jwt', 'X-Requested-With': 'XMLHttpRequest' }
}).then(response => response.data), { promise: true });

export const hubConfig = memo(() => axios.get('/api/company_config', {
  withCredentials: true,
  headers: { 'Accept': 'application/jwt', 'X-Requested-With': 'XMLHttpRequest' }
}).then(R.path(['data', 'data'])), { promise: true });

const getHubId = () => hubConfig().then(R.path(['hub', 'id']));

const getWindObj = memo(config => axios.get('/api/c2_homepage/infl_window_object', {
  withCredentials: true,
  baseURL: process.env.HUB_API_URL,
  headers: {
    'X_JWT_TOKEN': config.headers.X_JWT_TOKEN,
    'X_ORG_ID': config.headers.X_ORG_ID
  }
}).then(R.path(['data'])), {
  promise: true,
  primitive: true,
  normalize: args => args.headers.X_ORG_ID + args.headers.X_JWT_TOKEN
});

const getHubLang = config => getWindObj(config).then(R.path(['hub', 'lang']));

const getAndSetToken = async config => {
  try {
    config.headers.X_JWT_TOKEN = await getToken();
  } catch (e) {
    throw new Error('Could not get token');
  }
  return config;
};

const getAndSetHubId = async config => {
  const withToken = await getAndSetToken(config);
  withToken.headers.X_ORG_ID = await getHubId();
  return withToken;
};

const getAndSetHubLang = async config => {
  const withHubId = await getAndSetHubId(config);
  withHubId.headers['Accept-Language'] = await getHubLang(withHubId);
  return withHubId;
};

const retryWithNewToken = async error => {
  const config = await getAndSetToken(error.response.config);
  return axios(config);
};

const logout = error => {
  chatClient.disconnectUser();
  getToken.clear();
  tracking.track(nameSpaceEventName('user logged out'), error);
  window.location.href = window.location.protocol + '//' + window.location.hostname + '/users/sign_out';
};

export default ({ shouldLogOut = true, withBearer = false } = {}) => {
  const instance = axios.create({
    withCredentials: true,
    baseURL: process.env.HUB_API_URL
  });

  instance.interceptors.request.use(async config => {
    const withHubLang = await getAndSetHubLang(config);

    if (withBearer) {
      withHubLang.headers['Authorization'] = `Bearer ${withHubLang.headers.X_JWT_TOKEN}`;
    }

    return withHubLang;
  }, err => Promise.reject(err));

  instance.interceptors.response.use(res => res, error => {
    /* eslint-disable complexity */
    const inner = (error, count = 2) => {
      if (!error.response) return Promise.reject(error);
      if (401 === error.response.status) {
        return shouldLogOut ? logout(error) : null;
      }
      if (498 === error.response.status) {
        if (count > 0) {
          return retryWithNewToken(error).then(res => res, error => inner(error, count - 1));
        }
        return logout();
      }

      return Promise.reject(error);
    };

    return inner(error);
  });

  instance.destroyJwtToken = () => getToken.clear();

  return instance;
};

export const windowObj = async() => {
  const withHubId = await getAndSetHubId({ headers: {} });
  return getWindObj(withHubId);
};
