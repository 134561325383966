import React from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import {
  compose,
  withProps,
  defaultProps,
  withState,
  withHandlers
} from 'recompose';

import Button from '@influitive/secret-garden/lib/button';
import Icon from '@influitive/secret-garden/lib/icon';
import Input from '@influitive/secret-garden/lib/form-inputs/input';
import Tooltip from '@influitive/secret-garden/lib/tooltip';

import { deleteMembers } from './actions';
import classes from './delete-members.css';
import exampleContent from './example_content.png';
import exampleTopic from './example_topic.png';
import { toast } from 'react-toastify';

const capitalize = str => str && str.charAt(0).toUpperCase() + str.slice(1);

const toastMessage = (membersLength, modalFor)=> {
  const buildMessage = R.cond([
    [ R.equals('true|contact'),
      R.always(`Deleting ${membersLength} contacts. Check the Notification Centre for the status!`)],

    [ R.equals('false|contact'),
      R.always('Contact deleted.')],

    [ R.equals('true|member'),
      R.always(`Deleting ${membersLength} members. It may take some time to complete data cleanup. Check the Notification Centre for the status!`)],

    [ R.equals('false|member'),
      R.always('Member deleted. It may take some time to complete data cleanup in the Advocate View.')]
  ]);
  const conditionStr =  `${membersLength > 1}|${modalFor}`;
  return buildMessage(conditionStr);
};

const notifyOfDeletion = (memberUuids, modalFor) => {
  const membersLength = memberUuids.length;
  const message = toastMessage(membersLength, modalFor);
  toast.success(message);
};

// react tooltip doesn't work with disabled elements, thus div wrapper
// tooltip gets binded using event listener, which won't go away after button gets active,
// thus replacing divs to avoid triggering tooltip for an active button
const OptionalTooltip = ({ show, children }) =>
  show
    ? <div data-tip={true} data-for='delete-button-tooltip'>
      {children}
      <Tooltip id='delete-button-tooltip' multiline={true}>
        <span>
          This button will enable<br />
          after you type DELETE in the<br />
          input above
        </span>
      </Tooltip>
    </div>
    : <div>{children}</div>;

export default compose(
  defaultProps({
    memberUuids: [],
    onReject: () => {},
    onDeletion: () => {},
    modalFor: 'member'
  }),
  withProps(({ memberUuids, modalFor }) => ({
    isMultiple: memberUuids.length > 1,
    isMember: modalFor === 'member'
  })),
  withProps(({ isMultiple }) => ({
    pluralPostfix: isMultiple ? 's' : ''
  })),
  withState('inputValue', 'setInputValue', ({ isMultiple }) => isMultiple ? '' : 'DELETE'),
  withState('disableAccept', 'setDisableAccept', false),
  withProps(({ isMultiple, inputValue, disableAccept, memberUuids }) => ({
    isButtonDisabled: (isMultiple && inputValue !== 'DELETE') || disableAccept || memberUuids.length === 0
  })),
  withHandlers({
    handleInputChange: ({ setInputValue }) => event => {
      setInputValue(event.target.value);
    },
    handleDeletion: ({ memberUuids, setDisableAccept, onDeletion, modalFor }) => () => {
      setDisableAccept(true);
      deleteMembers(memberUuids).then(() => {
        notifyOfDeletion(memberUuids, modalFor);
        onDeletion();
      });
    }
  })
)(({
  isMultiple,
  inputValue,
  handleDeletion,
  handleInputChange,
  isButtonDisabled,
  onReject,
  modalFor,
  isMember,
  pluralPostfix
}) => (
  <React.Fragment>
    <div className={cx(classes.alert, classes.paragraph)}>
      <Icon icon='exclamation-circle' className={classes.alertIcon} width='24px' />
      <span className={classes.alertText}>
        You cannot undo deletion
      </span>
    </div>
    {
      isMember ?
        <React.Fragment>
          <p className={classes.pgraph}>
            By doing this, you will permanently lose
            {isMultiple ? ' these' : ' this'} member{pluralPostfix} and all of their
            data in:
          </p>
          <ul className={classes.dataList}>
            <li>Challenge Responses</li>
            <li>Groups</li>
            <li>Referrals</li>
            <li>Acts of Advocacy</li>
            <li>Targeting Rules</li>
            <li>Reporting</li>
            <li>Leaderboard</li>
            <li>Activity Feed</li>
          </ul>
          <p className={classes.pgraph}>
            Their posts and replies in Discussions will remain but become anonymized.
          </p>
          <div className={classes.examples}>
            <img src={exampleTopic} className={classes.example} />
            <img src={exampleContent} className={classes.exampleSmall} />
          </div>
        </React.Fragment>
        :
        <p className={classes.pgraph}>
          Are you sure you want to delete this Contact from the Invite List?
        </p>
    }
    {isMultiple && <Input id='confirmation' onChange={handleInputChange} value={inputValue} label='Type DELETE to confirm' required={true} />}
    <div className={classes.footer}>
      <OptionalTooltip show={isButtonDisabled}>
        <Button variant='danger' className={classes.buttonRightPadding} disabled={isButtonDisabled} onClick={handleDeletion}>
          Yes, delete {capitalize(modalFor)}{pluralPostfix}
        </Button>
      </OptionalTooltip>
      <Button variant='secondary' className={classes.button} onClick={onReject}>
        No, do not delete
      </Button>
    </div>
  </React.Fragment>
));
