import axios from 'axios';
import * as R from 'ramda';
import jwt from 'modules/jwt';
import moment from 'moment';
import { fetchedBadges, handleError } from './action-types';
import i18n from '../localization';

const hubId = R.pathOr('invalidHubId', ['_infl', 'hub', 'id'], window);
const lang = window._infl?.hub?.lang || 'en';

const mapBadgeAttributes = badge => {
  const getAttribute = key => R.path(['attributes', key], badge);

  return {
    id: badge.id,
    name: getAttribute('name'),
    description: getAttribute('description'),
    icon: badge.links.icon,
    createdAt: moment(getAttribute('created-at')),
    earnedAt: moment(getAttribute('earned-at')),
    remainingProgressPercentage: getAttribute('remaining-progress-percentage'),
    goal: getAttribute('goal'),
    isAutomatic: getAttribute('automatic'),
    levelInfo: mapLevelAttributes(getAttribute('level-info') || {})
  };
};

const mapLevelAttributes = levelInfo => ({
  name: R.or(levelInfo.name, i18n.t('Other Badges')),
  uuid: levelInfo.uuid,
  rank: R.or(levelInfo.rank, Number.MAX_SAFE_INTEGER),
  requiredBadgesCount: R.or(R.prop('required-badge-count', levelInfo), 0)
});

const instance = jwt(axios.create({
  baseURL: process.env.HUB_API_URL,
  headers: { 'X_ORG_ID': hubId },
  withCredentials: true
}), axios);

export const fetchBadges = id => dispatch => (
  instance.get(`badges_progress?contact_id=${id}`, {
    headers: { 'Accept-Language': lang }
  }).then(response => {
    const badges = R.map(mapBadgeAttributes, response.data.data);
    dispatch(fetchedBadges({ badges, id }));
  }).catch(error => {
    console.error(error);
    dispatch(handleError({
      id,
      errorMsg: 'Could not load badges information.'
    }));
  })
);
