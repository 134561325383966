import React from 'react';
import styled from '@emotion/styled';
import * as R from 'ramda';
import { Box } from '@rebass/grid/emotion';
import { compose, lifecycle, withState, defaultProps, withProps } from 'recompose';
import { showHubSwitcher } from '../../common/utils';
import HubSwitcher from '../hub-switcher';
import Links from './links';
import { withNamespaces } from 'react-i18next';

/* eslint complexity: 0 */
const setBodyOverflow = (hidden, scrollPos) => {
  document.documentElement.style.position = hidden ? 'fixed' : 'static';
  document.documentElement.style.width = document.body.style.width = hidden ? '100%' : 'auto';
  document.documentElement.style.overflow = document.body.style.overflow = hidden ? 'hidden' : 'auto';
  scrollPos && window.scrollTo(0, scrollPos);
};

const topHeaderOffset = 108;

const Container = styled(Box)`
  display: ${props => props.open ? 'block' : 'none'};
  width: 100%;
  height: calc(100vh - ${topHeaderOffset}px);
  background-color: ${props => props.theme.colors.white};
`;

const StyledInnerContainer = styled(Box)`
  height: ${props => props.height}px;
  overflow-y: scroll;
`;

class InnerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  state = {
    containerHeight: window.innerHeight - topHeaderOffset
  };

  componentDidUpdate() {
    if (this.props.open) this.containerRef.current.scrollTop = 0;
    const height = window.innerHeight - topHeaderOffset;
    if (this.state.containerHeight !== height) {
      this.setState({ containerHeight: height });
    }
  }

  render() {
    return <StyledInnerContainer height={this.state.containerHeight}
      ref={this.containerRef} className="c-mobile-nav-dd-inner">
      {this.props.children}
    </StyledInnerContainer>;
  }
}

export const SignOut = styled(Links)`
  border-top: 1px solid ${props => props.theme.colors.neutral[4]};
`;

const MoreMenu = compose(
  withState('scrollPos', 'setScrollPos', window.scrollY),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.open === this.props.open) return;

      this.props.open
        ? this.props.setScrollPos(window.scrollY, () => setBodyOverflow(this.props.open))
        : setBodyOverflow(this.props.open, this.props.scrollPos);
    }
  }),
  withNamespaces(),
  withProps(({ features, t }) => ({
    mobileMenuLinks: R.pipe(
      R.defaultTo([]),
      R.reject(R.propEq('type', 'sign_out')),
      R.map(link => ({
        ...link,
        label: t(link.label),
        title: t(`Access ${link.label} page`)
      }))
    )(features),
    signOutLink: R.pipe(
      R.defaultTo([]),
      R.filter(R.propEq('type', 'sign_out')),
      R.map(link => ({
        ...link,
        label: t(link.label),
        title: t(`Go ${link.label}`)
      }))
    )(features)
  })),
  defaultProps({
    useSuspense: false
  })
)(({
  open,
  hubSwitcher,
  showMessagesNotification,
  unseenCounts,
  mobileMenuLinks,
  signOutLink
}) => (
  <Container open={open} className="c-mobile-nav-dd-container">
    <InnerContainer open={open}>
      <Links
        items={mobileMenuLinks}
        showMessagesNotification={showMessagesNotification} />
      {showHubSwitcher(hubSwitcher || {}) && <HubSwitcher data={hubSwitcher} unseenCounts={unseenCounts} />}
      <SignOut items={signOutLink} />
    </InnerContainer>
  </Container>
));

export default MoreMenu;
