import React from 'react';
import { compose, setDisplayName, withHandlers } from 'recompose';
import ExperienceCard from '@influitive/secret-garden/lib/experience-card';
import Image from '@influitive/secret-garden/lib/image';
import defaultImg from './images/generic-experience.png';
import { track } from '../../common/tracking-utils';
import { withNamespaces } from 'react-i18next';
import truncateNumber from '../../common/truncate-number';

export default compose(
  withHandlers({
    track: ({ experience }) => () => track('experience', { id: experience.id })
  }),
  withNamespaces(),
  setDisplayName('ExperienceItem')
)(({ experience, track, t }) => (
  <ExperienceCard
    uuid={experience.uuid}
    media={<Image width="230" src={experience.image || defaultImg} alt={experience.title} />}
    url={`/experiences/${experience.id}`}
    title={experience.title}
    challengeCountText={t('{{count}} Challenge', { count: experience.total_challenge_count })}
    unseenCountText={t('{{count}} New', { count: truncateNumber(experience.unseen_challenge_count) })}
    ctaText={experience.call_to_action}
    onClick={track}
    data-qa={`experience-item-${experience.id}`} />
));
