import t from 'tcomb';

import { StringOperator, NumberOperator, ArrayOperator, TimeOperator, ImageOperator } from './operator';

export const StringInterface = t.interface({ string_value: t.String, operator: StringOperator });
export const NumberInterface = t.interface({ number_value: t.Number, operator: NumberOperator });
export const StringArrayInterface = t.interface({ string_array_value: t.list(t.String), operator: ArrayOperator });
export const MsAgoInterface = t.interface({ milliseconds_ago_value: t.Number, operator: TimeOperator });
export const ImageInterface = t.interface({ operator: ImageOperator });

export default {
  StringInterface,
  NumberInterface,
  StringArrayInterface,
  MsAgoInterface,
  ImageInterface
};
