import * as R from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import contains from 'contains';
import { StreamChat } from 'stream-chat';

const chatClient = StreamChat.getInstance(process.env.STREAM_IO_API_KEY);

export const qaSel = sel => `ADMIN-NAV-FE_${sel.toLowerCase().replace(/\s/g, '-')}`;

export const filterMenu = (features, permissions, windowObj) => menu => {
  const inner = (menu) => {
    if (!menu) return undefined;

    return menu.reduce((acc, curr) => {
      if (!curr.predicate(features, permissions, windowObj)) return acc;
      acc.push({ ...curr, children: inner(curr.children) });
      return acc;
    }, []);
  };

  return inner(menu);
};

const isCurrent = href => href === '/corporate' ? window.location.pathname === href :  R.contains(href, window.location.pathname);

export const isMaven = R.contains('maven', window.navigator.userAgent);

export const active = menuItem => Array.isArray(menuItem.href)
  ? menuItem.href.some(isCurrent)
  : isCurrent(menuItem.href) || isCurrent(menuItem.active);

export const noneContain = (elems, target) => R.pipe(
  R.reject(R.isNil),
  R.none(elem => contains(elem, target)),
)(elems);

const useTabChange = ({ shouldSkip, onHidden, onShow }) => {
  useEffect(() => {
    if (shouldSkip) return;

    const handleVisibilityChange = () =>
      document.visibilityState === 'hidden' ? onHidden() : onShow();

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [shouldSkip, onHidden, onShow]);
};

export const useHandleConnection = ({ shouldSkip, setUnreadCount }) => {
  const [isWSDisconnected, setWSDisconnected] = useState(chatClient.wsConnection?.isDisconnected);

  const onHidden = useCallback(() => {
    !chatClient.wsConnection?.isDisconnected && void chatClient.wsConnection?.disconnect();
    setWSDisconnected(true);
  }, [chatClient.wsConnection]);

  const onShow = useCallback(() => {
    if (chatClient.wsConnection?.isDisconnected && !chatClient.wsConnection.isConnecting) {
      void chatClient.wsConnection.connect().then(res => {
        void chatClient.recoverState().then(() => res && res.me && setUnreadCount(res.me.unread_channels));
        setWSDisconnected(false);
      });
    } else {
      chatClient.recoverState().then(() =>
        chatClient.wsConnection.connectionOpen && chatClient.Connection.connectionOpen.then((res) =>
          res && res.me && setUnreadCount(res.me.unread_channels))
      );
      setWSDisconnected(false);
    }
  }, [chatClient.wsConnection]);

  useTabChange({ shouldSkip, onHidden, onShow });

  return isWSDisconnected;
};
