import React from 'react';
import { branch, compose, renderComponent, renderNothing, setDisplayName, withHandlers, withProps, withState } from 'recompose';
import { useMediaQuery } from 'react-responsive';
import { withTheme } from 'emotion-theming';
import debounce from 'debounce-promise';
import { Async } from '@influitive/secret-garden/lib/form-inputs/type-ahead';
import Flex, { Box } from '@influitive/secret-garden/lib/flex';
import { PreloaderElement, pulsatingAnimation } from '@influitive/secret-garden/lib/skeleton-preloader';
import Tooltip from '@influitive/secret-garden/lib/tooltip';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { advocateSearch, searchGroups } from '../api';
import ActionButton from './action-button';
import { lensStates } from '../constants';

const typeaheadStyling = props => css`
  display: flex;
  width: ${props.width[3]};
  margin-right: ${props.theme.spacing[2]}px;
  margin-top: ${props.theme.spacing[2]}px;

  @media(max-width: 1225px) {
     width: ${props.width[2]};
  }

  @media(max-width: 768px) {
    width: ${props.width[1]};
 }

  @media(max-width: ${props.theme.sizes.phone}px) {
     width: ${props.width[0]};
     margin-right: 0;
  }
`;

const searchContainerStyling = props => css`
  margin-bottom: ${props.theme.spacing[1]}px;
  margin-top: -${props.theme.spacing[2]}px;

  @media (min-width: ${props.theme.sizes.desktop + 1}px) {
    margin-bottom: 0;
  }
`;

const StyledAsync = styled(Async)`
 ${typeaheadStyling}
`;

const buildSingleOption = impersonatedUser => ({
  image: impersonatedUser['profile-image'],
  label: impersonatedUser.name,
  name: impersonatedUser.name,
  value: impersonatedUser.name,
  uuid: impersonatedUser.uuid
});

const TypeAheadPlaceholder = styled(PreloaderElement)`
  ${props => css`
    height: ${props.isMobile ? props.theme.spacing[6] : props.theme.spacing[5]}px;
    border-radius: ${props.theme.container.radius[1]}px;
  `}
  ${typeaheadStyling}
`;

const SkeletonPreloader = styled(Flex)`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${pulsatingAnimation}
`;

const Placeholder = styled(({ className, isMobile, isTablet }) => (
  <SkeletonPreloader
    className={className}
    width={1}
    flex="1 1 auto"
    flexDirection="row !important"
    flexWrap={(isTablet && !isMobile) ? 'nowrap' : 'wrap'}>
    <TypeAheadPlaceholder width={['100%', '150px', '150px', '200px']} isMobile={isMobile} />
    <TypeAheadPlaceholder width={['100%', '100%', '200px', '350px']} isMobile={isMobile} />
  </SkeletonPreloader>
))`
  ${searchContainerStyling}
`;

const TypeAheadTooltip = ({ disabled }) => (
  <Tooltip id="lens-typeahead" zIndex={99999} place="bottom" disable={disabled} clickable={true}>
    <Box data-qa='lens-typeahead-tip' maxWidth='186'>
      Only 1 Lens may be activated at a time.
      Clear your existing Lens in order to search for a new Lens.
    </Box>
  </Tooltip>
);

const GroupTooltipWrapper = styled(Box)`
  @media (max-width: ${props => props.theme.sizes.phone}px) {
    width: inherit;
  }
`;

const IndividualTooltipWrapper = styled.div`
  @media (max-width: ${props => props.theme.sizes.tablet}px) {
    margin-right: ${props => props.theme.spacing[2]}px;
    width: 100%;
  }
  @media (max-width: ${props => props.theme.sizes.phone}px) {
    margin-right: 0;
    width: inherit;
  }
`;

let asyncRef = null;

export default compose(
  branch(({ inMemberView }) => !inMemberView, renderNothing),
  withTheme,
  withProps(({ lens, lensState }) => ({
    loadedLens: lensState !== lensStates.CLEARED && lens?.['impersonated-user']
  })),
  withProps(({ theme, loadedLens, lens }) => ({
    isMobile: useMediaQuery({ query: `(max-width: ${theme.sizes.phone}px)` }),
    isTablet: useMediaQuery({ query: `(max-width: ${theme.sizes.tablet}px)` }),
    impersonatedUser: loadedLens ? lens['impersonated-user'] : null
  })),
  branch(({ impersonatedUser, lensState }) =>
    !lensState || ((lensState === lensStates.ACTIVE) && !impersonatedUser), renderComponent(Placeholder)),
  withState('individualValue', 'setIndividualValue', null),
  withState('groupValue', 'setGroupValue', null),
  withState('isMenuOpen', 'setIsMenuOpen', false),
  withHandlers({
    changeHandlerIndividual: ({ setIndividualValue, setIsMenuOpen }) => e => {
      setIsMenuOpen(false);
      setIndividualValue(e);
    },
    changeHandlerGroup: ({ setGroupValue, setIndividualValue, setIsMenuOpen }) => e => {
      setGroupValue(e);
      e && setIndividualValue(null);
      asyncRef.focus();
      setIsMenuOpen(true);
    },
    getOptionsIndividual: ({ groupValue, canHaveProfileLinks }) => (value = '') =>
      R.pipe(R.trim, term => advocateSearch(term, R.prop('value', groupValue), canHaveProfileLinks))(value),
    getOptionsGroup: () => (value = '') => R.pipe(R.trim, searchGroups)(value)
  }),
  withProps(({ lensState, impersonatedUser, individualValue }) => ({
    userValue: (lensState !== lensStates.CLEARED && impersonatedUser) ?
      buildSingleOption(impersonatedUser) :
      individualValue
  })),
  withProps(({ lensState, isMobile, isTablet }) => ({
    searchWrap: (isTablet && !isMobile) ? 'nowrap' : 'wrap',
    typeaheadSize: isTablet ? 'large' : 'normal',
    disabled: lensState !== lensStates.CLEARED
  })),
  setDisplayName('LensLoader')
)(styled(({
  getOptionsGroup,
  getOptionsIndividual,
  changeHandlerIndividual,
  changeHandlerGroup,
  userValue,
  groupValue,
  profile,
  lensState,
  setClone,
  className,
  isTablet,
  createLens,
  clearLens,
  cancelLens,
  disabled,
  searchWrap,
  typeaheadSize,
  isMenuOpen,
  setIsMenuOpen,
  lensActionPending
}) => (
  <Flex className={className} width={1} flex="1 1 auto" flexWrap={searchWrap}>
    <TypeAheadTooltip disabled={!disabled} />
    <GroupTooltipWrapper data-tip data-for="lens-typeahead">
      <StyledAsync
        value={groupValue}
        defaultOptions={true}
        loadOptions={debounce(getOptionsGroup, 200)}
        onChange={changeHandlerGroup}
        inputProps={{ 'data-qa': 'group-search' }}
        placeholder='Filter by Group'
        isDisabled={disabled}
        width={['100%', '150px', '150px', '200px']}
        size={typeaheadSize} />
    </GroupTooltipWrapper>
    <Flex width={isTablet && 1}>
      <IndividualTooltipWrapper onClick={() => setIsMenuOpen(!isMenuOpen)} data-tip data-for="lens-typeahead">
        <StyledAsync
          className="individual-search"
          key={groupValue?.uuid}
          selectRef={ref => asyncRef = ref}
          value={userValue}
          defaultOptions={true}
          loadOptions={debounce(getOptionsIndividual, 200)}
          onChange={changeHandlerIndividual}
          inputProps={{ 'data-qa': 'individual-search' }}
          placeholder='Search name, email, title, company'
          isDisabled={disabled}
          decorator="individual"
          required={true}
          width={['100%', '100%', '200px', '350px']}
          size={typeaheadSize}
          onInputChange={(value) => value && setIsMenuOpen(true)}
          onBlur={() => setIsMenuOpen(false)}
          menuIsOpen={!disabled && isMenuOpen} />
      </IndividualTooltipWrapper>
      <ActionButton
        value={userValue}
        disabled={profile?.id === userValue?.uuid}
        lensState={lensState}
        setClone={setClone}
        isTablet={isTablet}
        createLens={createLens}
        clearLens={clearLens}
        cancelLens={cancelLens}
        lensActionPending={lensActionPending}
      />
    </Flex>
  </Flex>
))`
  ${searchContainerStyling}
`);
