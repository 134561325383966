import React from 'react';
import { compose, withProps, setDisplayName } from 'recompose';
import * as R from 'ramda';
import { showHubSwitcher } from '../../common/utils';
import HubSwitcher from '../hub-switcher';
import { SignOut } from '../more-menu';
import Links from '../more-menu/links';
import { withNamespaces } from 'react-i18next';
import { domain, subdomain, environment } from '../../common/from-hub.js';

const isTermsAndConditionsDisabled = (typeof window === 'undefined') ? undefined : R.path(['_infl', 'hub', 'isTermsAndConditionsDisabled'], window);

const popoverMenuItems = (t, enabledFeatures, onProfileModalOpen) => [
  { type: 'contact_admin', path: enabledFeatures.includes('inbox_v2') ? '/chat?p=admin' : '/inbox/conversation/with_admin', label: t('Message Admin'), title: t('Access Contact Admin page') },
  { type: 'terms_and_conditions', path: '/terms_and_conditions', label: t('Terms and Conditions'), title: t('Access Terms and Conditions page') },
  { type: 'language', path: '#', label: t('Language'), title: t('Language'), onClick: onProfileModalOpen }
].filter(R.cond([
  [itemType('language'), () => enabledFeatures.includes('multilingual_content')],
  [itemType('terms_and_conditions'), () => !isTermsAndConditionsDisabled],
  [R.T, R.T]
]));

const itemType = type => R.pipe(R.path(['type']), R.equals(type));

const wegoHealth = {
  subdomain: 'wegohealth',
  domain: 'https://platform.wegohealth.com',
  signOutUrl: 'https://wegohealth.influitive.com/users/sign_out'
};
const signOutUrl = domain === wegoHealth.domain || (environment === 'production' && subdomain === wegoHealth.subdomain) ? wegoHealth.signOutUrl : '/users/sign_out';

const Options = compose(
  withProps(({ t }) => ({
    signOutLink: [{
      type: 'sign_out',
      path: signOutUrl,
      label: t('Sign Out'),
      title: t('Sign Out')
    }]
  })),
  setDisplayName('ProfileOptions')
)(
  ({ features, hubSwitcher, t, unseenCounts, enabledFeatures, onProfileModalOpen, signOutLink }) => (
    <nav className="c-nav">
      <Links
        items={popoverMenuItems(t, enabledFeatures, onProfileModalOpen)}
        features={features}
        className="c-nav-list" />
      {showHubSwitcher(hubSwitcher) &&
        <HubSwitcher data={hubSwitcher} unseenCounts={unseenCounts} />}
      <SignOut items={signOutLink} className="c-sign-out" />
    </nav>
  )
);

export default withNamespaces()(Options);
