module.exports = {
  // KOMODO VARS

  // neutrals
  'neutral-0': '#232B34',
  'neutral-1': '#475666',
  'neutral-2': '#6C7885',
  'neutral-3': '#B5BBC2',
  'neutral-4': '#DADDE0',
  'neutral-5': '#ECEEEF',
  'neutral-6': '#F6F7F7',
  'white': '#ffffff',

  // neutral in rgb

  'rgb-neutral-0': '35, 43, 52',
  'rgb-neutral-1': '71, 86, 102',
  'rgb-neutral-2': '108, 120, 133',
  'rgb-neutral-3': '181, 187, 194',
  'rgb-neutral-4': '218, 221, 224',
  'rgb-neutral-5': '236, 238, 239',
  'rgb-neutral-6': '246, 247, 247',
  'rgb-white': '255, 255, 255',

  // Hydra - info
  'info': '#00B7CC',
  'info-dark': '#006882',
  'info-mid': '#39DAEC',
  'info-light': '#D9FBFF',

  // Dragonair - primary
  'primary': '#0A5ECC',
  'primary-dark': '#033A81',
  'primary-mid': '#5193E9',
  'primary-light': '#E7F1FF',

  // Saphira - secondary
  'secondary': '#00264F',
  'secondary-dark': '#001A37',
  'secondary-mid': '#104379',
  'secondary-light': '#DFECF9',

  // Elliot - success
  'success': '#20A960',
  'success-dark': '#066131',
  'success-mid': '#62E49F',
  'success-light': '#D8FFEA',

  // Ghidorah - warning
  'warning': '#FDBD00',
  'warning-dark': '#A46600',
  'warning-mid': '#FFDD78',
  'warning-light': '#FFFBDF',

  // Smaug - accent
  'accent': '#F85228',
  'accent-dark': '#B33906',
  'accent-mid': '#F37D5F',
  'accent-light': '#FFE8E3',

  // Mushi - error
  'error': '#CE063B',
  'error-dark': '#A2002B',
  'error-mid': '#E0426C',
  'error-light': '#FFE7ED',

  // Shadows
  'shadow-xsmall': '0 2px 3px 0 rgba(35, 43, 52, 0.15)',
  'shadow-small': '0 2px 6px 0 rgba(35, 43, 52, 0.25)',
  'shadow-medium': '0 0 20px 0 rgba(35, 43, 52, 0.1), 0 2px 4px 0 rgba(35, 43, 52, 0.1)',
  'shadow-large': '0 0 30px 0 rgba(35, 43, 52, 0.1), 0 3px 12px 0 rgba(35, 43, 52, 0.1)',
  'shadow-large-raised': '0 10px 30px 0 rgba(35, 43, 52, 0.1), 0 3px 12px 0 rgba(35, 43, 52, 0.1)',
  'shadow-inner': 'inset 0 2px 4px 0 rgba(35, 43, 52, 0.2)',

  // CUSTOM VARS

  // screen sizes
  'tablet': '950px', // admin-nav
  'mobile': '630px', // roi-view

  // ADMIN NAV
  'icon-size': '20px',
  'banner-height': '62px',

  // ADVOCATE-TABLE
  'stickyMinWidth': '325px',

  // profile-details
  'dark-gray': '#666666',
  'medium-gray': '#969696',
  'light-gray': '#d0d2d3',
  'darker-gray': '#444444'
};
