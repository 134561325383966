import React from 'react';
import { compose, withState } from 'recompose';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import cn from 'classnames';
import Tooltip from '@influitive/secret-garden/lib/tooltip';
import { Box } from '@influitive/secret-garden/lib/flex';

import { track } from '../../common/tracking-utils';
import { qaSel, omitProps } from '../../common/utils';
import { UnreadMarker } from '../unread-marker';
import DisabledTooltip from '../disabled-tooltip';

const trackClick = type => () => type && track(type);

const hidden = R.pipe(
  R.flip(R.contains),
  R.any(R.__, ['c-challenges', 'c-activity_feed', 'c-notifications'])
);

const applyIndent = props => props.indentLevel && `padding-left: ${15 + props.indentLevel * 25}px;`;

const commonLinkStyles = props => css`
  display: block;
  padding: 7px 30px 7px 15px;
  ${applyIndent(props)}
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: ${props.theme.fonts.size[3]}px;
  ${props.selected && 'font-weight: bold;'}
  @media (min-width: 769px) {
    padding: 3px 30px 3px 15px;
    ${applyIndent(props)}
    font-size: ${props.theme.fonts.size[2]}px;
  }
`;

export const LinkContainer = styled(({ className, ...props }) => (
  <ul role="menu" className={cn('c-link-container', className)}{...props}>
    {props.children}
  </ul>))`
  padding: 15px 0;
  margin: 0;
  list-style: none;
`;

export const Link = styled(omitProps(['indentLevel'])(
  ({ className, children, isActive, ...rest }) => (
    <a className={cn(className, 'c-link', { 'c-active': isActive })} {...rest}>
      {children}
    </a>
  )
))`
  ${commonLinkStyles}
  color: ${props => props.selected ? props.theme.colors.primary[0] : props.theme.colors.neutral[1]};
  &:visited {
    color: ${props => props.selected ? props.theme.colors.primary[0] : props.theme.colors.neutral[1]};
  }
  &:hover, &:focus, &:active {
    text-decoration: none;
  }
  @media (min-width: 769px) {
    &:hover, &:focus, &:active {
      background-color: ${props => props.theme.colors.neutral[5]};
      color: ${props => props.theme.colors.primary[0]};
    }
  }
  @media (max-width: 768px) {
    ${props => props.isActive && `background-color: ${props.theme.colors.neutral[5]};`}
    ${props => props.isActive && `color: ${props.theme.colors.primary[0]};`}
  }
  ${props => props.disabled && css`
    color: ${props.theme.colors.neutral[3]};
    &:hover, &:focus, &:active, &:visited {
      color: ${props.theme.colors.neutral[3]};
    }
  `}
`;

export const DisabledLink = styled(omitProps(['indentLevel'])(({ className, name, tooltipMessage }) => (
  <React.Fragment>
    <span className={cn(className, 'c-link')} data-tip data-for='disabled-link-tooltip'>{name}</span>
    <Tooltip id='disabled-link-tooltip' place='bottom'>
      <Box data-qa='disabled-link'>{tooltipMessage}</Box>
    </Tooltip>
  </React.Fragment>
)))`
  ${commonLinkStyles}
  color: ${props => props.selected ? props.theme.colors.neutral[2] : props.theme.colors.neutral[3]};
  cursor: not-allowed;
`;

const ListItem = styled('li')`
  margin: 0;
  padding: 0;
  border: 0;
  display: ${props => hidden(props.className) ? 'none' : 'block'};
`;

const Label = styled('span')`
  position: relative;
`;

const StyledUnreadMarker = styled(UnreadMarker)`
  right: -10%;
  top: 22%;
`;

const Links = compose(
  withState('activeLink', 'setActiveLink', '')
)(({ items, showMessagesNotification, className, activeLink, setActiveLink }) => (
  /* eslint-disable complexity */
  <LinkContainer className={className} onTouchMove={() => setActiveLink('')}>
    {items.map((item, index) => <ListItem
      className={`c-nav-item c-${item.type}`}
      key={item.type + index}
      role="menuitem">
      <Link
        data-tip={item.disabled}
        data-for={`disabled-${item.type}`}
        data-qa={qaSel(`more-menu-link-${item.type}`)}
        href={item.disabled ? '#' : item.path}
        isActive={activeLink === item.path}
        onClick={item.disabled ? () => {} : () => {
          trackClick(item.track);
          setActiveLink(item.path);
          item.onClick && item.onClick();
        }}
        onBlur={item.disabled ? () => {} : () => setActiveLink('')}
        aria-label={item.title}
        disabled={item.disabled} >
        <Label>
          {item.label}
          {item.type === 'messages' && showMessagesNotification && <StyledUnreadMarker show={showMessagesNotification} />}
        </Label>
      </Link>
      {item.disabled && <DisabledTooltip id={`disabled-${item.type}`} />}
    </ListItem>
    )}
  </LinkContainer>
));

export default Links;
