import * as R from 'ramda';
import axios from 'axios';

import jwt2, { windowObj } from 'modules/jwt-2';

const C2_HOMEPAGE_URL = 'api/c2_homepage';
const api = jwt2();

const unnest = R.path(['data', 'data']);

export const customBranding = async() => {
  const { branding } = await windowObj();
  return branding.serializedBrandingUrl
    ? axios.get(branding.serializedBrandingUrl).then(R.prop('data'))
    : axios.get('/api/branding').then(unnest);
};

export const getRecommendedChallenges = () => api.get(`${C2_HOMEPAGE_URL}/carousel`).then(R.pipe(
  R.prop('data'),
  ({ data, meta }) => ({
    challenges: data,
    orderedBy: meta.sort
  })
));

export const getFeatures = () => api.get('/api/features').then(unnest);
