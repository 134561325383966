import axios from 'axios';
import jwt from 'modules/jwt';
import moment from 'moment';

const hubId = R.pathOr('invalidHubId', ['_infl', 'hub', 'id'], window);
const lang = window._infl?.hub?.lang || 'en';
const requestHeaders = { headers: { 'Accept-Language': lang } };

const hubApi = jwt(axios.create({
  baseURL: process.env.HUB_API_URL,
  headers: { 'X_ORG_ID': hubId },
  withCredentials: true
}), axios);

const mapBadgeAttributes = badge => {
  const getAttribute = key => R.path(['attributes', key], badge);

  return {
    id: badge.id,
    name: getAttribute('name'),
    description: getAttribute('description'),
    icon: badge.links.icon,
    createdAt: moment(getAttribute('created-at')),
    earnedAt: moment(getAttribute('earned-at')),
    remainingProgressPercentage: getAttribute('remaining-progress-percentage'),
    goal: getAttribute('goal'),
    isAutomatic: getAttribute('automatic')
  };
};

export const fetchBadgeProgress = (badgeID, contactUUID) => (
  hubApi.get(`/badges_progress/${badgeID}?contact_id=${contactUUID}`, requestHeaders)
    .then(R.pipe(R.path(['data', 'data']), mapBadgeAttributes))
);
