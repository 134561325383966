import React from 'react';
import styled from '@emotion/styled';

const LoaderWrapper = styled('div')`
  width: 100%;
  margin: auto 0;
  padding: 15px;
  text-align: center;
`;

const LoaderSpinner = styled('div')`
  width: 36px;
  height: 36px;
  margin: 0 auto;
  border-radius: 50%;
  border: 5px solid ${props => props.theme.colors.neutral[4]};
  border-top-color: ${props => props.theme.colors.primary[0]};
  animation: spin 1.2s ease-in-out infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const Loader = () => (
  <LoaderWrapper className="c-loader-wrapper">
    <LoaderSpinner className="c-loader-spinner"/>
  </LoaderWrapper>
);

export default Loader;
