import t from 'tcomb';
import { StringInterface, StringArrayInterface } from '../value-interfaces';

const CustomFieldBase = t.interface({
  custom_field_id: t.String
});

const extensions = [
  StringInterface,
  StringArrayInterface
];

const interfaces = extensions.map(ext => CustomFieldBase.extend(ext, { strict: true }));

const CustomField = t.union(interfaces, 'CustomField');
/* eslint complexity: 0 */
export default CustomField;
