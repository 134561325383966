import React from 'react';
import * as R from 'ramda';
import { compose, setDisplayName, withProps, branch, renderNothing } from 'recompose';

import { qaSel, sanitizeHTML } from '../../common/utils';

const customBranding = ({ enabledFeatures, customBranding }) => ({
  customHtml: R.pipe(
    R.defaultTo(''),
    sanitizeHTML
  )(customBranding.header_html),
  customCss: R.pipe(
    R.defaultTo(''),
    R.replace(/\u003Cstyle\u003E|\u003C\/style\u003E/g, ''),
  )(customBranding.header_css),
  showCustomHeader: (R.contains('additional_advanced_branding', enabledFeatures || [])
    && !R.isEmpty(customBranding.header_html))
});

const CustomHeader = compose(
  withProps(customBranding),
  branch(R.propEq('showCustomHeader', false), renderNothing),
  setDisplayName('Header')
)((({ customHtml, customCss }) => (
  <React.Fragment>
    <style dangerouslySetInnerHTML={{ __html: customCss }}/>
    <div
      id="custom-header"
      data-qa={qaSel('custom-header')}
      className='c-custom-header'
      dangerouslySetInnerHTML={{ __html: customHtml }} />
  </React.Fragment>
)));

export default CustomHeader;
