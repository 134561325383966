exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".infl-fe__style__container___2jmbW {\n  display: flex;\n  flex: 1 1 auto;\n  align-items: center;\n  padding-top: 3px;\n}\n.infl-fe__style__iconLink___186o8 {\n  color: #6C7885;\n  margin-left: 30px;\n  position: relative;\n  display: flex;\n}\n.infl-fe__style__iconLink___186o8:visited {\n    color: #6C7885;\n  }\n.infl-fe__style__iconLink___186o8:hover, .infl-fe__style__iconLink___186o8:focus, .infl-fe__style__iconLink___186o8:active {\n    color: #232B34;\n    text-decoration: none;\n  }\n.infl-fe__style__iconLink___186o8.infl-fe__style__disabledLink___2fQmP {\n    color: #B5BBC2;\n  }\n.infl-fe__style__iconLink___186o8.infl-fe__style__disabledLink___2fQmP:hover, .infl-fe__style__iconLink___186o8.infl-fe__style__disabledLink___2fQmP:focus, .infl-fe__style__iconLink___186o8.infl-fe__style__disabledLink___2fQmP:active, .infl-fe__style__iconLink___186o8.infl-fe__style__disabledLink___2fQmP:visited {\n      color: #B5BBC2;\n      cursor: default;\n    }\n.infl-fe__style__iconStyled___23Jsc {\n  font-size: 20px;\n}\n.infl-fe__style__hidden___2M7yQ {\n  display: none;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "infl-fe__style__container___2jmbW",
	"iconLink": "infl-fe__style__iconLink___186o8",
	"disabledLink": "infl-fe__style__disabledLink___2fQmP",
	"iconStyled": "infl-fe__style__iconStyled___23Jsc",
	"hidden": "infl-fe__style__hidden___2M7yQ"
};