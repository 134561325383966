import * as R from 'ramda';
import { createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import * as actions from './actions';

const features = createReducer({
  [actions.featuresReceived]: (_, payload) => payload
}, []);

const featuresFetched = createReducer({
  [actions.featuresReceived]: R.T,
  [actions.featuresFetchFailed]: R.T,
  [actions.fetchStatusesReset]: R.F
}, false);

const challengeIds = createReducer({
  [actions.challengeIdsReceived]: (_, payload) => payload
}, []);

const challenges = createReducer({
  [actions.challengesReceived]: (_, payload) => payload
}, {});

const orderedBy = createReducer({
  [actions.orderedByReceived]: (_, payload) => payload
}, 'rank');

const challengesError = createReducer({
  [actions.challengesFetchFailed]: R.T
}, false);

const challengesFetched = createReducer({
  [actions.challengesRequested]: R.F,
  [actions.challengesReceived]: R.T,
  [actions.challengesFetchFailed]: R.T,
  [actions.fetchStatusesReset]: R.F
}, false);

export default combineReducers({
  features,
  featuresFetched,
  challengeIds,
  challenges,
  challengesError,
  challengesFetched,
  orderedBy
});
