import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@rebass/grid/emotion';
import cn from 'classnames';

import { qaSel } from '../../common/utils';
import { UnreadMarker } from '../unread-marker';

const BurgerMenu = styled(
  props => <button aria-haspopup="true" aria-expanded={props.open} {...props}>{props.children}</button>
)`
  position: relative;
  display: inline-block;
  cursor: pointer;
  background: ${props => props.theme.colors.white};
  padding: 10px 20px;
  border-radius: 0;
  border: none;
  box-shadow: none;
  &:hover, &:focus, &:active {
    box-shadow: none;
  }
`;

const BurgerBar = styled(({ className }) => <Box className={cn(className, 'c-burger-patty')} />)`
  width: 25px;
  height: 2px;
  background-color: ${props => props.open ? props.theme.colors.primary[0] : props.theme.colors.neutral[2]};
  margin: 4px 0;
  transition: 0.25s;
  border-radius: 2px;
  ${props => props.open && props.index === 1 && 'transform: rotate(-45deg) translate(-3px, 4px)'};
  ${props => props.open && props.index === 2 && 'opacity: 0;'};
  ${props => props.open && props.index === 3 && 'transform: rotate(45deg) translate(-4px, -6px)'};
`;

const StyledUnreadMarker = styled(UnreadMarker)`
  right: 14px;
  top: 4.5px;
`;

const Burger = ({ open, onClick, showNotification }) => (
  <BurgerMenu data-qa={qaSel('burger-button')} onClick={onClick} open={open} className="c-button-burger">
    <BurgerBar index={1} open={open} key="b1" />
    <BurgerBar index={2} open={open} key="b2" />
    <BurgerBar index={3} open={open} key="b3" />
    <StyledUnreadMarker show={showNotification && !open} />
  </BurgerMenu>
);

export default Burger;
