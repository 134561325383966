exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".infl-fe__style__mobileIconMenu___1_jml {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  padding: 5px 0;\n  background-color: #ffffff;\n  border-bottom: 1px solid #DADDE0;\n}\n.infl-fe__style__menuItem___QfRGh {\n  display: flex;\n  justify-content: center;\n  flex-grow: 1;\n  flex-basis: 0;\n  font-size: 24px;\n  color: #6C7885;\n  position: relative;\n}\n.infl-fe__style__bottomFixed___KCe7i {\n  position: fixed;\n  display: flex;\n  flex-direction: column-reverse;\n  width: 100%;\n  bottom: 0;\n  z-index: 1000;\n  border-bottom: none;\n}\n.infl-fe__style__bottomFixed___KCe7i .infl-fe__style__mobileIconMenu___1_jml {\n    border-top: 1px solid #DADDE0;\n    z-index: 1000;\n  }\n.infl-fe__style__isSelected___ugFG3 {\n  color: #0A5ECC;\n}\n", ""]);

// Exports
exports.locals = {
	"mobileIconMenu": "infl-fe__style__mobileIconMenu___1_jml",
	"menuItem": "infl-fe__style__menuItem___QfRGh",
	"bottomFixed": "infl-fe__style__bottomFixed___KCe7i",
	"isSelected": "infl-fe__style__isSelected___ugFG3"
};