/* eslint-disable complexity */
import React from 'react';
import * as R from 'ramda';
import { branch, renderNothing, compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import styled from '@emotion/styled';
import { Box } from '@rebass/grid/emotion';
import '@influitive/infl-fonts/fonts.css';
import i18n from 'modules/localization';
import { I18nextProvider } from 'react-i18next';
import i18nMoment from '@influitive/moment-launcher';
import { hubConfig, windowObj, getToken } from 'modules/jwt-2';
import { withCookies } from 'react-cookie';
import SecThemeProvider from '@influitive/secret-garden/lib/theme-provider';
import { DataDog } from 'modules/datadog';
import {
  checkSelectedHub,
  extractLocaleData,
  isMobileApp,
  isWhiteLabel,
  isMaven,
  shouldLoadHubSwitcher,
  updatePathMap,
  qaSel
} from './common/utils';

import { DD_APP_ID, DD_CLIENT_TOKEN, DEPLOY_ENV } from '../../src/common/environment';

import constants from './common/constants';
import AdminBar from 'modules/admin-bar';
import MainHeader from './components/main-header';
import ThemeProvider from './theme-provider';
import ContextSwitcher from './components/context-switcher';
import { isLensActive } from 'modules/lenses/api';
import {
  advocateLinks,
  customBranding,
  enabledFeatures,
  hubSwitcher,
  notificationsCount,
  permissions,
  profile,
  publicHomepageTabs,
  unseenChallengesCounts,
  updateTimezone
} from './common/api';

const fontFamily = props => (
  props.customBranding.custom_font_enabled ? `${props.customBranding.custom_font_name}, ${constants.defaultFont}` : constants.defaultFont
);

const custom = props => `
  @font-face {
    font-family: ${props.customBranding.custom_font_name};
    font-style: normal;
    src: url(${props.customBranding.custom_font_url});
  }
`;

const App = styled(Box)`
  ${props => props.customBranding.custom_font_enabled && custom(props)}
  font-family: ${fontFamily};
`;

const updateProfileEvents = [
  'influitive:stage-completed',
  'influitive:waiting-confirmation',
  'influitive:reward-redeemed',
  'influitive:claimed-todays-points'
];

const canShowMainHeader = () => !isMobileApp() || isWhiteLabel || !isMaven;

const isAdminOrCloneWithRoleManagementFeature = features =>
  (window._infl.currentContact.isAdmin || window._infl.currentContact.isClone) &&
  features.includes('role_management_restructure');

export default compose(
  withCookies,
  withState('customBranding', 'brandingReceived', {}),
  withState('i18n', 'i18nReceived', i18n({ defaultNs: 'AdvocateNavFE', ns: 'AdvocateNavFE' })),
  withState('moment', 'momentReceived', null),
  withState('profile', 'profileReceived', null),
  withState('features', 'linksReceived', []),
  withState('hubSwitcher', 'hubSwitcherReceived', null),
  withState('isAuth', 'isAuthReceived', false),
  withState('allowOpenSignUp', 'openSignUpReceived', false),
  withState('publicTabs', 'publicTabsReceived', []),
  withState('enabledFeatures', 'enabledFeaturesReceived', []),
  withState('unseenCounts', 'unseenCountsReceived', {}),
  withState('unseenNotificationsCount', 'unseenNotificationsCountReceived', 0),
  withState('notificationBarHeight', 'setNotificationBarHeight', 0),
  withState('inActiveLens', 'setInActiveLens', false),
  withState('permissions', 'permissionsReceived', null),
  withHandlers({
    handleActiveLens: ({ setInActiveLens, linksReceived }) => () => (
      isLensActive().then(inActiveLens => {
        setInActiveLens(inActiveLens);
        advocateLinks(inActiveLens).then(linksReceived);
      })
    ),
    newRoleManagementPermissionEnabled: ({ enabledFeatures, permissions }) => permission => (
      !enabledFeatures.includes('role_management_restructure') || (permissions && permissions.includes(permission))
    )
  }),
  lifecycle({
    componentDidMount() {
      hubConfig().then(config => {
        this.props.openSignUpReceived(config.hub.allowOpenSignUp);

        publicHomepageTabs().then(this.props.publicTabsReceived);
        getToken().then(() => {
          windowObj().then(({ hub }) => {
            const { lang, primaryLang } = hub;

            const moment = i18nMoment({ lang });
            this.props.momentReceived(() => moment);

            const options = { defaultNs: 'AdvocateNavFE', ns: 'AdvocateNavFE', lang };
            this.props.i18nReceived(i18n(options));

            customBranding().then(branding => {
              this.props.brandingReceived({
                ...branding,
                header_html: extractLocaleData(branding.header_html, lang, primaryLang),
                header_css: extractLocaleData(branding.header_css, lang, primaryLang),
                banner_html: extractLocaleData(branding.banner_html, lang, primaryLang),
                banner_css: extractLocaleData(branding.banner_css, lang, primaryLang)
              });
            });
            notificationsCount().then(data => this.props.unseenNotificationsCountReceived(data.count));
          });
          this.props.isAuthReceived(true);
          const updateProfile = () => profile().then(this.props.profileReceived);
          updateProfile();

          // NOTE: some of these event are fired via jQuery's `trigger` and can't be handled with `addEventListener`
          // see https://github.com/jquery/jquery/issues/3347
          updateProfileEvents.forEach(event => $(window).on(event, updateProfile)); // eslint-disable-line no-undef

          shouldLoadHubSwitcher && hubSwitcher().then(hubs => {
            this.props.hubSwitcherReceived(hubs);
            unseenChallengesCounts(
              R.pluck('org_id',
                R.reject(checkSelectedHub, R.path(['family', 'companies'], hubs)))
            ).then(challengesCounts => {
              this.props.unseenCountsReceived(challengesCounts);
            });
          });
          // this is a temporary measure to always attempt to keep timezone in sync on-load
          updateTimezone();
        }).catch(err => {
          if (err.response.status === 401) {
            this.props.isAuthReceived(false);

            const lang = this.props.cookies.get('lang');

            const moment = i18nMoment({ lang });
            this.props.momentReceived(() => moment);

            const options = { defaultNs: 'AdvocateNavFE', ns: 'AdvocateNavFE', lang };
            this.props.i18nReceived(i18n(options));

            customBranding().then(branding => {
              this.props.brandingReceived({
                ...branding,
                header_html: extractLocaleData(branding.header_html),
                header_css: extractLocaleData(branding.header_css),
                banner_html: extractLocaleData(branding.banner_html),
                banner_css: extractLocaleData(branding.banner_css)
              });
            });
          }
        });
        enabledFeatures().then(features => {
          R.contains('discourse_homepage', features) && updatePathMap('forum', 'home');
          R.contains('mountain_dew', features) && updatePathMap('', 'challenges');
          this.props.enabledFeaturesReceived(features);
          isAdminOrCloneWithRoleManagementFeature(features) && permissions().then(this.props.permissionsReceived);
        });
        this.props.handleActiveLens();
      }).catch(() => {
        this.props.isAuthReceived(false);
      });
    },
    componentWillUnmount() {
      updateProfileEvents.forEach(event => $(window).off(event)); // eslint-disable-line no-undef
    }
  }),
  withProps(({ enabledFeatures, newRoleManagementPermissionEnabled, inActiveLens, profile }) => ({
    hasCorporateAccessPermission: enabledFeatures && (newRoleManagementPermissionEnabled('corporate_access')),
    hasViewLensPermission: enabledFeatures && (newRoleManagementPermissionEnabled('view_lens')),
    isAdminOrClone: profile?.attributes?.type === 'Corporate' || inActiveLens
  })),
  withProps(({ enabledFeatures, isAdminOrClone, hasCorporateAccessPermission }) => ({
    showContextSwitcher: enabledFeatures && isAdminOrClone && hasCorporateAccessPermission
  })),
  withProps(({ isAdminOrClone, hasViewLensPermission, hasCorporateAccessPermission }) => ({
    showAdminBar: (hasViewLensPermission || hasCorporateAccessPermission) && isAdminOrClone
  })),
  branch(({ customBranding }) => R.isEmpty(customBranding), renderNothing)
)(({ i18n, customBranding, enabledFeatures, showContextSwitcher, showAdminBar,
  hasCorporateAccessPermission, ...rest
}) => {
  return (
    <I18nextProvider i18n={i18n}>
      <App data-qa={qaSel('app')} id="ADVOCATE-NAV" customBranding={customBranding}>
        {window?._infl?.hub?.features?.some(x => x === 'complete_tracing_enabled') && (
          <DataDog
            config={{
              service: 'infl-fe',
              applicationId: DD_APP_ID,
              clientToken: DD_CLIENT_TOKEN,
              env: {
                production: 'prod',
                staging: 'staging',
                development: 'development'
              }[DEPLOY_ENV],
              allowedTracingUrls: [
                url => url.startsWith(window.location.origin),
                'https://api.influitive.com',
                'https://api.influitives.com'
              ],
              excludedActivityUrls: [
                `${window.location.origin}/forum/message-bus/*`,
                `${window.location.origin}/xhr_send*`,
                `${window.location.origin}/_challenge_preview*`,
                `wss://${window.location.origin}/graphql`,
                'wss://chat.stream-io-api.com/connect*'
              ]
            }}
          />
        )}
        <SecThemeProvider>
          {showAdminBar ?
            <AdminBar inMemberView={true} hasCorporateAccessPermission={hasCorporateAccessPermission} dataSelector="ADVOCATE-NAV-FE" {...rest} /> :
            showContextSwitcher && <ContextSwitcher {...rest} />}
        </SecThemeProvider>
        <ThemeProvider customBranding={customBranding}>
          {canShowMainHeader() &&
            <MainHeader
              customBranding={customBranding}
              enabledFeatures={enabledFeatures}
              {...rest}
            />}
        </ThemeProvider>
      </App>
    </I18nextProvider>
  );
});
