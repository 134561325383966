import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from '@emotion/styled';
import Tooltip from '@influitive/secret-garden/lib/tooltip';
import Link from '@influitive/secret-garden/lib/link';
import { Box } from '@influitive/secret-garden/lib/flex';
import Icon from '@influitive/secret-garden/lib/icon';

const StyleIcon = styled.span`
    margin-left: ${props => props.theme.spacing[2]}px;
    margin-top: -${props => props.theme.spacing[9]}px;
    line-height: 0;
`;

export default () => {
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  return (
    <React.Fragment>
      <StyleIcon data-tip data-for='info-bar-tip' data-qa='tip-icon'>
        <Icon fill='white' icon='question-circle' width='16px' />
      </StyleIcon>
      <Tooltip
        id='info-bar-tip'
        type='light'
        place={isTablet && !isMobile ? 'left' : 'bottom'}
        delayHide={isMobile ? 0 : 1000}
        clickable={true}
        zIndex={1002}>
        <React.Fragment>
          <Box data-qa='tip-text' maxWidth='300' width={1} mb={2}>
            This is a copied view of your member as of the time the Lens was loaded. Anything you do in this
            mode will not affect the member's account, and their challenges, points, etc., will remain untouched.
          </Box>
          <Link
            href="https://support.influitive.com/article/767-lenses"
            target="_blank"
            rel="noopener noreferrer"
            data-qa='learn-more'
            title="Learn more about how lenses work">
            Learn More
          </Link>
        </React.Fragment>
      </Tooltip>
    </React.Fragment>
  );
};
