import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import styled from '@emotion/styled';

import Badges from './badges';

const Wrapper = styled.div`
  color: ${props => props.theme.colors.neutral[3]};
  border-bottom: 2px solid ${props => props.theme.colors.neutral[5]};
  padding-bottom: 20px;
`;

const Header = styled.p`
  margin: 0px 0px 1rem 0px;
  font-size: 1.1rem;
  color: ${props => props.theme.colors.neutral[3]};
`;

const BadgeList = ({ id, badges, t }) => (
  R.isEmpty(badges)
    ? null
    : <Wrapper className='c-badges-list'>
      <Header className='c-badges-header'>
        {t('Below are the badges and levels that you can achieve. Click a badge to see more details.')}
      </Header>
      <Badges id={id} badges={badges} />
    </Wrapper>
);

BadgeList.propsTypes = {
  id: PropTypes.string.isRequired,
  badges: PropTypes.array.isRequired
};

export default withNamespaces()(BadgeList);
