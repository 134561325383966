import { consentPage, corporatePage, advocateConsent } from './common/utils';

import 'modules/advocate-nav';
import 'modules/challenges-carousel';
import 'modules/delete-members';
import 'modules/badges-progress';
import 'modules/froala-editor/load';
import 'modules/profile-image-uploader/load';
import 'modules/file-uploader/load';
import 'modules/momentjs/load';

import 'modules/page-events-tracker';

document.getElementById('infl-fe-contact-admin') && import('modules/contact-admin');

// document.getElementById('infl-fe-activity-feed') && import('modules/activity-feed');

if (!consentPage()) {
  import('modules/filestack/load');

  if (corporatePage()) {
    import('modules/level-form');
    import('modules/targeting-editor');
    import('modules/role-management/add-role');
    import('modules/role-management/edit-role');
    import('modules/mountain-dew/challenge-card-preview');
    import('modules/code-editor/load');
    import('modules/stage-image-uploader/load');
    import('modules/help-box/load');
    import('modules/admin-discussion-categories/load');
    import('modules/toastify-notification');
    import('modules/legal-settings/load');
    import('modules/rewrite-challenge-preview/load');
    document.getElementById('infl-fe-admin-nav') && import('modules/admin-nav');
    document.getElementById('infl-fe-advocate-consent-settings') && import('modules/advocate-consent-settings');
    document.getElementById('infl-fe-onboarding') && import('modules/onboarding-settings');
    document.getElementById('infl-fe-advocate-table') && import('modules/advocate-table');
    document.getElementById('infl-fe-campaigns') && import('modules/campaigns');
    document.getElementById('infl-fe-bounties') && import('modules/bounties');
    document.getElementById('infl-fe-hub-switcher') && import('modules/hub-switcher');
    document.getElementById('infl-fe-message-settings') && import('modules/message-settings');
    document.getElementById('infl-fe-multilingual') && import('modules/multilingual');
    document.getElementById('infl-fe-roi-settings') && import('modules/roi-settings');
    document.getElementById('infl-fe-roi-table') && import('modules/roi-view');
    document.getElementById('infl-fe-streaker-settings') && import('modules/streaker-settings');
    document.getElementById('infl-fe-advocacy-picker') && import('modules/advocacy-picker');
    document.getElementById('infl-fe-admin-question-challenge') && import('modules/admin-question-challenge');
    document.getElementById('infl-fe-digest-email-settings') && import('modules/digest-email-settings');
    document.getElementById('infl-fe-inactive-email-settings') && import('modules/inactive-email-settings');
    document.getElementById('infl-fe-hub-dashboards') && import('modules/hub-dashboards');
    document.getElementById('infl-fe-hub-reports') && import('modules/hub-reports');
    document.getElementById('infl-fe-user-management-table') && import('modules/user-management');
    document.getElementById('infl-fe-group-targeting') && import('modules/group-targeting');
    document.getElementById('infl-fe-challenge-notification') && import('modules/challenge-notification');
    document.getElementById('infl-fe-challenge-reminder-form') && import('modules/challenge-reminder-form');
    document.getElementById('infl-fe-challenge-reminder-settings') && import('modules/challenge-reminder-settings');
  } else {
    import('modules/toastify-notification');
    import('modules/expired-referral-campaign/load');
    import('modules/mountain-dew');
    import('modules/leaderboard');
    import('modules/custom-leaderboard');
    import('modules/achievements-tile');
    import('modules/advocate-challenge-onboarding');
    import('modules/referral-challenge');
    import('modules/challenge-suggester/load');
    document.getElementById('infl-fe-advocate-notifications') && import('modules/advocate-notifications');
    document.getElementById('infl-fe-challenge-player-loader') && import('modules/challenge-player-loader');
    document.getElementById('infl-fe-profile') && import('modules/profile');
    (document.getElementById('infl-fe-streaker-mobile')
      || document.getElementById('infl-fe-streaker-desktop')) && import('modules/streaker');
  }
}

if (advocateConsent()) {
  import('modules/mountain-dew/advocate-consent');
}
