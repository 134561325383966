import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const Link = styled('a')`
  ${props => css`
    font-size: ${props.theme.fonts.size[3]}px;
    font-weight: ${props.selected ? props.theme.fonts.weight[3] : props.theme.fonts.weight[1]};
    text-decoration: none;
    color: ${props.selected ? props.theme.colors.primary[0] : props.theme.colors.neutral[1]};
    white-space: nowrap;

    &:visited {
      color: ${props.selected ? props.theme.colors.primary[0] : props.theme.colors.neutral[1]};
    }
    &:focus, &:active, &:hover {
      text-decoration: none;
      color: ${props.theme.colors.primary[0]};
    }
  `}
  ${props => props.disabled && css`
    color: ${props.theme.colors.neutral[3]};

    &:focus, &:active, &:hover, &:visited {
      color: ${props.theme.colors.neutral[3]};
      cursor: default;
    }
  `}
`;

export const PlaceholderLink = styled('span')`
  display: inline-block;
  width: 55px;
  height: 1em;
  background-color: ${props => props.theme.colors.neutral[4]};
`;
