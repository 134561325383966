import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import ThemeProvider from '@influitive/secret-garden/lib/theme-provider';
import { I18nextProvider } from 'react-i18next';

import badgesReducer from './reducers/badges';
import Badges from './containers/badges';
import i18n from './localization';

let composeEnhancers = compose;
if (process.env.NODE_ENV !== 'production') {
  composeEnhancers = composeWithDevTools({ name: 'BADGES PROGRESS' });
}

let store = createStore(
  badgesReducer,
  composeEnhancers(
    applyMiddleware(thunk)
  )
);

const load = contactUUID => document.getElementById('infl-fe-badges-and-levels') && render(
  <Index id={contactUUID} />,
  document.getElementById('infl-fe-badges-and-levels')
);

window.inflLoader = window.inflLoader || {};
window.inflLoader = { ...window.inflLoader, loadBadgesAndLevels: load };

const Index = ({ ...props }) => (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={window._inflTheme}>
        <Badges {...props} />
      </ThemeProvider>
    </I18nextProvider>
  </Provider>
);

Index.propsTypes = {
  id: PropTypes.string.isRequired
};

export default Index;
