import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, setDisplayName, lifecycle, branch, renderComponent, withHandlers, withState } from 'recompose';
import { ChallengeExperienceLayoutPreloader } from '@influitive/secret-garden/lib/challenge-experience-layout';
import * as actions from  '../../store/reducer/actions';
import * as selectors from '../../store/reducer/selectors';
import Carousel from '../carousel';
import ErrorMessage from '../error-message';
import ChallengeItem from '../challenge-item';
import ExperienceItem from '../experience-item';
import ChannelItem from '../channel-item';
import ModuleEvent from 'modules/module-event';

const isChallenge = R.propEq('type', 'Challenge');

const hasChallenges = R.propSatisfies(R.gt(R.__, 0), 'total_challenge_count');

const filterChallenges = R.filter(R.either(isChallenge, hasChallenges));

const checkType = type => R.pipe(R.prop('type'), R.equals(type));

const countChallenges = challenges => R.length(
  R.filter(R.complement(R.propEq('type', 'Preloader')))(challenges)
);

export const withChallenges = compose(
  connect(state => ({
    features: selectors.featuresSelector(state),
    featuresFetched: selectors.featuresFetchedSelector(state),
    challengeIds: selectors.challengeIdsSelector(state),
    challenges: selectors.challengesSelector(state),
    challengesError: selectors.challengesErrorSelector(state),
    challengesFetched: selectors.challengesFetchedSelector(state)
  }), actions),
  branch(
    R.propSatisfies(R.equals(true), 'challengesError'),
    renderComponent(() => <ErrorMessage errorText="Couldn't load challenges" />)
  )
);

export default compose(
  withState('filteredChallenges', 'setFilteredChallenges', [
    { type: 'Preloader', uuid: 'challenge-preloader-1' },
    { type: 'Preloader', uuid: 'challenge-preloader-2' },
    { type: 'Preloader', uuid: 'challenge-preloader-3' }
  ]),
  withChallenges,
  withHandlers({
    handleChallengesFetch: ({ setFilteredChallenges, challenges, challengeIds, onNoChallenges }) => () => {
      const filteredChallenges = filterChallenges(R.map(R.prop(R.__, challenges), challengeIds));
      setFilteredChallenges(filteredChallenges);
      !countChallenges(filteredChallenges) && onNoChallenges();
    },
    markAsSeen: ({ filteredChallenges, setFilteredChallenges }) => challenge => {
      const challenges = filteredChallenges.map(filteredChallenge =>
        challenge.id === filteredChallenge.id
          ? { ...filteredChallenge, unseen: false }
          : filteredChallenge
      );

      setFilteredChallenges(challenges);
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.resetFetchStatuses();
      this.props.fetchFeatures();
      window.addEventListener('challenge-player:modal:challenge-state-change', this.props.fetchChallenges);
      setTimeout(() => {
        ModuleEvent('CHALLENGES_CAROUSEL', 'LOADED');
      }, 1000);
    },
    componentWillUnmount() {
      window.removeEventListener('challenge-player:modal:challenge-state-change', this.props.fetchChallenges);
    },
    /* eslint-disable complexity */
    componentDidUpdate(prevProps) {
      const { featuresFetched, challengesFetched, fetchChallenges, handleChallengesFetch } = this.props;
      if (!prevProps.featuresFetched && featuresFetched) fetchChallenges();
      !prevProps.challengesFetched && challengesFetched && handleChallengesFetch();
    }
    /* eslint-enable complexity */
  }),
  setDisplayName('ChallengesList')
)(({ filteredChallenges, onChallengeOpen, markAsSeen }) => (
  <Carousel>
    {filteredChallenges.map(entity => (
      R.cond([
        [checkType('Challenge'), entity => <ChallengeItem key={entity.uuid} challenge={entity} onChallengeOpen={onChallengeOpen} markAsSeen={markAsSeen} />],
        [checkType('Experience'), entity => <ExperienceItem key={entity.uuid} experience={entity} />],
        [checkType('Channel'), entity => <ChannelItem key={entity.uuid} channel={entity} />],
        [checkType('Preloader'), () => <ChallengeExperienceLayoutPreloader key={entity.uuid} />],
        [R.T, R.always(null)]
      ])(entity)
    ))}
  </Carousel>
));
