import React, { useReducer, useEffect, useRef } from 'react';
import * as R from 'ramda';
import debounce from 'lodash.debounce';
import { createAction, createReducer } from 'redux-act';
import { withNamespaces } from 'react-i18next';
import Button from '@influitive/secret-garden/lib/button';

import Badge, { badgeFullWidth } from 'modules/badge-popover';
import NoLevelHeader from './no-level-header';
import { fetchBadgeProgress } from '../actions/badges';

const initialRowsCount = 3;
const setWidth = createAction('set component width');
const setViewAll = createAction('view all badges');

const reducer = createReducer({
  [setWidth]: (state, width) => {
    if (width && !state.isViewAll) {
      const badgesToRender = Math.floor(width / badgeFullWidth) * initialRowsCount;
      const isMoreBadges = state.badges.length > badgesToRender;

      return {
        ...state,
        isMoreBadges,
        badgesToRender: R.take(badgesToRender, state.badges)
      };
    }

    return state;
  },
  [setViewAll]: state => ({
    isViewAll: true,
    isMoreBadges: false,
    badgesToRender: state.badges
  })
});

const initialState = {
  width: null,
  isViewAll: null,
  isMoreBadges: false,
  badgesToRender: []
};

export default withNamespaces()(R.pipe(
  (({ badges, ...props }) => {
    const [state, dispatch] = useReducer(reducer, { ...initialState, badges });
    const noLevelRef = useRef(null);

    useEffect(() => {
      const handleResize = () => {
        dispatch(setWidth(noLevelRef.current.getBoundingClientRect().width));
      };
      handleResize();

      window.addEventListener('resize', debounce(handleResize, 200));

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {
      ...state,
      ...props,
      noLevelRef,
      dispatch
    };
  }),
  (({ id, level, noLevelRef, badgesToRender, isMoreBadges, dispatch, t }) =>
    <React.Fragment>
      <div ref={noLevelRef}>
        <NoLevelHeader level={level} />
        {badgesToRender.map(badge =>
          <Badge contactUUID={id} key={badge.id} badge={badge} fetchBadgeProgress={fetchBadgeProgress} />
        )}
      </div>
      {isMoreBadges && <Button variant="link" onClick={R.compose(dispatch, setViewAll)} data-qa="view-all">{t('View all')}</Button>}
    </React.Fragment>
  )
));

