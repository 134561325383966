import React from 'react';
import { I18nextProvider } from 'react-i18next';
import ThemeProvider from '@influitive/secret-garden/lib/theme-provider';

import App from './components';
import i18n from './localization';

export default props =>
  <ThemeProvider useBase={false}>
    <I18nextProvider i18n={i18n}>
      <App {...props} />
    </I18nextProvider>
  </ThemeProvider>;
