import * as R from 'ramda';
import axios from 'axios';
import jwt from 'modules/jwt';

import memo from 'memoizee';
import { windowObj } from 'modules/jwt-2';

const hubId = (typeof window === 'undefined') ? undefined : R.path(['_infl', 'hub', 'id'], window) || 'fakehubidfortest';
const csrfToken = (document.querySelector('meta[name=\'csrf-token\']') || {}).content;
const lang = (typeof window === 'undefined') ? undefined : R.path(['_infl', 'hub', 'lang'], window);

const api = jwt(axios.create({
  baseURL: process.env.HUB_API_URL,
  headers: { 'X_ORG_ID': hubId, 'X-CSRF-Token': csrfToken, 'Accept-Language': lang },
  withCredentials: true
}), axios);

export const customBranding = memo(() =>
  windowObj().then(({ branding }) =>
    branding.serializedBrandingUrl
      ? axios.get(branding.serializedBrandingUrl).then(R.prop('data'))
      : axios.get('/api/branding').then(R.path(['data', 'data'])))
);

export const getProfileFields = memo(() => api.get('/profile_field_data'));
