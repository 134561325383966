import axios from 'axios';
import * as R from 'ramda';
import Pusher from 'pusher-js';
import jwt from 'modules/jwt';

const unnest = R.path(['data', 'data']);

const createAxios = ({ url, hubId }) => jwt(axios.create({
  baseURL: url,
  headers: { 'X_ORG_ID': hubId },
  withCredentials: true
}), axios);

const createFetcherWithConfig = url => () => new Promise(resolve => {
  axios.get('/api/company_config').then(config => {
    const hubConfig = unnest(config);
    const tokenUrl = hubConfig.hub.tokenUrl;

    axios.post(tokenUrl, null, {
      withCredentials: true,
      headers: { 'Accept': 'application/jwt', 'X-Requested-With': 'XMLHttpRequest' }
    }).then(() => {
      jwt(axios.create({
        baseURL: hubConfig.api.url,
        headers: { 'X_ORG_ID': hubConfig.hub.id },
        withCredentials: true
      }), axios, tokenUrl)(url).then(resolve);
    });
  });
});

const createPusher = ({ url, hubId, authEndpoint, authArgs, key, cluster }) => {
  const post = createAxios({ url, hubId }).post;

  return new Pusher(key, {
    cluster,
    authEndpoint,
    encrypted: true,
    authorizer: (channel, options) => ({
      authorize: (socketId, callback) => {
        post(options.authEndpoint, ...authArgs(channel, socketId))
          .then(({ data }) => callback(false, data));
      }
    })
  });
};

const subscribeFactory = ({ apiUrl, hubId }) => (notificationHandler, toastHandler, userId) => {
  try {
    const hedwigPusher = createPusher({
      url: `${apiUrl}/notifications`,
      hubId,
      authEndpoint: '/auth/pusher',
      authArgs: (channel, socketId) => [{}, { headers: { 'X-CHANNEL': channel.name, 'X-SOCKET-ID': socketId }, withCredentials: true }],
      key: process.env.HEDWIG_PUSHER_KEY,
      cluster: process.env.HEDWIG_PUSHER_CLUSTER
    });

    const globalHubChannel = hedwigPusher.subscribe(`private-hub-notification-.${hubId}`);
    globalHubChannel.bind('notifications', notificationHandler);
    toastHandler && globalHubChannel.bind('client-toast', toastHandler);

    if (userId) {
      const hubPusher = createPusher({
        url: apiUrl,
        hubId,
        authEndpoint: '/pusher/auth',
        authArgs: (channel, socketId) => [{ 'channel_name': channel.name, 'socket_id': socketId }, { withCredentials: true }],
        key: process.env.HUB_PUSHER_KEY,
        cluster: process.env.HUB_PUSHER_CLUSTER
      });

      const userChannel = hubPusher.subscribe(`private-user-${userId}`);
      userChannel.bind('notifications', notificationHandler);
    }
  } catch (e) {
    console.error(e);
  }
};

const notifyFactory = ({ apiUrl, hubId }) => {
  try {
    const hedwigPusher = createPusher({
      url: `${apiUrl}/notifications`,
      hubId,
      authEndpoint: '/auth/pusher',
      authArgs: (channel, socketId) => [{}, { headers: { 'X-CHANNEL': channel.name, 'X-SOCKET-ID': socketId }, withCredentials: true }],
      key: process.env.HEDWIG_PUSHER_KEY,
      cluster: process.env.HEDWIG_PUSHER_CLUSTER
    });

    const globalHubChannel = hedwigPusher.subscribe(`private-hub-notification-.${hubId}`);

    return ({ text, type, link }) => {
      return globalHubChannel.trigger('client-toast', { text, type, link });
    };
  } catch (e) {
    console.error(e);
  }
};

const cancelJobFactory = ({ apiUrl, hubId }) => id => {
  createAxios({ url: `${apiUrl}/notifications`, hubId }).post('/cancel', { id });
  createAxios({ url: apiUrl, hubId }).post('/cancel', { id });
};

// create api for passing in apiUrl and hubId and pusher details
/* eslint complexity: 0 */
export const create = ({
  apiUrl = process.env.HUB_API_URL,
  hubId = (typeof window === 'undefined') ? undefined : R.path(['_infl', 'hub', 'id'], window)
} = {}) => {
  const getNotifications = apiUrl && hubId
    ? createAxios({ url: `${apiUrl}/notifications`, hubId }).get
    : createFetcherWithConfig('/notifications');

  return {
    notifications: getNotifications,
    cancelJob: cancelJobFactory({ apiUrl, hubId }),
    subscribe: subscribeFactory({ apiUrl, hubId }),
    notify: notifyFactory({ apiUrl, hubId })
  };
};

// static import style
const api = create();
export const notifications = api.notifications;
export const subscribe = api.subscribe;
export const notify = api.notify;
export const cancelJob = api.cancelJob;
export default { notifications, subscribe, notify, cancelJob };
