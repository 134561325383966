import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import styled from '@emotion/styled';
import Heading from '@influitive/secret-garden/lib/heading';
import { fadeOffset } from '../carousel';
import * as selectors from '../../store/reducer/selectors';

const StyledHeading = styled(Heading)`
  ${props => `
    position: relative;
    display: inline-flex;
    z-index: 1;
    color: ${props.theme.colors.neutral[0]};
    margin-bottom: ${props.theme.spacing[3]}px;
    font-size: ${props.theme.fonts.size[3]}px;
    line-height: ${props.theme.fonts.lineHeight[7]}px;

    &:focus, &:hover, &:active {
      text-decoration: none;
    }

    @media (min-width: ${props.theme.sizes.tablet}px) {
      font-size: ${props.theme.fonts.size[4]}px;
      line-height: ${props.theme.fonts.lineHeight[8]}px;
    }
    @media (min-width: ${props.theme.sizes.desktop}px) {
      font-size: ${props.theme.fonts.size[5]}px;
      line-height: ${props.theme.fonts.lineHeight[9]}px;
    }
    @media (min-width: 1025px) and (max-width: 1200px) {
      margin-left: calc((100vw - 1200px) * -0.5);
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      margin-left: ${fadeOffset(1, 'tablet-l')(props) - 8}px;
    }
  `}
`;

export default compose(
  withNamespaces(),
  connect(state => ({
    orderedBy: selectors.orderedBySelector(state)
  })),
  setDisplayName('ChallengesHeadingLink')
)(({ t, orderedBy }) => (
  <StyledHeading level={3} as="h2">
    {orderedBy === 'rank' ? t('Recommended For You') : t('New')}
  </StyledHeading>
));
