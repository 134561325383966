import React from 'react';
import { Box } from '@influitive/secret-garden/lib/flex';
import { compose, setDisplayName, withProps } from 'recompose';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import Icon from '@influitive/secret-garden/lib/icon';
import Text from '@influitive/secret-garden/lib/text';

import { lensStates } from '../constants';

const StyledText = styled(Text)`
  line-height: 1.5;
  white-space: nowrap;
`;

export default compose(
  withTheme,
  withProps(({ lensState, theme }) => ({
    lensIcon: lensState === lensStates.ACTIVE
      ? { icon: 'lens-active', fill: theme.colors.accent[0] }
      : { icon: 'lens', fill: 'white' }
  })),
  setDisplayName('LensTitle')
)(({ lensIcon }) => (
  <React.Fragment>
    <Icon {...lensIcon} width="30px" />
    <Box mr={2} ml={2}><StyledText variant="small" color="white">View Lens</StyledText></Box>
  </React.Fragment>
));
