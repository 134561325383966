import { Any, All, Not, Field, Group, ChallengeCompleted, RedeemedReward, BadgeEarned, JoinCode } from '../types';

export const all = list => All({ $all: list });
export const any = list => Any({ $any: list });
export const not = aql => Not({ $not: aql });

export const hubId = id => Field({
  field: 'hub_id',
  operator: '$exact',
  string_value: id
});

export const group = id => Group({
  group_id: id
});

export const challengeCompleted = id => ChallengeCompleted({
  challenge_completed_id: id
});

export const redeemedReward = id => RedeemedReward({
  reward_redeemed_id: id
});

export const badgeEarned = id => BadgeEarned({
  badge_earned_id: id
});

export const joinCode = code => JoinCode({
  join_code: code
});

export const engagedSince = ms => Field({
  field: 'last_engaged_at',
  operator: '$after',
  milliseconds_ago_value: ms
});
