import React from 'react';
import styled from '@emotion/styled';
import { compose, lifecycle, withState, withHandlers } from 'recompose';
import cn from 'classnames';

import { qaSel } from '../../common/utils';
import styles from './style.css';
import { withNamespaces } from 'react-i18next';
import { Link } from '../links/style.js';
import { onDiscoursePageChange, isCurrPageWithOverrides } from '../../common/discourse-utils';
import ContentSectionsDropDown from '../links/content-sections-dropdown';

const SessionLink = styled('a')`
  display: inline-block;
  padding: 5px 16px;
  background: ${props => props.theme.colors.primary[0]};
  border-radius: 4px;
  color: white !important;
  font-weight: 400 !important;
  color: white;
  margin-left: 10px;
  font-size: ${props => props.theme.fonts.size[3]}px !important;
  font-weight: ${props => props.theme.fonts.weight[1]} !important;
`;

const AnonNav = compose(
  withState('currentPageOverride', 'setCurrentPageOverride', {}),
  withState('visibleLinks', 'setVisibleLinks', []),
  withState('dropDownLinks', 'setDropDownLinks', []),
  withHandlers({
    onDiscourseEvent: ({ setCurrentPageOverride, publicTabs }) => e => {
      setCurrentPageOverride(onDiscoursePageChange(e, publicTabs, '/forum/c/'));
    },
    resizeHandler: ({ publicTabs, setVisibleLinks, setDropDownLinks }) => () => {
      const topbarWidth = document.getElementById('topbar').offsetWidth;
      const index = (topbarWidth - 300) / 100 | 0;

      setVisibleLinks(publicTabs.slice(0, index));
      setDropDownLinks(publicTabs.slice(index));
    }
  }),
  lifecycle({
    componentDidMount() {
      document.addEventListener('discourse-page-change', this.props.onDiscourseEvent);
      document.addEventListener('discourse-parent-category-page-change', this.props.onDiscourseEvent);

      const event = new Event('nav-loaded', { bubbles: true });
      document.getElementById('nav-container').dispatchEvent(event);

      this.props.resizeHandler();
      window.addEventListener('resize', this.props.resizeHandler);
    },
    componentWillUnmount() {
      document.removeEventListener('discourse-page-change', this.props.onDiscourseEvent);
      document.removeEventListener('discourse-parent-category-page-change', this.props.onDiscourseEvent);

      window.removeEventListener('resize', this.props.resizeHandler);
    }
  })
)(({ visibleLinks, dropDownLinks, currentPageOverride, allowOpenSignUp, t }) => (
  <div id="nav-container" className={cn(styles.container, 'c-nav-container', 'c-anon-nav')}>
    <div id="topbar" className={cn(styles.topBar, 'c-top-bar')}>
      <div className={cn(styles.publicTabs, 'c-public-tabs')}>
        {visibleLinks.map((tab, i) => (
          <div key={i} className={cn(styles.tabWrapper, 'c-tab-wrapper')}>
            <Link
              className="c-public-community-tab"
              data-qa={qaSel(`public-community-tab-${i}`)}
              href={tab.path}
              selected={isCurrPageWithOverrides(
                currentPageOverride[tab.path],
                Object.keys(currentPageOverride).length !== 0,
                tab.type
              )}>
              {tab.name}
            </Link>
          </div>
        ))}

        {dropDownLinks && dropDownLinks.length > 0 && <ContentSectionsDropDown
          sectionsForDrownDown={dropDownLinks}
          currentPageOverride={currentPageOverride}>
        </ContentSectionsDropDown>}
      </div>
      <div className={cn(styles.sessionLinksContainer, 'c-session-links-container')}>
        {allowOpenSignUp && <SessionLink
          data-qa={qaSel('sign-up')}
          className="c-nav-item c-sign-up"
          href='/users/sign_up'>{t('Sign Up')}</SessionLink>}
        <SessionLink
          data-qa={qaSel('sign-in')}
          className="c-nav-item c-sign-in"
          href='/users/sign_in'>{t('Log In')}</SessionLink>
      </div>
    </div>
  </div>
));

export default withNamespaces()(AnonNav);
