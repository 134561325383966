import * as R from 'ramda';
import { lifecycle } from 'recompose';
import ModuleEvent from 'modules/module-event';
import { mapProps } from 'recompose';
import DOMPurify from 'dompurify/dist/purify.es.js';
import * as AQL from 'modules/aql-gen';

export const qaSel = sel => `ADVOCATE-NAV-FE_${sel}`;

/* eslint complexity: 0 */
export const isMobileApp = () => {
  const userAgent = window.navigator.userAgent;
  if (userAgent.includes('Android') && userAgent.includes('Version/')) return true;
  else if (/iP(ad|hone|od)/i.test(userAgent) && !userAgent.includes('Safari')) return true;
  return false;
};

const bodyClasses = document.getElementsByTagName('body')[0].className;
const rootClasses = document.documentElement.className;
const LOCALE_LAST_RESORT = 'en';

export const isMaven = R.contains('maven', window.navigator.userAgent);
export const isWhiteLabel = bodyClasses.includes('white-label') || rootClasses.includes('white-label') || window.navigator.userAgent.includes('white-label');
export const isGatewayApp = bodyClasses.includes('gateway-mobile') || rootClasses.includes('gateway-mobile') || window.navigator.userAgent.includes('gateway-mobile');

const currPath = R.head(window.location.pathname.substr(1).split('/'));
const pathMap = {
  '': 'home',
  'challenges': 'challenges',
  'forum': 'discussions',
  'refer': 'referrals',
  'rewards': 'rewards',
  'leaderboard': 'leaderboard',
  'activity_feed': 'activity_feed',
  'inbox': 'messages',
  'feedbacks': 'feedback',
  'mobile_notifications': 'notifications'
};

export const updatePathMap = (key, value) => pathMap[key] = value;

export const currPage = () => pathMap[currPath];
export const isCurrPage = type => R.equals(currPage(), type);

export const logoAlt = profile => (profile?.attributes.company) ? `${profile.attributes.company} logo` : 'Logo';

export const isLinkEnabled = (features, item) => R.any(R.propEq('type', item.type))(features);

export const shouldLoadHubSwitcher = R.either(() => !isWhiteLabel, () => isGatewayApp);

export const showHubSwitcher = R.allPass([
  R.identity,
  () => shouldLoadHubSwitcher(),
  R.either(
    R.pipe(
      R.path(['family', 'companies']),
      R.complement(R.empty)
    ),
    R.has('others'))
]);

export const checkSelectedHub = R.pipe(
  R.prop('host_names'),
  R.contains(window.location.host)
);

export const sumUnseenCounts = R.pipe(
  R.pluck('count'),
  R.values,
  R.sum
);

export const extractLocaleData = (multilingualData, lang, primaryLang) => {
  if (multilingualData instanceof Object) {
    return (
      multilingualData[lang] ||
      multilingualData[primaryLang] ||
      multilingualData[LOCALE_LAST_RESORT] ||
      ''
    );
  }

  return multilingualData || '';
};

export const withLoadedEvent = lifecycle({
  componentDidMount() {
    ModuleEvent('ADVOCATE_NAV', 'LOADED');
  }
});

export const omitProps = R.compose(mapProps, R.omit);

const config = { ADD_ATTR: ['target'] };

export const sanitizeHTML = dirty => DOMPurify.sanitize(dirty, config);

const makeGroup = groupId => groupId ? [{ group_id: groupId }] : [];
export const convertTermToAql = (term, groupId) => AQL.all([
  AQL.any([
    AQL.Field({
      field: 'name',
      operator: '$substring',
      string_value: term
    }),
    AQL.Field({
      field: 'email',
      operator: '$substring',
      string_value: term
    }),
    AQL.Field({
      field: 'title',
      operator: '$substring',
      string_value: term
    }),
    AQL.Field({
      field: 'company',
      operator: '$substring',
      string_value: term
    })
  ]),
  AQL.any(makeGroup(groupId))
]);
