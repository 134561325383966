export const lensStates = {
  LOADING: 'loading',
  LOADED: 'loaded',
  ACTIVE: 'active',
  CLEARED: 'cleared',
  CREATE_ERROR: 'createError',
  CLEAR_ERROR: 'clearError',
  REFRESH_ERROR: 'refreshError',
  ACTIVE_ERROR: 'activeError',
  CANCEL_ERROR: 'cancelError'
};
