import { camelCase, snakeCase } from 'change-case';
import * as R from 'ramda';
import { compose, lifecycle, withState } from 'recompose';

export const advocateConsent = () => window.location.pathname === '/advocate_consent';

export const consentPage = () => window.location.pathname === '/corporate/consent'
  || advocateConsent();

/* eslint-disable complexity */
export const corporatePage = () => window.location.pathname.includes('corporate')
  || window.location.pathname.includes('/influitive/companies')
  || window.location.pathname.includes('forum/review')
  || window.location.pathname.includes('forum/admin')
  || window.location.host.startsWith('admin.');
/* eslint-enable complexity */

const isFeatureEnabled = feature => R.pathOr([], ['_infl', 'hub', 'features'], window || {}).includes(feature);

export const updatingStatusChipsNamesAndColors = isFeatureEnabled('updating_status_chips_names_and_colors');
export const uxTeamEmailDigestPreviewImprovement = isFeatureEnabled('ux_team_email_digest_preview_improvement');
export const isMdew = isFeatureEnabled('mountain_dew');
export const isCustomerAdvocacy = window._infl?.hub?.plan === 'customer_advocacy';
export const isMultilingualHub = isFeatureEnabled('multilingual_content');
export const adminShortcutsEnabled = isFeatureEnabled('admin_shortcuts');
export const digestEmailFeaturedContentEnabled = isFeatureEnabled('digest_email_featured_content');
export const discussionReplyImagePreview = isFeatureEnabled('discussion_reply_image_preview');
export const onlineActionAndReviewImagePreview = isFeatureEnabled('online_action_and_review_image_preview');
export const onlineActionAndDiscussionReplyImagePreview = isFeatureEnabled('online_action_and_discussion_reply_image_preview');
export const multilingualContentEnabled = isFeatureEnabled('multilingual_content');
export const campaignRemovalEnabled = isFeatureEnabled('campaign_removal');
export const rewardDescriptionRTEEnabled = isFeatureEnabled('reward_description_rte');
export const isBountiesEnabled = isFeatureEnabled('community_embed');
export const froalaAIGeneratorEnabled = isFeatureEnabled('froala_ai_generator');
export const isAdminDesignerEnabled = isFeatureEnabled('admin_designer');

export const stripHTML = html => {
  const temporalDivElement = document.createElement('div');
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
};

export const withDelay = delay => compose(
  withState('show', 'setShow', false),
  lifecycle({
    componentDidMount() {
      this.timer = setTimeout(() => this.props.setShow(true), delay);
    },
    componentWillUnmount() {
      clearTimeout(this.timer);
    }
  })
);

const createDeepKeysConverter = updateCallback => {
  const isObject = R.both(R.is(Object), R.pipe(Array.isArray, R.not));
  const convertPairs = ([key, value]) => [
    updateCallback(key),
    convert(value)
  ];

  function convert(obj) {
    return R.cond([
      [Array.isArray, R.map(convert)],
      [isObject, R.compose(R.fromPairs, R.map(convertPairs), R.toPairs)],
      [R.T, R.identity]
    ])(obj);
  }

  return convert;
};

export const deepSnakeize = createDeepKeysConverter(snakeCase);
export const deepCamelize = createDeepKeysConverter(camelCase);
