import * as R from 'ramda';
import { deepSnakeize } from 'src/common/utils';

const headers = { type: 'text/plain; charset=UTF-8' };

const fillData = data => JSON.stringify(deepSnakeize({
  contactUUID: R.path(['_infl', 'currentContact', 'id'], window),
  ...data
}));

const createBlob = data => new Blob([fillData(data)], headers);

export const pushData = data =>
  window.navigator.sendBeacon('/page_events', createBlob(data));

export const pushBatch = batch =>
  window.navigator.sendBeacon('/page_events/batch', createBlob({ batch }));
