import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withNamespaces } from 'react-i18next';
import Popover from '@influitive/secret-garden/lib/popover';

import Placeholder from './placeholder';
import {
  Button,
  Dropdown,
  Icon,
  Wrapper,
  Round,
  ProfileImage
} from './styled';

import { closePopup, togglePopup, setImage } from '../actions';
import reducer from '../reducers';

const App = R.pipe(
  ({ fileInput, image, onChange, onImageDeletion, t }) => {
    const [state, dispatch] = useReducer(reducer, { image, isPopupOpen: false });

    useEffect(() => {
      const handleImageChange = event => {
        dispatch(setImage(URL.createObjectURL(event.target.files[0])));
        dispatch(closePopup());
        onChange && onChange();
      };

      fileInput.addEventListener('change', handleImageChange);

      return () => fileInput.removeEventListener('change', handleImageChange);
    }, []);

    return {
      ...state,
      dispatch,
      t,
      openFile: () => {
        fileInput.click();
      },
      removeFile: () => {
        dispatch(setImage(null));
        dispatch(closePopup());

        fileInput.value = '';

        onImageDeletion && onImageDeletion();
      }
    };
  },
  ({ image, isPopupOpen, openFile, removeFile, dispatch, t }) =>
    <Wrapper>
      <Round>
        {image
          ? <ProfileImage src={image} />
          : <Placeholder />}
      </Round>
      <Popover
        isOpen={isPopupOpen}
        preferPlace='below'
        onOuterAction={() => dispatch(closePopup())}
        padding='0'
        enterExitTransitionDurationMs={0}
        zIndex={1500}
        body={
          <div>
            <Button onClick={openFile}>{t('Upload a photo')}</Button>
            <Button danger={true} onClick={removeFile}>{t('Remove profile photo')}</Button>
          </div>
        }>
        <Dropdown onClick={() => image ? dispatch(togglePopup()) : dispatch(openFile())}>
          <Icon icon='camera' width='25px' />
        </Dropdown>
      </Popover>
    </Wrapper>
);


App.displayName = 'ProfileImageUploader';

App.propTypes = {
  fileInput: PropTypes.instanceOf(Element).isRequired,
  onChange: PropTypes.func,
  onImageDeletion: PropTypes.func,
  image: PropTypes.string
};

export default withNamespaces()(App);
