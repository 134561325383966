import React from 'react';
import MediaQuery from 'react-responsive';
import Sticky from 'react-stickynode';
import { branch, compose, renderComponent, withState } from 'recompose';
import styled from '@emotion/styled';
import cn from 'classnames';

import UserNav from '../user-nav';
import CompanyBanner from '../company-banner';
import CustomHeader from '../custom-header';
import MobileHeader from '../mobile-header';
import MobileIconMenu from '../mobile-icon-menu';
import AnonNav from '../anon-nav';
import { isMobileApp, withLoadedEvent } from '../../common/utils';

const AnonHeader = ({ enabledFeatures, customBranding, publicTabs, allowOpenSignUp }) => customBranding ? (
  <React.Fragment>
    <CustomHeader enabledFeatures={enabledFeatures} customBranding={customBranding} />
    <AnonNav publicTabs={publicTabs} allowOpenSignUp={allowOpenSignUp} />
    <CompanyBanner enabledFeatures={enabledFeatures} customBranding={customBranding} />
  </React.Fragment>
) : null;

const StickyHeader = styled(props => (
  <Sticky innerZ={999}
    top={props.notificationBarHeight}
    enabled={props.isStickyEnabled}
    className={cn(props.className, 'c-header-nav-container-mobile')}>
    <MobileHeader profile={props.profile}
      customBranding={props.customBranding}
      enabledFeatures={props.enabledFeatures} />
    <MobileIconMenu
      features={props.features?.mobile_menu}
      hubSwitcher={props.hubSwitcher}
      unseenCounts={props.unseenCounts}
      setStickyEnabled={props.setStickyEnabled}
      unseenNotificationsCount={props.unseenNotificationsCount} />
  </Sticky>
))`
  @media (min-width: 769px) {
    display: none;
  }
`;

const UserHeader = compose(
  withState('isStickyEnabled', 'setStickyEnabled', true)
)(({ customBranding, profile, enabledFeatures, features,
  hubSwitcher, unseenCounts, moment, unseenNotificationsCount, ...rest
}) => (
  <React.Fragment>
    <MediaQuery minWidth={769}>
      <CustomHeader enabledFeatures={enabledFeatures} customBranding={customBranding} />
      <UserNav
        profile={profile}
        enabledFeatures={enabledFeatures}
        features={features?.advocate_menu}
        hubSwitcher={hubSwitcher}
        unseenCounts={unseenCounts}
        unseenNotificationsCount={unseenNotificationsCount}
        moment={moment}/>
      <CompanyBanner profile={profile} enabledFeatures={enabledFeatures} customBranding={customBranding} />
    </MediaQuery>
    <MediaQuery maxWidth={768}>
      {isMobileApp()
        ? <div className="c-header-nav-container-mobile">
          <MobileHeader profile={profile} customBranding={customBranding} enabledFeatures={enabledFeatures} />
          <MobileIconMenu
            features={features?.mobile_menu}
            hubSwitcher={hubSwitcher}
            unseenCounts={unseenCounts}
            unseenNotificationsCount={unseenNotificationsCount} />
        </div>
        : <StickyHeader
          profile={profile}
          customBranding={customBranding}
          enabledFeatures={enabledFeatures}
          features={features}
          hubSwitcher={hubSwitcher}
          unseenCounts={unseenCounts}
          unseenNotificationsCount={unseenNotificationsCount}
          {...rest} />}
    </MediaQuery>
  </React.Fragment>
));

export default compose(
  branch(R.propEq('isAuth', false), renderComponent(withLoadedEvent(AnonHeader))),
  withLoadedEvent
)(UserHeader);
