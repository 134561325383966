import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Icon } from 'infl-icons';
import { withNamespaces } from 'react-i18next';
import cn from 'classnames';
import ThemeProvider from '@influitive/secret-garden/lib/theme-provider';

import { qaSel } from '../../common/utils';
import styles from './style.css';

const tooltipText = () => 'When previewing the advocate interface, you can interact with all challenges' +
' in the system, regardless of targeting rules. Your activity will not affect advocate statistics.';

const ContextSwitcher = () => (
  <ThemeProvider>
    <div data-qa={qaSel('context-switcher')} className={cn(styles.contextSwitcher, 'c-context-switcher')}>
      <div className={cn(styles.contextLocation, 'c-context-location')}>
        <span>You are currently in the Advocate View.</span>
        <div data-tip={true} className={cn(styles.icon, 'c-icon-wrapper')}>
          <Icon icon="question-circle-o" />
        </div>
        <ReactTooltip place="bottom" type="dark" effect="solid">
          <span className={cn(styles.toolTipText, 'c-tooltip-body')}>{tooltipText()}</span>
        </ReactTooltip>
      </div>
      <div>
        <a data-qa={qaSel('switch-to-admin')} className={cn(styles.link, 'c-link')} href="/corporate">
          Switch to Admin View.
        </a>
      </div>
    </div>
  </ThemeProvider>
);

export default withNamespaces()(ContextSwitcher);
