import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { branch, compose, renderComponent, setDisplayName, withProps } from 'recompose';
import Flex from '@influitive/secret-garden/lib/flex';
import Text from '@influitive/secret-garden/lib/text';

import InfoTooltip from './info-tooltip';
import { lensStates } from '../constants';

const StyledInfoText = styled(Text)`
  margin-bottom: ${props => props.theme.spacing[1]}px;
`;

const MobileInfo = ({ date, isLensActive, notificationText, inMemberView }) => (
  <Flex alignItems="center">
    {isLensActive
      ? <Fragment>
        <Flex flexDirection="column" mr={3}>
          {inMemberView ?
            <Fragment>
              <StyledInfoText data-qa='lens-info' color='white' variant='small'>{notificationText}</StyledInfoText>
              <Text data-qa='activation-time' color='white' variant='small'>{date}</Text>
            </Fragment> :
            <Text data-qa='lens-info' color='white' variant='small'>{notificationText}</Text>
          }
        </Flex>
        <InfoTooltip />
      </Fragment>
      : <Flex flexDirection="column" mr={3}>
        <Text data-qa='lens-info' color='white' variant='small'>{notificationText}</Text>
      </Flex>}
  </Flex>
);

export default compose(
  withProps(({ lensState }) => ({
    isLensActive: lensState === lensStates.ACTIVE
  })),
  branch(({ isMobile }) => isMobile, renderComponent(MobileInfo)),
  setDisplayName('InfoText')
)(({ notificationText, isLensActive }) => (
  <Fragment>
    <Text data-qa='lens-info' color='white' variant='small'>{notificationText}</Text>
    {isLensActive && <InfoTooltip />}
  </Fragment>
));
