import React from 'react';
import ReactDOM from 'react-dom';
import { FroalaView } from '@influitive/secret-garden/lib/froala';
import Froala from './';
import { addAIGeneratorButton } from './utils';

(function() {
  let editor = {
    init: (elem, props = {}) => {
      elem && ReactDOM.render((
        <Froala {...props} />
      ), elem);
    },
    addAIGeneratorButton
  };

  let view = {
    init: (elem, props = {}) => {
      elem && ReactDOM.render((
        <FroalaView {...props} />
      ), elem);
    }
  };

  window.FroalaEditor = editor;
  window.FroalaView = view;
  window.dispatchEvent(new Event('influitive:froala-loaded'));
})();
