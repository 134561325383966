import promise from 'redux-promise';
import thunk from 'redux-thunk';

const getMiddleware = () => {
  const middleware = [
    promise,
    thunk
  ];

  if (process.env.NODE_ENV !== 'production') {
    const { logger } = require('redux-logger');
    middleware.push(logger);
  }

  return middleware;
};


export default getMiddleware();
