import axios from 'axios';
import jwt from 'modules/jwt';
import * as R from 'ramda';
import memoizee from 'memoizee';

import { setWindowObject, windowObject, windowLang } from './utils';

let jwtGet;
let companyConfig;

const unnest = R.path(['data', 'data']);

const jwtGetSafe = url => {
  return jwtGet ? jwtGet(url) : hubConfig().then(() => jwtGetSafe(url));
};

const createJwtGet = () => {
  let headers = {
    'X_ORG_ID': companyConfig.hub.id
  };

  if (windowObject) {
    headers = { ...headers, 'Accept-Language': windowLang() };
  }

  jwtGet = jwt(axios.create({
    withCredentials: true,
    baseURL: companyConfig.api.url,
    headers
  }), axios, companyConfig.hub.tokenUrl);
};

export const windowObjectApi = () => {
  if (windowObject) return Promise.resolve(true);

  return jwtGetSafe('api/c2_homepage/infl_window_object').then(R.pipe(
    R.prop('data'),
    setWindowObject,
    createJwtGet
  ));
};

export const hubConfig = () => axios.get('/api/company_config').then(config => {
  companyConfig = unnest(config);

  createJwtGet();

  return companyConfig.hub.allowOpenSignUp;
});

export const notifications = memoizee(page => jwtGetSafe(`/api/notifications?page=${page}`).then(unnest));
