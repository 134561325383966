import React from 'react';
import { branch, compose, setDisplayName, withHandlers, withState, lifecycle, renderNothing } from 'recompose';
import Button from '@influitive/secret-garden/lib/button';
import Tooltip from '@influitive/secret-garden/lib/tooltip';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import { toggleRewrite, rewriteState } from './api';

const StyledButton = styled(Button, { shouldForwardProp: prop => prop !== 'rewriteEnabled' })(
  ({ theme, rewriteEnabled }) => ({
    minWidth: 'fit-content',
    marginRight: `${theme.spacing[2]}px`,
    background: rewriteEnabled ? theme.colors.white : theme.colors.primary[0]
  })
);

export default compose(
  withState('show', 'setShow', false),
  withState('rewriteEnabled', 'setRewriteEnabled', false),
  withState('loading', 'setLoading', false),
  withHandlers({
    handleRewriteToggle: ({ setLoading }) => () => {
      setLoading(true);
      toggleRewrite()
        .then(() => window.location.href = '/')
        .catch(() => {
          setLoading(false);
          toast('Could not load rewrite UI at this time, please try again.');
        });
    }
  }),
  lifecycle({
    componentDidMount() {
      rewriteState().then(response => {
        this.props.setRewriteEnabled(response.data.enabled);
        this.props.setShow(true);
      });
    }
  }),
  branch(({ show }) => !show, renderNothing),
  setDisplayName('RewriteToggleButton')
)(({ handleRewriteToggle, loading, rewriteEnabled }) =>
  <StyledButton
    data-tip
    data-for="rewrite-toggle-button"
    onClick={handleRewriteToggle}
    disabled={loading}
    variant={rewriteEnabled ? 'secondary' : 'primary'}
    rewriteEnabled={rewriteEnabled}>
    {rewriteEnabled ? 'Return to Classic UI' : 'Preview Project Elevate'}
    <Tooltip
      id="rewrite-toggle-button"
      type="dark"
      zIndex={1001}
      place="bottom">
      <span>
        This will only affect your preview
      </span>
    </Tooltip>
  </StyledButton>
);
