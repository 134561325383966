import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

export const DataDog = ({ config }) => {
  useEffect(() => {
    datadogRum.init({
      site: 'datadoghq.com',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackFrustrations: true,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      useSecureSessionCookie: true,
      useCrossSiteSessionCookie: true,
      traceSampleRate: 100,
      ...config
    });
    datadogRum.startSessionReplayRecording();

    return () => {
      datadogRum.stopSessionReplayRecording();
    };
  }, []);

  return null;
};
