import axios from 'axios';
import jwt2 from 'modules/jwt-2';
import memoize from 'memoizee';

const api = jwt2();
const unnest = R.path(['data', 'data']);

export const enabledFeatures = memoize(() => axios.get('/api/features/public').then(unnest));
export const permissions = memoize(() => api.get('/permissions').then(unnest));
export const toggleRewrite = () => api.post('/api/toggle_rewrite').then(unnest);
export const rewriteState = () => api.get('/api/toggle_rewrite');
